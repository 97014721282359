import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.product.host;
    this.api = this.configService.getConfig().services.product.api;
  }

  getCurrentOrder(
    customerId: string,
    payerId: string,
    applicationId: string
  ): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/orders/latest/customers/${customerId}/payers/${payerId}/application/${applicationId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getPendingOrders(customerId: string, payerId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/orders/customers/${customerId}/payers/${payerId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  addOrder(orderData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/orders`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, orderData, options);
  }

  addOrderItem(orderId: string, itemData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/orders/${orderId}/items`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, itemData, options);
  }

  confirmOrder(orderId: string, orderData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/orders/${orderId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, orderData, options);
  }
}
