import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { formConfigs, formMetadata, formValidationRules } from '@fusion/common';

@Component({
  selector: 'fusion-form-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss']
})
export class OptionListComponent implements OnInit {
  @Output() notifyJobType: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyJobLevel: EventEmitter<string> = new EventEmitter<string>();
  @Input() dialogRef: MatDialogRef<any> | null;
  @Input() formName: string;
  @Input() selectedJobLevels: any;
  @Input() selectedJobTypes: any;
  validationRules: FormGroup;
  formConfig: Object;
  metadata: any;
  role: string[];

  constructor(private fb: FormBuilder, private router: Router) {}

  submitOption(type, name, formData) {
    if (name === 'jobType') {
      this.notifyJobType.emit(type);
    } else if (name === 'jobLevel') {
      this.notifyJobLevel.emit(type);
    }
  }

  ngOnInit() {
    this.formConfig = formConfigs.jobSearchOptionsForm;
    this.metadata = formMetadata.jobSearchOptionsForm;
    this.validationRules = this.fb.group(
      formValidationRules.jobSearchOptionsForm
    );
    this.validationRules.valueChanges.subscribe((form: any) => {
      console.log('form changed to:', form);
    });
  }

  getSelectionState(type: string, name: string, jobLevels: any, jobTypes: any) {
    if (name === 'jobType' && jobTypes !== null) {
      const typeExist = jobTypes.find((level) => level === type);
      return typeExist ? true : false;
    } else if (name === 'jobLevel' && jobLevels !== null) {
      const levelExist = jobLevels.find((level) => level === type);
      return levelExist ? true : false;
    }
    return false;
  }
}
