import { createSelector } from '@ngrx/store';

import { getFusionErrorState } from '../reducers';
import { IError, IErrorHandlingConfig } from '../../models/interfaces';

export const getErrorState = createSelector(getFusionErrorState, (state) => {
  if (state == null) {
    return null;
  }
  return state.error;
});

export const getError = createSelector(
  getErrorState,
  (state): IError<any> => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getErrorHandlingConfig = (errorCode) =>
  createSelector(
    getError,
    (error): IErrorHandlingConfig => {
      if (error == null) {
        return null;
      }
      return error.config && error.code === errorCode ? error.config : null;
    }
  );
