import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ISort } from '../../models/interfaces/sort.interface';

@Component({
  selector: 'fusion-common-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit {
  @Input() orderBy: string;
  @Input() sortOption: ISort;
  @Output() notifySort: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
