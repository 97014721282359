import { NgModule } from '@angular/core';
import { FusionServiceComponent } from './fusion-service.component';
import { FusionCommonModule } from '@fusion/common';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [FusionServiceComponent],
  imports: [FusionCommonModule],
  exports: [],
  providers: [NgxCookieService]
})
export class FusionServiceModule {}
