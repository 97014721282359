import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IFormConfig, IFormMetadata } from "../../models/interfaces";

@Component({
  selector: "fusion-form-dialog-template",
  templateUrl: "./dialog-template.component.html",
  styleUrls: ["./dialog-template.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DialogTemplateComponent implements OnInit, AfterViewInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();
  @Input() isDialog: boolean;
  @Input() autoCompleteEntities: any;
  @Input() entityType: string;
  @Input() formValidationRules: FormGroup;
  @Input() formConfigs: IFormConfig[];
  @Input() formMetadata: IFormMetadata;
  role: string[];

  constructor(
    public dialogRef: MatDialogRef<DialogTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.data) {
      this.isDialog = this.data.isDialog;
      this.autoCompleteEntities = this.data.autoCompleteEntities;
      this.entityType = this.data.entityType;
      this.formValidationRules = this.data.formValidationRules;
      this.formConfigs = this.data.formConfigs;
      this.formMetadata = this.data.formMetadata;
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  formClose() {
    // TODO emit event to notify form close
    this.dialogRef.close();
  }
}
