import { createSelector } from "@ngrx/store";

import { getFusionSubscriptionState } from "../reducers";
import { ISubscription } from "../../models/interfaces";

export const getCurrentSubscriptionState = createSelector(
  getFusionSubscriptionState,
  state => {
    if (state == null) {
      return null;
    }
    return state.currentSubscription;
  }
);

export const getCurrentSubscription = createSelector(
  getCurrentSubscriptionState,
  (state): ISubscription => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
