export enum FormFieldType {
  Text = "text",
  Textarea = "textarea",
  Number = "number",
  Email = "email",
  Phone = "tel",
  Date = "date",
  Password = "password",
  Dropdown = "dropdown",
  Checkbox = "checkbox",
  Ckeditor = "ckeditor",
  Salary = "salary",
  CurrencyCode = "currency_code",
  PaymentCycle = "payment_cycle",
  Url = "url",
  Radio = "radio",
  Autocomplete = "autocomplete",
  List = "list",
  Group = "group",
  File = "file",
  Place = "place"
}
