<div class="row3">
  <div class="row3__section-one">
    <ng-container *ngFor="let config of formConfigs">
      <fusion-form-text-field
        *ngIf="config.name == 'position'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>
  </div>
  <div class="row3__section-two col2">
    <ng-container *ngFor="let config of formConfigs">
      <div class="col2__section-one">
        <fusion-form-dropdown
          *ngIf="config.name == 'type'"
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-dropdown>
      </div>
      <div class="col2__section-two">
        <fusion-form-dropdown
          *ngIf="config.name == 'level'"
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-dropdown>
      </div>
    </ng-container>
  </div>
  <div class="row3__section-three col2">
    <ng-container *ngFor="let config of formConfigs">
      <div class="col2__section-one">
        <fusion-form-dropdown
          *ngIf="config.name == 'category'"
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-dropdown>
      </div>
      <div class="col2__section-two">
        <fusion-form-dropdown
          *ngIf="config.name == 'vacancy'"
          [formConfigs]="config"
          [formValidationRules]="formValidationRules"
        ></fusion-form-dropdown>
      </div>
    </ng-container>
  </div>
</div>
