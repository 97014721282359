import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";

@Injectable({
  providedIn: "root"
})
export class RentService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.rent.host;
    this.api = this.configService.getConfig().services.rent.api;
  }

  getHomeProperties(): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/homeproperties`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postProperty(property: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/homeproperties`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, property, options);
  }

  updateProperty(property: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/homeproperties`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, property, options);
  }
  
  activateProperty(propertyId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/homeproperties/${propertyId}/activate`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, {}, options);
  }

  getHomePropertyById(propertyId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/homeproperties/${propertyId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  searchProperties(payload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/search`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, payload, options);
  }

  uploadImage(propertyId: string, imagePayload: any): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/homeproperties/${propertyId}/images`;
    const options = this.requestOptionsService.generateOptions(false, true);

    const formData = new FormData();
    formData.append("file", imagePayload.blob);
    formData.append("name", imagePayload.name);
    formData.append("type", imagePayload.type);
    return this._http.post(fullUrl, formData, options);
  }

  deleteImage(propertyId: string, imageId: string): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/homeproperties/${propertyId}/images/${imageId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }

  setBannerImage(propertyId: string, imageId: string): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/homeproperties/${propertyId}/images/${imageId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, {}, options);
  }

  checkBeforeSend(file: File, next: Function): void {
    next(file);
  }

  sendToServer(url, options, method, file: File): void {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("folder", "images");

    const xhr = new XMLHttpRequest();
    // init http query
    xhr.open(method, url, true);
    // add custom headers
    for (const [key, val] of Object.entries(options)) {
      xhr.setRequestHeader(key, <string>val);
    }

    // listen callback
    xhr.onload = () => {
      if (xhr.status === 201) {
        // console.log("======>1xhr", xhr);
        // callbackOK(xhr.getResponseHeader('Location'), this.insert.bind(this));
      } else {
        // console.log("======>2xhr", xhr);
        return {
          code: xhr.status,
          response: xhr.response,
          responseLocation: xhr.getResponseHeader("Location"),
          type: xhr.statusText,
          body: xhr.responseText
        };
      }
    };

    xhr.send(fd);
  }
}
