import { createSelector } from "@ngrx/store";

import { getFusionSubscriptionState } from "../reducers";
import { ICompanyPermission, IPermission } from "../../models/interfaces";

export const getPermissionsState = createSelector(
  getFusionSubscriptionState,
  state => {
    if (state == null) {
      return null;
    }
    return state.permissions;
  }
);

export const getCompanyPermissions = createSelector(
  getPermissionsState,
  (state): ICompanyPermission => {
    if (state == null) {
      return null;
    }
    return state.companyPermission;
  }
);

export const getCurrentPermission = createSelector(
  getPermissionsState,
  (state): IPermission => {
    if (state == null) {
      return null;
    }
    return state.currentPermission;
  }
);
