import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  CurrentAppActionTypes,
  LoadCurrentAppSuccess,
  LoadCurrentAppFail,
  LoadCurrentApp
} from "../actions/current-app.actions";
import { Store } from "@ngrx/store";
import {
  SubscriptionService,
  MappingService,
  mappingType
} from "@fusion/service";
import { FusionSubscriptionState } from "../reducers";
import { SetProfileNavigation, LoadCurrentSubscription, SetCurrentProfileNavigation } from "../actions";
import { getCurrentApp } from "../selectors";
import { ICurrentAppMetadata, IApplication } from "../../models/interfaces";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";

@Injectable()
export class CurrentAppEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private subscriptionService: SubscriptionService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<LoadCurrentApp>(CurrentAppActionTypes.LoadCurrentApp),
    map(action => action.payload),
    withLatestFrom(this.store.select(getCurrentApp)),
    mergeMap(([payload, application]: [ICurrentAppMetadata, IApplication]) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.LoadCurrentAppFail,
        source: ErrorSource.Validation,
        data: null
      };
      if (application) {
        return of(new SetCurrentProfileNavigation(payload.currentPath));
      }
      return this.subscriptionService
        .getCurrentApplication(payload.applicationId)
        .pipe(
          switchMap(dataResult => {
            const mappedData = this.mappingService.getMappedData<IApplication>(
              dataResult,
              mappingType.camelize
            );
            let profileNav = mappedData.features;
            return [
              new LoadCurrentAppSuccess(mappedData),
              new SetProfileNavigation(profileNav),
              new SetCurrentProfileNavigation(payload.currentPath),
              new LoadCurrentSubscription(payload.applicationId)
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue loading current application. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new LoadCurrentApp(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new LoadCurrentAppFail(errorPayload));
          })
        );
    })
  );
}
