export const leaseTerms = [
  'Monthly',
  '3 Months',
  '6 Months',
  '1 Year',
  '2 Years',
  '3 Years',
  '4 Years',
  '5 Years',
  '10+ Years'
];

export const leaseVarifications = ['Background Check', 'Credit Check'];
