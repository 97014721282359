import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { IProject } from '../../models/interfaces';

export const getProjectsState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.projects;
  }
);

export const getProjects = createSelector(
  getProjectsState,
  (project): IProject[] => {
    if (project == null) {
      return null;
    }
    return project.data;
  }
);
