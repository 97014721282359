import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { ISubscription } from "../../models/interfaces";

export enum CurrentSubscriptionActionTypes {
  LoadCurrentSubscription = "[Current Subscription] Load",
  LoadCurrentSubscriptionSuccess = "[Current Subscription] Load Success",
  LoadCurrentSubscriptionFail = "[Current Subscription] Load Fail"
}

export class LoadCurrentSubscription implements Action {
  readonly type = CurrentSubscriptionActionTypes.LoadCurrentSubscription;

  constructor(public payload: string) {}
}

export class LoadCurrentSubscriptionSuccess implements Action {
  readonly type = CurrentSubscriptionActionTypes.LoadCurrentSubscriptionSuccess;

  constructor(public payload: ISubscription) {}
}

export class LoadCurrentSubscriptionFail implements Action {
  readonly type = CurrentSubscriptionActionTypes.LoadCurrentSubscriptionFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export type CurrentSubscriptionActions =
  | LoadCurrentSubscription
  | LoadCurrentSubscriptionSuccess
  | LoadCurrentSubscriptionFail;
