import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { IFeature } from "../../models/interfaces";

export enum ProfileNavigationActionTypes {
  SetProfileNavigation = "[Profile Navigation] Set",
  SetProfileNavigationSuccess = "[Profile Navigation] Set Success",
  SetProfileNavigationFail = "[Profile Navigation] Set Fail",
  SetCurrentProfileNavigation = "[Profile Navigation] Set Current",
  SetActiveProfileNavigation = "[Profile Navigation] Set Active",
  SetProfileNavigationPath = "[Profile Navigation] Set Fail"
}

export class SetProfileNavigation implements Action {
  readonly type = ProfileNavigationActionTypes.SetProfileNavigation;

  constructor(public payload: any) {}
}

export class SetProfileNavigationSuccess implements Action {
  readonly type = ProfileNavigationActionTypes.SetProfileNavigationSuccess;

  constructor(public payload: IFeature[]) {}
}

export class SetProfileNavigationFail implements Action {
  readonly type = ProfileNavigationActionTypes.SetProfileNavigationFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export class SetCurrentProfileNavigation implements Action {
  readonly type = ProfileNavigationActionTypes.SetCurrentProfileNavigation;

  constructor(public payload: string) {}
}

export class SetActiveProfileNavigation implements Action {
  readonly type = ProfileNavigationActionTypes.SetActiveProfileNavigation;

  constructor(public payload: IFeature) {}
}

export type ProfileNavigationActions =
  | SetProfileNavigation
  | SetProfileNavigationSuccess
  | SetProfileNavigationFail
  | SetCurrentProfileNavigation
  | SetActiveProfileNavigation;
