import {
  ArticlesActionTypes,
  ArticlesActions
} from '../actions/articles.actions';
import { IArticle } from '../../models/interfaces';

export interface ArticlesState {
  data: IArticle[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: ArticlesState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: ArticlesActions
): ArticlesState {
  switch (action.type) {
    case ArticlesActionTypes.LoadArticles: {
      return {
        ...state,
        loading: true
      };
    }
    case ArticlesActionTypes.LoadArticlesSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case ArticlesActionTypes.LoadArticlesFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
