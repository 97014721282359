import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionCompanyError } from "../../models/enums";
import { IUser } from "@fusion/common";

export enum UsersActionTypes {
  SearchUsers = "[Users] Search",
  SearchUsersSuccess = "[Users] Search Success",
  SearchUsersFail = "[Users] Search Fail"
}

export class SearchUsers implements Action {
  readonly type = UsersActionTypes.SearchUsers;

  constructor(public payload: string) {}
}

export class SearchUsersSuccess implements Action {
  readonly type = UsersActionTypes.SearchUsersSuccess;

  constructor(public payload: IUser[]) {}
}

export class SearchUsersFail implements Action {
  readonly type = UsersActionTypes.SearchUsersFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export type UsersActions = SearchUsers | SearchUsersSuccess | SearchUsersFail;
