import { ArticleActionTypes, ArticleActions } from '../actions/article.actions';
import { IArticle } from '../../models/interfaces';

export interface ArticleState {
  current: {
    article: IArticle;
    loading: boolean;
    loaded: boolean;
  };
  draft: {
    article: IArticle;
    adding: boolean;
    added: boolean;
    updating: boolean;
    updated: boolean;
  };
}

export const initialState: ArticleState = {
  current: {
    article: null,
    loading: false,
    loaded: false
  },
  draft: {
    article: null,
    adding: false,
    added: false,
    updating: false,
    updated: false,
  }
};

export function reducer(
  state = initialState,
  action: ArticleActions
): ArticleState {
  switch (action.type) {
    case ArticleActionTypes.LoadArticle: {
      return {
        ...state,
        current: {
          ...state.current,
          loading: true
        }
      };
    }
    case ArticleActionTypes.LoadArticleSuccess: {
      return {
        ...state,
        current: {
          ...state.current,
          loading: false,
          loaded: true,
          article: action.payload
        }
      };
    }
    case ArticleActionTypes.LoadArticleFail: {
      return {
        ...state,
        current: {
          ...state.current,
          loading: false,
          loaded: false
        }
      };
    }
    case ArticleActionTypes.AddArticle: {
      return {
        ...state,
        draft: {
          ...state.draft,
          adding: true
        }
      };
    }
    case ArticleActionTypes.AddArticleSuccess: {
      return {
        ...state,
        draft: {
          ...state.draft,
          adding: false,
          added: true,
          article: action.payload
        }
      };
    }
    case ArticleActionTypes.AddArticleFail: {
      return {
        ...state,
        draft: {
          ...state.draft,
          adding: false,
          added: false
        }
      };
    }
    case ArticleActionTypes.UpdateArticle: {
      return {
        ...state,
        draft: {
          ...state.draft,
          updating: true,
          updated: false,
        }
      };
    }
    case ArticleActionTypes.UpdateArticleSuccess: {
      return {
        ...state,
        draft: {
          ...state.draft,
          updating: false,
          updated: true
        }
      };
    }
  }
  return state;
}
