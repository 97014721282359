import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fusion-common-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent implements OnInit {
  @Input() currentRootPath: string;
  @Input() targetDir: string;
  @Input() buttonText: string;

  constructor() {}

  ngOnInit() {}

  getBackUrl(url: string, parentDir: string) {
    if (parentDir && parentDir.length > 0) {
      return `${url}/${parentDir}`;
    }
    return `${url}`;
  }
}
