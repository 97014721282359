<footer pageSpacing class="row3">
  <div class="row3__section-one footer__social row2">
    <div extraSmallTitle semiBold class="row2__section-one">Follow</div>
    <div class="row2__section-two colN">
      <a mat-mini-fab color="warn" href="https://www.facebook.com/dottocircle.us" target="_blank">
        <mat-icon svgIcon="facebook"></mat-icon>
      </a>
      <a mat-mini-fab color="warn" href="https://www.linkedin.com/company/dottocircle" target="_blank">
        <mat-icon svgIcon="linkedin"></mat-icon>
      </a>
      <a mat-mini-fab color="warn" href="https://twitter.com/dottocircle_us" target="_blank">
        <mat-icon svgIcon="twitter"></mat-icon>
      </a>
      <a mat-mini-fab color="warn" href="https://www.youtube.com/channel/UC84SJ1TmsIcz86ekuawgG0w" target="_blank">
        <mat-icon svgIcon="youtube"></mat-icon>
      </a>
    </div>
  </div>
  <div class="row3__section-two">
    <mat-divider></mat-divider>
  </div>
  <div centering centerRight class="row3__section-three footer__copywrite">
    <span>Â© 2020 Dot to Circle, Inc. All Rights Reserved</span>
  </div>
</footer>
