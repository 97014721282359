<div>
  <mat-form-field class="textarea-field">
    <mat-label>{{ formConfigs.label }}</mat-label>
    <textarea
      matInput
      [formControl]="formValidationRules.controls[formConfigs.name]"
      required="{{
        formValidationRules.controls[formConfigs.name].hasError('required')
      }}"
      cdkTextareaAutosize
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="5"
    ></textarea>
  </mat-form-field>
  <mat-error
    *ngIf="
      formValidationRules.controls[formConfigs.name].hasError('required') &&
      formValidationRules.controls[formConfigs.name].touched
    "
  >
    You must include {{ formConfigs.label }}.
  </mat-error>
  <mat-error
    *ngIf="
      formValidationRules.controls[formConfigs.name].hasError('minlength') &&
      formValidationRules.controls[formConfigs.name].touched
    "
  >
    Your {{ formConfigs.label }} must be at least
    {{ formConfigs.validation.min }} characters long.
  </mat-error>
  <mat-error
    *ngIf="
      formValidationRules.controls[formConfigs.name].hasError('maxlength') &&
      formValidationRules.controls[formConfigs.name].touched
    "
  >
    Your {{ formConfigs.label }} cannot exceed
    {{ formConfigs.validation.max }} characters.
  </mat-error>
</div>
