import { Component, OnInit, Input } from "@angular/core";
import {
  Image,
  ButtonsConfig,
  ButtonsStrategy,
  KS_DEFAULT_BTN_DELETE,
  KS_DEFAULT_BTN_CLOSE,
  PlainGalleryConfig,
  PlainGalleryStrategy,
  LineLayout
} from "@ks89/angular-modal-gallery";
import { IImage } from "../../models/interfaces";

@Component({
  selector: "fusion-common-image-gallery",
  templateUrl: "./image-gallery.component.html",
  styleUrls: ["./image-gallery.component.scss"]
})
export class ImageGalleryComponent implements OnInit {
  @Input() images: IImage[];
  @Input() galleryId: number = 1;
  buttonsConfigFull: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [KS_DEFAULT_BTN_DELETE, KS_DEFAULT_BTN_CLOSE]
  };

  plainGalleryRowATags: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.ROW,
    layout: new LineLayout(
      { width: "145px", height: "103px" },
      { length: 4, wrap: true },
      "flex-start"
    ),
    // when advanced is defined, additionalBackground: '50% 50%/cover' will be used by default.
    // I added this here, to be more explicit.
    advanced: { aTags: true, additionalBackground: "50% 50%/cover" }
  };

  constructor() {}

  ngOnInit() {}

  getImages(images: IImage[]): Image[] {
    return images.map((image, index) => {
      return new Image(index, { img: image.url });
    });
  }
}
