import { createSelector } from "@ngrx/store";

import { getFusionSubscriptionState } from "../reducers";
import { getUserPermissions } from "@fusion/oauth";
import { getCurrentSubscription } from "./current-subscription.selectors";
import { IFeature } from "../../models/interfaces";

export const getProfileNavigationState = createSelector(
  getFusionSubscriptionState,
  state => {
    if (state == null) {
      return null;
    }
    return state.profileNavigation;
  }
);

export const getProfileNavigationData = createSelector(
  getProfileNavigationState,
  (state): IFeature[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getCurrentRoleId = createSelector(
  getUserPermissions,
  getCurrentSubscription,
  (permissions, subscription): string => {
    if (permissions == null || subscription == null) {
      return null;
    }
    const permission = permissions.find(
      permission => permission.providerId === subscription.id
    );
    return permission ? permission.roleId : null;
  }
);

export const getProfileNavigation = createSelector(
  getProfileNavigationData,
  getCurrentRoleId,
  (navigations, roleId): IFeature[] => {
    if (navigations == null || roleId == null) {
      return null;
    }
    return navigations.filter(
      nav => nav.active && nav.roles.indexOf(roleId) > -1
    );
  }
);

export const getUserProfileNavigation = createSelector(
  getProfileNavigationData,
  (navigations): IFeature[] => {
    if (navigations == null) {
      return null;
    }
    return navigations.filter(nav => nav.active);
  }
);
