import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';

export const getSignUpState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.signUp;
  }
);

export const getSignUpProgressState = createSelector(
  getSignUpState,
  (state) => {
    if (state == null) {
      return null;
    }
    return {
      isInProgress: state.isInProgress,
      isSuccess: state.isSuccess
    };
  }
);