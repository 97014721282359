import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: any, count?: any): any {
    if (value && value.length > count) {
      value = value.substring(0, count).trim() + '...';
    }
    return value;
  }
}
