import { createSelector } from "@ngrx/store";

import { getFusionArticleState } from "../reducers";
import { IArticle } from "../../models/interfaces";

export const getArticlesState = createSelector(getFusionArticleState, state => {
  if (state == null) {
    return null;
  }
  return state.articles;
});

export const getArticles = createSelector(
  getArticlesState,
  (state): IArticle[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
