<div style="height: 80vh;">

  <button mat-icon-button
    (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav>
      <mat-nav-list>
        <a (click)="sidenav.toggle()"
          href=""
          mat-list-item>Close</a>
        <a href="#"
          mat-list-item>Menu Item 1</a>
        <a href="#"
          mat-list-item>Menu Item 2 Menu Item 2</a>
        <a href="#"
          mat-list-item>Menu Item 3</a>
        <a href="#"
          mat-list-item>Menu Item 4</a>
        <a href="#"
          mat-list-item>Menu Item 5</a>
        <a href="#"
          mat-list-item>Menu Item 6</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>Main content</mat-sidenav-content>
  </mat-sidenav-container>
</div>
