import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation
} from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "fusion-common-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CardHeaderComponent implements OnInit {
  @Output()
  notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();
  @Input() isEditIcon: boolean;
  @Input() isEditable: boolean;
  @Input() title: string;
  @Input() type: string;
  @Input() entities: any;
  @Input() entityType: string;
  @Input() formName: string;
  @Input() isColor: boolean;
  @Input() formValidationRules: FormGroup;
  @Input() formConfigs: any;
  @Input() formMetadata: any;

  constructor() {}

  ngOnInit() {}
}
