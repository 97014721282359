import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fusion-common-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input() title: string;
  @Input() name: string;

  constructor() {}

  ngOnInit() {}
}
