import { ArticleEffects } from './article.effects';
import { ArticlesEffects } from './articles.effects';
import { PrivateArticlesEffects } from './private-articles.effects';
import { ErrorEffects } from './error.effects';

// include all effects
export const effects: any[] = [
  ArticleEffects,
  ArticlesEffects,
  PrivateArticlesEffects,
  ErrorEffects
];

// export from effects
