import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  switchMap,
  withLatestFrom,
  map
} from "rxjs/operators";

import {
  PermissionsActionTypes,
  LoadPermissionsSuccess,
  LoadPermissionsFail,
  AddPermissions,
  AddPermissionsSuccess,
  AddPermissionsFail,
  LoadPermissions,
  UpdatePermissions,
  UpdatePermissionsSuccess,
  UpdatePermissionsFail
} from "../actions/permissions.actions";
import {
  SubscriptionService,
  MappingService,
  mappingType
} from "@fusion/service";
import { Store } from "@ngrx/store";
import { FusionSubscriptionState } from "../reducers";
import { getRouterParams } from "@fusion/router";
import { Params } from "@angular/router";
import { IPermission, ICompanyPermission } from "../../models/interfaces";
import { getCompanyPermissions, getCurrentPermission } from "../selectors";
import { getCompany } from "@fusion/company";
import { ICompany } from "@fusion/common";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";

@Injectable()
export class PermissionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private subscriptionService: SubscriptionService,
    private mappingService: MappingService
  ) {}

  @Effect()
  loadPermissions$ = this.actions$.pipe(
    ofType(PermissionsActionTypes.LoadPermissions),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([action, params]: [any, Params]) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.LoadPermissionsFail,
        source: ErrorSource.Validation,
        data: null
      };
      const companyId = params.companyId;
      return this.subscriptionService.getCompanyPermissions(companyId).pipe(
        switchMap(dataResult => {
          const mappedPermissions = this.mappingService.getMappedData<
            ICompanyPermission
          >(dataResult, mappingType.camelize);
          return [new LoadPermissionsSuccess(mappedPermissions)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue loading your access permissions. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadPermissions()],
                  title: "Retry"
                }
              }
            }
          };
          return of(new LoadPermissionsFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  addPermission$ = this.actions$.pipe(
    ofType<AddPermissions>(PermissionsActionTypes.AddPermissions),
    map(action => action.payload),
    withLatestFrom(
      this.store.select(getCompanyPermissions),
      this.store.select(getCurrentPermission)
    ),
    mergeMap(
      ([payload, companyPermissions, currentPermission]: [
        IPermission,
        ICompanyPermission,
        IPermission
      ]) => {
        let errorPayload: IError<FusionSubscriptionError> = {
          code: FusionSubscriptionError.AddPermissionsFail,
          source: ErrorSource.Validation,
          data: null
        };

        const isRepresentative = companyPermissions.permissions.find(
          per => per.representativeId === payload.user.id
        );

        const permission: IPermission = {
          ...currentPermission,
          companyId: companyPermissions.companyId,
          representativeId: payload.user.id,
          isRepresentative: isRepresentative ? true : false,
          roleId: payload.role,
          active: true
        };

        const mappedSubscription = this.mappingService.getMappedData(
          permission,
          mappingType.underscore
        );
        return this.subscriptionService.addPermission(mappedSubscription).pipe(
          switchMap(dataResult => {
            return [
              new AddPermissionsSuccess(dataResult),
              new LoadPermissions()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue adding new permission. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new AddPermissions(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new AddPermissionsFail(errorPayload));
          })
        );
      }
    )
  );

  @Effect()
  updatePermission$ = this.actions$.pipe(
    ofType<UpdatePermissions>(PermissionsActionTypes.UpdatePermissions),
    map(action => action.payload),
    withLatestFrom(this.store.select(getCurrentPermission)),
    mergeMap(([payload, currentPermission]: [IPermission, IPermission]) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.UpdatePermissionsFail,
        source: ErrorSource.Validation,
        data: null
      };

      const permission: IPermission = {
        id: currentPermission.id,
        roleId: payload["role"]
      };

      const mappedSubscription = this.mappingService.getMappedData(
        permission,
        mappingType.underscore
      );
      return this.subscriptionService.updatePermission(mappedSubscription).pipe(
        switchMap(dataResult => {
          return [
            new UpdatePermissionsSuccess(dataResult),
            new LoadPermissions()
          ];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue updating current permission. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UpdatePermissions(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new UpdatePermissionsFail(errorPayload));
        })
      );
    })
  );
}
