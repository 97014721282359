<ng-container *ngIf="title; else edit_form">
  <div
    cardSpacing
    bgColor
    tRadius
    class="col2"
    [ngClass]="{ 'card-header-color': isColor }"
  >
    <div
      exMediumTitle
      class="col2__section-one"
      [ngClass]="{ 'title-color': isColor }"
    >
      {{ title }}
    </div>
    <div centeringRight *ngIf="isEditable" class="col2__section-two">
      <ng-container *ngIf="!currentEntity; else edit_with_title">
        <fusion-form-dialog
          semiBold
          primaryFontColor
          [isDialog]="true"
          [buttonTitle]="buttonTitle"
          [iconType]="iconType"
          [formValidationRules]="formValidationRules"
          [formConfigs]="formConfigs"
          [formMetadata]="formMetadata"
          [autoCompleteEntities]="autoCompleteEntities"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
          (notifyAutocompletion)="notifyAutocompletion.emit($event)"
        ></fusion-form-dialog>
      </ng-container>
      <ng-template #edit_with_title>
        <fusion-form-dialog
          semiBold
          primaryFontColor
          [isDialog]="true"
          [iconType]="iconType"
          [buttonTitle]="buttonTitle"
          [formValidationRules]="updatedValidationRules"
          [formConfigs]="formConfigs"
          [formMetadata]="formMetadata"
          [autoCompleteEntities]="autoCompleteEntities"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
          (notifyAutocompletion)="notifyAutocompletion.emit($event)"
          (click)="updateValidationRules()"
        ></fusion-form-dialog>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #edit_form>
  <fusion-form-dialog
    semiBold
    primaryFontColor
    [isDialog]="true"
    [iconType]="iconType"
    [buttonTitle]="buttonTitle"
    [formValidationRules]="updatedValidationRules"
    [formConfigs]="formConfigs"
    [formMetadata]="formMetadata"
    [autoCompleteEntities]="autoCompleteEntities"
    (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    (notifyAutocompletion)="notifyAutocompletion.emit($event)"
    (click)="updateValidationRules()"
  ></fusion-form-dialog>
</ng-template>
