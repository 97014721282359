import { Quill } from 'quill';
/**
 * Custom module for quilljs to allow user to drag images from their file system into the editor
 * and paste images from clipboard (Works on Chrome, Firefox, Edge, not on Safari)
 * @see https://quilljs.com/blog/building-a-custom-module/
 */
export class ImageUpload {
  private quill;
  private options;
  constructor(quill: Quill, options: object = {}) {
    // save the quill reference
    this.quill = quill;
    // save options
    this.options = options;
    // listen for drop and paste events
    this.quill
      .getModule('toolbar')
      .addHandler('image', this.selectLocalImage.bind(this));
  }

  /**
   * Select local image
   */
  selectLocalImage(): void {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];

      // file type is only image.
      if (/^image\//.test(file.type)) {
        const checkBeforeSend =
          this.options.checkBeforeSend || this.checkBeforeSend.bind(this);
        checkBeforeSend(file, this.sendToServer.bind(this));
      } else {
        console.warn('You could only upload images.');
      }
    };
  }

  checkBeforeSend(file: File, next: Function): void {
    next(file);
  }

  sendToServer(file: File): void {
    const url = this.options.url || 'your-url.com',
      method = this.options.method || 'POST',
      headers = this.options.headers || {},
      callbackOK =
        this.options.callbackOK || this.uploadImageCallbackOK.bind(this),
      callbackKO =
        this.options.callbackKO || this.uploadImageCallbackKO.bind(this),
      fd = new FormData();
    fd.append('file', file);
    fd.append('folder', 'images');

    const xhr = new XMLHttpRequest();
    // init http query
    xhr.open(method, url, true);
    // add custom headers
    for (const [key, val] of Object.entries(headers)) {
      xhr.setRequestHeader(key, <string>val);
    }

    // listen callback
    xhr.onload = () => {
      if (xhr.status === 201) {
        callbackOK(xhr.getResponseHeader('Location'), this.insert.bind(this));
      } else {
        callbackKO({
          code: xhr.status,
          response: xhr.response,
          responseLocation: xhr.getResponseHeader('Location'),
          type: xhr.statusText,
          body: xhr.responseText
        });
      }
    };

    xhr.send(fd);
  }

  insert(dataUrl: string): void {
    const index =
      (this.quill.getSelection() || {}).index || this.quill.getLength();
    this.quill.insertEmbed(index, 'image', dataUrl, 'user');
  }

  uploadImageCallbackOK(response: any, next: Function): void {
    next(response);
  }

  uploadImageCallbackKO(error: Error): void {
    // tslint:disable-next-line:no-console
    console.log(error);
  }
}
