import { createSelector } from "@ngrx/store";

import { getFusionSubscriptionState } from "../reducers";
import { ICurrentAppMetadata, IApplication } from "../../models/interfaces";

export const getCurrentAppState = createSelector(
  getFusionSubscriptionState,
  state => {
    if (state == null) {
      return null;
    }
    return state.currentApp;
  }
);

export const getCurrentAppMetadata = createSelector(
  getCurrentAppState,
  (state): ICurrentAppMetadata => {
    if (state == null) {
      return null;
    }
    return state.metadata;
  }
);

export const getCurrentApp = createSelector(
  getCurrentAppState,
  (state): IApplication => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
