export const currencyCode = [
  '&#2547;', // BDT
  '&#36;',
  '&#163;',
  '&#8364;',
  '&#165;',
  '&#8377;',
  '&#8381;',
  '&#20803;',
  '&#8355;',
  '&#8356;',
  '&#8357;',
  '&#8358;',
  '&#8359;',
  '&#8360;',
  '&#8361;',
  '&#8362;',
  '&#8363;',
  '&#8365;',
  '&#8366;',
  '&#8368;',
  '&#8369;',
  '&#8371;',
  '&#8378;',
  '&#65020;'
];

export const payRates = [
  'Per Hour',
  'Per Day',
  'Per Week',
  'Per Two Weeks',
  'Per Month',
  'Per Year',
  'One Time'
];
