import { JobseekerProfileEffects } from "./jobseeker-profile.effects";
import { EducationsEffects } from "./educations.effects";
import { ExperiencesEffects } from "./experiences.effects";
import { ProjectsEffects } from "./projects.effects";
import { PublicationsEffects } from "./publications.effects";
import { RatingEffects } from "./rating.effects";
import { SkillsEffects } from "./skills.effects";
import { ErrorEffects } from "./error.effects";

// import effects

// include all effects
export const effects: any[] = [
  JobseekerProfileEffects,
  EducationsEffects,
  ExperiencesEffects,
  ProjectsEffects,
  PublicationsEffects,
  RatingEffects,
  SkillsEffects,
  ErrorEffects
];

// export from effects
