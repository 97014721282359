import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';

import { ConfigService } from '../config/config.service';
import { IS3Configs } from '@fusion/common';

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  private s3Configs: IS3Configs;
  constructor(private configService: ConfigService) {
    this.s3Configs = this.configService.getConfig().s3;
  }
  uploadfile(file: any, filePath) {
    const bucket = new S3({
      accessKeyId: this.s3Configs.accessKeyId,
      secretAccessKey: this.s3Configs.secretAccessKey,
      region: this.s3Configs.region
    });
    const params = {
      Bucket: this.s3Configs.bucketName,
      Key: `${filePath}/${file.name}`,
      Body: file
    };
    return bucket.upload(params, function(err, data) {
      if (err) {
        return err;
      }
      return data;
    });
  }
}
