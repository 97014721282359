import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  CurrentSubscriptionActionTypes,
  LoadCurrentSubscriptionSuccess,
  LoadCurrentSubscriptionFail,
  LoadCurrentSubscription
} from "../actions/current-subscription.actions";
import {
  SubscriptionService,
  MappingService,
  mappingType
} from "@fusion/service";
import { Store } from "@ngrx/store";
import { FusionSubscriptionState } from "../reducers";
import { Params } from "@angular/router";
import { getRouterParams } from "@fusion/router";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";

@Injectable()
export class CurrentSubscriptionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private subscriptionService: SubscriptionService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<LoadCurrentSubscription>(
      CurrentSubscriptionActionTypes.LoadCurrentSubscription
    ),
    map(action => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([payload, params]: [string, Params]) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.LoadCurrentSubscriptionFail,
        source: ErrorSource.Validation,
        data: null
      };
      const subscriberId = params.subscriberId;
      // does not need any subscription, ex: user
      // TODO remove companyId, it requires subscription
      const nonSubscriberId = params.companyId || params.userId;
      if (nonSubscriberId) {
        return of(new LoadCurrentSubscriptionSuccess({ id: nonSubscriberId }));
      }
      return this.subscriptionService
        .getCurrentSubscription(subscriberId, payload)
        .pipe(
          switchMap(dataResult => {
            const mappedData = this.mappingService.getMappedData(
              dataResult,
              mappingType.camelize
            );
            return of(new LoadCurrentSubscriptionSuccess(mappedData));
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue loading your current subscription. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new LoadCurrentSubscription(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new LoadCurrentSubscriptionFail(errorPayload));
          })
        );
    })
  );
}
