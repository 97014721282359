import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const PetPolicyForm: IForm = {
  metadata: {
    name: constants.PET_POLICY_FORM,
    title: "Pet Policy",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "500px",
      maxHeight: "300px"
    }
  },
  configs: [
    {
      label: "",
      type: FormFieldType.Checkbox,
      items: [
        {
          label: "No Pets",
          name: "no_pets",
          value: "no_pets",
          validation: {
            required: false
          }
        },
        {
          label: "Cates Allowed",
          name: "cates",
          value: "cates",
          validation: {
            required: false
          }
        },
        {
          label: "Dogs Allowed",
          name: "dogs",
          value: "dogs",
          validation: {
            required: false
          }
        }
      ],
      disable: {
        by: "no_pets",
        fields: ["cates", "dogs"]
      }
    }
  ]
};
