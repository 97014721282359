<div *ngIf="article" class="row4">
  <div class="post-headline">{{ article?.title }}</div>
  <div class="post-subheadline">{{ article?.subtitle }}</div>
  <div image>
    <img
      *ngIf="article.bannerImage"
      imageFit
      class="radius"
      [src]="article.bannerImage"
    />
  </div>
  <quill-view
    [content]="article?.content"
    format="text"
    theme="snow"
  ></quill-view>
</div>
