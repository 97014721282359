import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionoAuthError } from "../../models/enums";

export enum UserProfileActionTypes {
  LoadUserProfile = "[User Profile] Load",
  LoadUserProfileSuccess = "[User Profile] Load Success",
  LoadUserProfileFail = "[User Profile] Load Fail"
}

export class LoadUserProfile implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfile;
}

export class LoadUserProfileSuccess implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfileSuccess;

  constructor(public payload: any) {}
}

export class LoadUserProfileFail implements Action {
  readonly type = UserProfileActionTypes.LoadUserProfileFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export type UserProfileActions =
  | LoadUserProfile
  | LoadUserProfileSuccess
  | LoadUserProfileFail;
