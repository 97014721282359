import * as constants from "../constants";
import { jobCategories } from "../data";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const JobSearchForm: IForm = {
  metadata: {
    name: constants.JOB_SEARCH_FORM,
    title: "Sign In",
    submitButtonText: "Sign In",
    style: {
      topMargin: "0px",
      maxWidth: "540px",
      maxHeight: "380px"
    }
  },
  configs: [
    {
      label: "Keywords",
      name: "keywords",
      type: FormFieldType.Text,
      placeholder: "search keywords, job title, job category, company",
      validation: {
        required: false
      }
    },
    {
      label: "Keywords",
      name: "category",
      type: FormFieldType.Dropdown,
      placeholder: "select job category",
      options: jobCategories,
      validation: {
        required: false
      }
    }
  ]
};
