import { createSelector } from "@ngrx/store";

import { getFusionCompanyState } from "../reducers";
import { IUser } from "@fusion/common";

export const getUsersState = createSelector(getFusionCompanyState, state => {
  if (state == null) {
    return null;
  }
  return state.users;
});

export const getUsers = createSelector(getUsersState, (state): IUser[] => {
  if (state == null) {
    return null;
  }
  return state.data;
});
