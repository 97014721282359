import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

let role = ["administrator", "blocked", "moderator", "remove"];
export const RepresentativeForm: IForm = {
  metadata: {
    name: constants.REPRESENTATIVE_FORM,
    title: "Add Representative",
    submitButtonText: "Send",
    style: {
      topMargin: "0px"
    }
  },
  configs: [
    {
      label: "First Name",
      name: "firstName",
      type: FormFieldType.Text,
      placeholder: "John",
      validation: {
        required: true,
        max: 30
      }
    },
    {
      label: "Last Name",
      name: "lastName",
      type: FormFieldType.Text,
      placeholder: "Deep",
      validation: {
        required: true,
        max: 30
      }
    },
    {
      label: "Gender",
      name: "gender",
      type: FormFieldType.Radio,
      options: [
        {
          label: "Male",
          value: "male"
        },
        {
          label: "Female",
          value: "female"
        }
      ],
      validation: {
        required: true
      }
    },
    {
      label: "Date of Birth",
      name: "dateOfBirth",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Role",
      name: "role",
      type: FormFieldType.Dropdown,
      options: role,
      validation: {
        required: true
      }
    },
    {
      label: "Phone #",
      name: "phoneNo",
      type: FormFieldType.Text,
      placeholder: "(347) 123-1234",
      examplePattern: "(347) 123-1234",
      validation: {
        required: true,
        max: 300,
        pattern: "^[(]{1}[0-9]{3}[)]{1}\\ [0-9]{3}-[0-9]{4}$"
      }
    },
    {
      label: "Email",
      name: "email",
      type: FormFieldType.Email,
      placeholder: "john.deep@example.com",
      examplePattern: "john.deep@example.com",
      validation: {
        required: true,
        pattern: "[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\\.[a-zA-Z]+"
      }
    },
    {
      label: "Password",
      name: "password",
      type: FormFieldType.Password,
      placeholder: "password",
      validation: {
        required: true,
        max: 30
      }
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      type: FormFieldType.Password,
      placeholder: "password",
      validation: {
        required: true
      }
    },
    {
      label: "User Choice",
      type: FormFieldType.Checkbox,
      items: [
        {
          label: "Privacy Policy & Terms of Use",
          name: "privecy",
          value: "privecy",
          validation: {
            required: true
          }
        },
        {
          label: "Email me Job/Career/Promotional Update",
          name: "promEmail",
          value: "promEmail",
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
