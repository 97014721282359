import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import { mergeMap, catchError, map, switchMap } from "rxjs/operators";

import {
  UsersActionTypes,
  SearchUsersSuccess,
  SearchUsersFail,
  SearchUsers
} from "../actions/users.actions";
import { UserService, MappingService, mappingType } from "@fusion/service";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionCompanyError } from "../../models/enums";
import { IUser } from "@fusion/common";

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<SearchUsers>(UsersActionTypes.SearchUsers),
    map(action => action.payload),
    mergeMap((payload: string) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.SearchUsersFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.userService.searchUsers(payload).pipe(
        switchMap(dataResult => {
          const mappedUsers = this.mappingService.getMappedData<IUser[]>(
            dataResult,
            mappingType.camelize
          );
          return [new SearchUsersSuccess(mappedUsers)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue searching users. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new SearchUsers(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new SearchUsersFail(errorPayload));
        })
      );
    })
  );
}
