<div *ngFor="let article of articles" class="col2">
  <div class="col2__section-one row3">
    <div class="post-headline">{{ article.title }}</div>
    <div class="post-subheadline">{{ article.subtitle }}</div>
    <quill-view
      [content]="article.content"
      format="text"
      theme="snow"
    ></quill-view>
  </div>
  <div class="col2__section-two">two</div>
</div>
