import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-auto-complete",
  templateUrl: "./auto-complete.component.html",
  styleUrls: ["./auto-complete.component.scss"]
})
export class AutoCompleteComponent implements OnInit {
  @Input() autoCompleteEntities: any;
  @Input() entityType: string;
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: FormGroup;
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();
  reset: boolean;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formValidationRules.valueChanges.subscribe((formData: any) => {
      if (typeof formData[this.formConfigs.name] === "string") {
        // emit event to get data
        this.notifyAutocompletion.emit(formData[this.formConfigs.name]);
        if (this.reset) {
          this.formConfigs.autoFillItems.map(field => {
            this.formValidationRules.controls[field].patchValue(null, {
              onlySelf: true
            });
            this.formValidationRules.controls[field].enable({
              onlySelf: true
            });
          });
          this.reset = false;
        }
      } else if (formData[this.formConfigs.name]) {
        const entity = formData[this.formConfigs.name];
        this.formConfigs.autoFillItems.map(field => {
          if (entity && entity[field]) {
            this.formValidationRules.controls[field].patchValue(entity[field], {
              onlySelf: true
            });
            this.formValidationRules.controls[field].disable({
              onlySelf: true
            });
          }
        });
        this.reset = true;
      }
    });
  }

  displayName(entity): string {
    if (entity && entity.name) {
      return entity.name;
    } else if (entity && entity.firstName) {
      return `${entity.firstName} ${entity.lastName}`;
    }
  }

  onFormSubmit(entity: any) {
    // emit selected entity
  }
}
