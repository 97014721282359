<div bPageSpacing class="">
  <ng-container
    *ngIf="
      (updateArticleErrorHandlingConfig$ | async) ||
        (publishArticleErrorHandlingConfig$ | async) ||
        (loadArticleErrorHandlingConfig$ | async) as errorHandlingConfig;
      else articleEditor
    "
  >
    <ng-template [fusionErrorHandler]="errorHandlingConfig"></ng-template>
  </ng-container>
  <ng-template #articleEditor>
    <fusion-article-editor
      [currentRootPath]="currentRootPath$ | async"
      [article]="article$ | async"
      [isUpdating]="isUpdating$ | async"
      (notifyArticleSubmit)="onNotifyArticleSubmit($event)"
      (notifyArticlePublish)="onNotifyArticlePublish($event)"
    ></fusion-article-editor>
  </ng-template>
</div>
