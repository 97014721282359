import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import { Store, Action } from "@ngrx/store";
import { FusionCareerProfileState } from "../reducers/index";
import { JobseekerService, MappingService, mappingType } from "@fusion/service";
import { getRouterParams } from "@fusion/router";
import { Params } from "@angular/router";
import {
  JobseekerProfileActionTypes,
  LoadJobseekerProfileSuccess,
  LoadJobseekerProfileFail,
  LoadJobseekerProfile,
  AddJobseekerProfileSuccess,
  AddJobseekerProfileFail,
  SetExperiences,
  SetEducations,
  SetProjects,
  SetPublications,
  SetRating,
  SetSkills,
  UpdateJobseekerProfile,
  AddJobseekerProfile,
  UpdateJobseekerProfileFail
} from "../actions/index";
import { getoAuthUserId } from "@fusion/oauth";
import { IJobseeker, FusionCareerProfileError } from "../../models";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";

@Injectable()
export class JobseekerProfileEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionCareerProfileState>,
    private jobseekerService: JobseekerService,
    private mappingService: MappingService
  ) {}

  @Effect()
  getJobseekerProfile$ = this.actions$.pipe(
    ofType<LoadJobseekerProfile>(
      JobseekerProfileActionTypes.LoadJobseekerProfile
    ),
    map(action => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([payload, params]: [string, Params]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.LoadJobseekerProfileFail,
        source: ErrorSource.Validation,
        data: null
      };
      const subscriberId = params.subscriberId;
      return this.jobseekerService
        .getJobseekerProfile(subscriberId || payload)
        .pipe(
          switchMap(dataResult => {
            const result = this.mappingService.getMappedData<IJobseeker>(
              dataResult,
              mappingType.camelize
            );
            return [
              new LoadJobseekerProfileSuccess(result),
              new SetEducations(result.educations),
              new SetExperiences(result.experiences),
              new SetProjects(result.projects),
              new SetPublications(result.publications),
              new SetRating(result.ratings),
              new SetSkills(result.skills)
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Template,
                message:
                  "Sorry, we are having some issue loading your career profile. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new LoadJobseekerProfile(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new LoadJobseekerProfileFail(errorPayload));
          })
        );
    })
  );

  @Effect()
  addJobseekerProfile$ = this.actions$.pipe(
    ofType(JobseekerProfileActionTypes.AddJobseekerProfile),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([action, userId]: [Action, String]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.AddJobseekerProfileFail,
        source: ErrorSource.Validation,
        data: null
      };
      const jobseeker = {
        user_id: userId
      };
      return this.jobseekerService.postJobseeker(jobseeker).pipe(
        switchMap(result => {
          return [new AddJobseekerProfileSuccess(result)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue setting your career profile. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new AddJobseekerProfile()],
                  title: "Retry"
                }
              }
            }
          };
          return of(new AddJobseekerProfileFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  updateJobseekerProfile$ = this.actions$.pipe(
    ofType<UpdateJobseekerProfile>(
      JobseekerProfileActionTypes.UpdateJobseekerProfile
    ),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([profile, userId]: [IJobseeker, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.UpdateJobseekerProfileFail,
        source: ErrorSource.Validation,
        data: null
      };
      const mappedProfile = this.mappingService.getMappedData(
        profile,
        mappingType.underscore
      );
      return this.jobseekerService.putJobseeker(userId, mappedProfile).pipe(
        switchMap(result => {
          return [new LoadJobseekerProfile()];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue updating your career profile. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UpdateJobseekerProfile(profile)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new UpdateJobseekerProfileFail(errorPayload));
        })
      );
    })
  );
}
