import {
  ArticleErrorActionTypes,
  ArticlesErrorActionTypes,
  PrivateArticlesErrorActionTypes
} from "../../store/actions/index";

export type FusionArticleError =
  | ArticleErrorActionTypes
  | ArticlesErrorActionTypes
  | PrivateArticlesErrorActionTypes;
export const FusionArticleError = {
  ...ArticleErrorActionTypes,
  ...ArticlesErrorActionTypes,
  ...PrivateArticlesErrorActionTypes
};
