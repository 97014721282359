import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { reducers } from "./store/reducers/index";
import { EffectsModule } from "@ngrx/effects";
import { effects } from "./store/effects/index";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreviewComponent } from "./containers/preview/preview.component";
import { FusionPipeModule } from "@fusion/pipe";
import { CompanyListComponent } from "./containers/company-list/company-list.component";
import { CompanyComponent } from "./containers/company/company.component";
import { AddCompanyComponent } from "./components/add-company/add-company.component";
import { FusionMaterialModule } from "@fusion/material";
import { FusionThemeModule } from "@fusion/theme";
import { CompanyCardComponent } from "./components/company-card/company-card.component";
import { FusionCommonModule } from "@fusion/common";
import { CompanyHeaderComponent } from "./components/company-header/company-header.component";
import { CompanyBodyComponent } from "./components/company-body/company-body.component";
import { CompanySummaryComponent } from "./components/company-summary/company-summary.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FusionFormModule } from "@fusion/form";
import { guards } from "./store/guards/index";

@NgModule({
  declarations: [
    PreviewComponent,
    CompanyListComponent,
    CompanyComponent,
    AddCompanyComponent,
    CompanyCardComponent,
    CompanyHeaderComponent,
    CompanyBodyComponent,
    CompanySummaryComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FusionPipeModule,
    BrowserAnimationsModule,
    FusionMaterialModule,
    FusionThemeModule,
    FusionCommonModule,
    FusionFormModule,
    StoreModule.forFeature("fusionCompany", reducers),
    EffectsModule.forFeature(effects)
  ],
  exports: [PreviewComponent, CompanyListComponent, CompanyComponent],
  providers: [guards]
})
export class FusionCompanyModule {}
