import * as constants from "../constants";
import * as dataModel from "../data";
import { IForm } from "../interfaces";
import { FormFieldType, FormFieldGroup } from "../enums";

export const FamilyPreferencesForm: IForm = {
  metadata: {
    name: constants.FAMILY_PREFERENCES_FORM,
    title: "Family Preferences",
    submitButtonText: "Next",
    stepper: {
      isStepper: true,
      isFirstStep: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "440px"
    }
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.HomeProperty,
      configs: [
        {
          label: "Expected Family Size",
          name: "familySize",
          type: FormFieldType.Dropdown,
          options: dataModel.familySize,
          validation: {
            required: false
          }
        },
        {
          label: "Kids Policy",
          name: "kidsPolicy",
          type: FormFieldType.Dropdown,
          options: dataModel.kidsPolicy,
          validation: {
            required: false
          }
        }
      ]
    },
    {
      label: "Prefered Gender",
      name: "genders",
      type: FormFieldType.Dropdown,
      isMultiSelectDropdown: true,
      options: ["Male", "Female"],
      validation: {
        required: false
      }
    },
    {
      label: "Prefered Languages",
      name: "languages",
      type: FormFieldType.Dropdown,
      isMultiSelectDropdown: true,
      options: dataModel.languages,
      validation: {
        required: false
      }
    }
  ]
};
