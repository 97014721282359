import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IEmptyState } from '../../models/interfaces/empty-state.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'fusion-common-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {
  @Output() notifyEmptyStateClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() isNotifyOnly: boolean;
  @Input() isLoading: boolean;
  @Input() emptyState: IEmptyState;
  @Input() currentRootPath: string;

  constructor(private router: Router) {}

  ngOnInit() {}

  navigateTo(baseRoute: string, path: string) {
    if (!this.isNotifyOnly) {
      this.router.navigate([`${baseRoute}${path}`]);
    } else {
      this.notifyEmptyStateClick.emit();
    }
  }
}
