<h1>
  {{ title }}!<sup
    *ngIf="name"
    radius
    nanoFont
    primaryBgColor
    whiteFontColor
    semiBold
    class="subscriber-label v-padding-1 h-padding-2"
    >@{{ name }}</sup
  >
</h1>
