import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fusion-router-fusion-router',
  template: `
    <p>
      fusion-router works!
    </p>
  `,
  styles: []
})
export class FusionRouterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
