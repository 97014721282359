import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ICarousel } from '../../models/interfaces/carousel.interface';

@Component({
  selector: 'fusion-common-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() carouselItems: ICarousel[];
  currentIndex: number;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentIndex = 0;
    setInterval(() => {
      this.cdRef.markForCheck();
      this.next();
    }, 3000);
  }

  next = () => {
    if (this.currentIndex < this.carouselItems.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  };

  previous = () => {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  };

  currentSlide = (i) => {
    this.currentIndex = i;
  };

  isActive(i) {
    return i === this.currentIndex;
  }
}
