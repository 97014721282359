import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OauthSessionGuard, OauthRouteGuard } from "@fusion/oauth";
import { ArticlesComponent } from "./containers/articles/articles.component";
import { ApplicationGuard } from "@fusion/subscription";
import { PublicationsComponent } from "./containers/publications/publications.component";
import { AddArticleComponent } from "./containers/add-article/add-article.component";
import { SettingsComponent } from "./containers/settings/settings.component";
import { ArticleComponent } from "./containers/article/article.component";
import { MyArticlesComponent } from "./containers/my-articles/my-articles.component";
import { EditArticleComponent } from "./containers/edit-article/edit-article.component";

const routes: Routes = [
  {
    path: "",
    canActivateChild: [OauthSessionGuard, ApplicationGuard],
    data: { applicationId: "a65b7994-936a-4e76-851b-ce894119e38f" },
    children: [
      {
        path: ":subscriberId",
        component: ArticlesComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "" }
      },
      {
        path: ":subscriberId/articles/:articleId",
        component: ArticleComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "" }
      },
      {
        path: ":subscriberId/myarticles",
        component: MyArticlesComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "myarticles" }
      },
      {
        path: ":subscriberId/myarticles/:articleId",
        component: EditArticleComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "myarticles" }
      },
      {
        path: ":subscriberId/publications",
        component: PublicationsComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "publications" }
      },
      {
        path: ":subscriberId/addarticle",
        component: AddArticleComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "addarticle" }
      },
      {
        path: ":subscriberId/addarticle/:articleId",
        component: EditArticleComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "addarticle" }
      },
      {
        path: ":subscriberId/settings",
        component: SettingsComponent,
        canActivate: [OauthRouteGuard],
        data: { currentPath: "settings" }
      },
      {
        path: "**",
        redirectTo: "",
        pathMatch: "full"
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
