import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'fusion-common-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss']
})
export class CardFooterComponent implements OnInit {
  @Output() notifyCardFooterClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() title: string;

  constructor() {}

  ngOnInit() {}
}
