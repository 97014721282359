import {
  EducationsActionTypes,
  EducationsActions
} from '../actions/educations.actions';
import { IEducation } from '../../models/interfaces';

export interface EducationsState {
  data: IEducation[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: EducationsState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: EducationsActions
): EducationsState {
  switch (action.type) {
    case EducationsActionTypes.AddEducations: {
      return {
        ...state,
        loading: true
      };
    }
    case EducationsActionTypes.SetEducations: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case EducationsActionTypes.AddEducationsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
