export enum Subdomain {
  home = "home",
  user = "user",
  homerent = "homerent",
  job = "job",
  career = "career",
  company = "company",
  learning = "learning",
  article = "article",
  Mosque = "mosque",
}
