import { Action } from '@ngrx/store';
import { IArticle } from '../../models/interfaces';
import { IError } from '@fusion/error/public-api';
import { FusionArticleError } from '../../models/enums';

export enum ArticleActionTypes {
  LoadArticle = '[Article] Load',
  LoadArticleSuccess = '[Article] Load Success',
  LoadArticleFail = '[Article] Load Fail',
  LoadDraftArticle = '[Article] Load Draft',
  LoadDraftArticleSuccess = '[Article] Load Draft Success',
  LoadDraftArticleFail = '[Article] Load Draft Fail',
  AddArticle = '[Article] Add',
  AddArticleSuccess = '[Article] Add Success',
  AddArticleFail = '[Article] Add Fail',
  UpdateArticle = '[Article] Update',
  UpdateArticleSuccess = '[Article] Update Success',
  UpdateArticleFail = '[Article] Update Fail',
  PublishArticle = '[Article] Publish',
  PublishArticleSuccess = '[Article] Publish Success',
  PublishArticleFail = '[Article] Publish Fail'
}

export enum ArticleErrorActionTypes {
  LoadArticleFail = '[Article] Load Fail',
  LoadDraftArticleFail = '[Article] Load Draft Fail',
  AddArticleFail = '[Article] Add Fail',
  UpdateArticleFail = '[Article] Update Fail',
  PublishArticleFail = '[Article] Publish Fail'
}

export class LoadArticle implements Action {
  readonly type = ArticleActionTypes.LoadArticle;
}

export class LoadArticleSuccess implements Action {
  readonly type = ArticleActionTypes.LoadArticleSuccess;

  constructor(public payload: any) {}
}

export class LoadArticleFail implements Action {
  readonly type = ArticleActionTypes.LoadArticleFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export class LoadDraftArticle implements Action {
  readonly type = ArticleActionTypes.LoadDraftArticle;
}

export class LoadDraftArticleSuccess implements Action {
  readonly type = ArticleActionTypes.LoadDraftArticleSuccess;

  constructor(public payload: any) {}
}

export class LoadDraftArticleFail implements Action {
  readonly type = ArticleActionTypes.LoadDraftArticleFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export class AddArticle implements Action {
  readonly type = ArticleActionTypes.AddArticle;
}

export class AddArticleSuccess implements Action {
  readonly type = ArticleActionTypes.AddArticleSuccess;

  constructor(public payload: any) {}
}

export class AddArticleFail implements Action {
  readonly type = ArticleActionTypes.AddArticleFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export class UpdateArticle implements Action {
  readonly type = ArticleActionTypes.UpdateArticle;

  constructor(public payload: IArticle) {}
}

export class UpdateArticleSuccess implements Action {
  readonly type = ArticleActionTypes.UpdateArticleSuccess;

  constructor(public payload: any) {}
}

export class UpdateArticleFail implements Action {
  readonly type = ArticleActionTypes.UpdateArticleFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export class PublishArticle implements Action {
  readonly type = ArticleActionTypes.PublishArticle;

  constructor(public payload: IArticle) {}
}

export class PublishArticleSuccess implements Action {
  readonly type = ArticleActionTypes.PublishArticleSuccess;

  constructor(public payload: any) {}
}

export class PublishArticleFail implements Action {
  readonly type = ArticleActionTypes.PublishArticleFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export type ArticleActions =
  | LoadArticle
  | LoadArticleSuccess
  | LoadArticleFail
  | LoadDraftArticle
  | LoadDraftArticleSuccess
  | LoadDraftArticleFail
  | AddArticle
  | AddArticleSuccess
  | AddArticleFail
  | UpdateArticle
  | UpdateArticleSuccess
  | UpdateArticleFail
  | PublishArticle
  | PublishArticleSuccess
  | PublishArticleFail;
