<div class="form-group">
  <div
    [ngClass]="{
      'has-error':
        !formValidationRules.controls[formConfigs.name].valid &&
        formValidationRules.controls[formConfigs.name].touched
    }"
  >
    <div
      class="radio-title"
      [ngClass]="{
        error:
          !formValidationRules.controls[formConfigs.name].valid &&
          formValidationRules.controls[formConfigs.name].touched
      }"
    >
      {{ formConfigs.label }}
      <span
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('required')
        "
        >*</span
      >
    </div>
  </div>
  <div class="example-container">
    <mat-radio-group
      [formControl]="formValidationRules.controls[formConfigs.name]"
      required="{{formValidationRules.controls[formConfigs.name].hasError('required')}}"
    >
      <div *ngFor="let option of formConfigs.options" class="radio">
        <mat-radio-button
          class="example-radio-button"
          color="primary"
          name="{{formConfigs.name}}"
          value="{{option.value}}"
          (click)="update(!formValidationRules.controls[formConfigs.name].value)"
          >{{ option.label }}</mat-radio-button
        >
      </div>
    </mat-radio-group>
    <mat-error
      class="alert alert-danger"
      *ngIf="
        !formValidationRules.controls[formConfigs.name].valid &&
        formValidationRules.controls[formConfigs.name].touched
      "
    >
      You must select an option for '{{ formConfigs.label }}'.
    </mat-error>
  </div>
</div>
