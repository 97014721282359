import { createSelector } from '@ngrx/store';

import { getFusionoAuthState } from '../reducers';
import { getUserAppBaseUrl } from './applications.selectors';
import { IoAuthSession, IoAuthUser } from '../../models/interfaces';
import { IUser } from '@fusion/common';

export const getOauthState = createSelector(getFusionoAuthState, (state) => {
  if (state == null) {
    return null;
  }
  return state.oAuth;
});

export const getOauthProgressState = createSelector(getOauthState, (state) => {
  if (state == null) {
    return null;
  }
  return {
    isInProgress: state.isInProgress,
    isSuccess: state.isSuccess,
  };
});

export const getoAuthSession = createSelector(
  getOauthState,
  (oAuthSession): IoAuthSession => {
    if (oAuthSession === null) {
      return null;
    }
    return oAuthSession.session;
  }
);

export const getoAuthSessionToken = createSelector(
  getoAuthSession,
  (oAuthSession): string => {
    if (oAuthSession === null) {
      return null;
    }
    return oAuthSession.token;
  }
);

export const getoAuthDecodedToken = createSelector(
  getOauthState,
  (oAuthSession): IoAuthUser => {
    if (oAuthSession === null) {
      return null;
    }
    return oAuthSession.sessionProfile;
  }
);

export const getoAuthUser = createSelector(
  getoAuthDecodedToken,
  (decodedToken): IUser => {
    if (decodedToken === null) {
      return null;
    }
    return decodedToken.user;
  }
);

export const getoAuthUserId = createSelector(
  getoAuthUser,
  (oAuthUser): string => {
    if (oAuthUser === null) {
      return null;
    }
    return oAuthUser.id;
  }
);

export const getoAuthUserFullName = createSelector(
  getoAuthUser,
  (oAuthUser): string => {
    if (oAuthUser === null) {
      return null;
    }
    return oAuthUser.middleName
      ? `${oAuthUser.firstName} ${oAuthUser.middleName} ${oAuthUser.lastName}`
      : `${oAuthUser.firstName} ${oAuthUser.lastName}`;
  }
);

export const getoAuthUserProfilePath = createSelector(
  getoAuthUserId,
  getUserAppBaseUrl,
  (oAuthUserId, userAppBaseUrl): string => {
    if (oAuthUserId === null || userAppBaseUrl == null) {
      return null;
    }

    return `${userAppBaseUrl}/${oAuthUserId}`;
  }
);
