import {
  ApplicationsActionTypes,
  oAuthActionTypes,
  SignUpActionTypes,
  UserProfileActionTypes,
  UserActionTypes,
} from '../../store/actions';

export type FusionoAuthError =
  | ApplicationsActionTypes
  | oAuthActionTypes
  | SignUpActionTypes
  | UserProfileActionTypes
  | UserActionTypes;
export const FusionoAuthError = {
  ...ApplicationsActionTypes,
  ...oAuthActionTypes,
  ...SignUpActionTypes,
  ...UserProfileActionTypes,
  ...UserActionTypes,
};
