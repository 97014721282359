import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { IProfileNav, IEmptyState } from "@fusion/common";
import { ArticleState } from "../../store/reducers/index";
import { getRouterUrlRoot } from "@fusion/router";
import {
  getProfileNavigation,
  SetActiveProfileNavigation
} from "@fusion/subscription";
import {
  LoadPrivateArticles,
  getPrivateArticles,
  IArticle,
  AddArticle,
  isAddingDraftArticle,
  FusionArticleError
} from "@fusion/article";
import { getErrorHandlingConfig, IErrorHandlingConfig } from "@fusion/error";

@Component({
  selector: "article-add-article",
  templateUrl: "./add-article.component.html",
  styleUrls: ["./add-article.component.scss"]
})
export class AddArticleComponent implements OnInit {
  profileNav$: Observable<IProfileNav[]>;
  currentRootPath$: Observable<string>;
  articles$: Observable<IArticle[]>;
  isAddingArticle$: Observable<boolean>;
  addArticleErrorHandlingConfig$: Observable<IErrorHandlingConfig>;
  loadArticlesErrorHandlingConfig$: Observable<IErrorHandlingConfig>;

  emptyState: IEmptyState = {
    path: "/addarticle/new",
    card: {
      title: "add article",
      description: "Write & share your expertise!"
    },
    message: {
      title: "write article",
      description: "Let us find readers & followers for you!"
    }
  };

  constructor(private store: Store<ArticleState>) {}

  ngOnInit() {
    // load draft articles
    this.store.dispatch(new LoadPrivateArticles(false));

    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.profileNav$ = this.store.select(getProfileNavigation);
    this.articles$ = this.store.select(getPrivateArticles);
    this.isAddingArticle$ = this.store.select(isAddingDraftArticle);
    this.addArticleErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.AddArticleFail)
    );
    this.loadArticlesErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.LoadPrivateArticlesFail)
    );
  }

  onNotifyEmptyStateClick() {
    this.store.dispatch(new AddArticle());
  }
}
