import {
  PublicationsActionTypes,
  PublicationsActions
} from '../actions/publications.actions';
import { IPublication } from '../../models/interfaces';

export interface PublicationsState {
  data: IPublication[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: PublicationsState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: PublicationsActions
): PublicationsState {
  switch (action.type) {
    case PublicationsActionTypes.LoadPublications: {
      return {
        ...state,
        loading: true
      };
    }
    case PublicationsActionTypes.SetPublications: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case PublicationsActionTypes.LoadPublicationsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
