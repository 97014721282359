import { PROJECT_FORM } from "@fusion/common";
import { IForm, FormFieldType, FormFieldPattern } from "@fusion/form";

export const ProjectForm: IForm = {
  metadata: {
    name: PROJECT_FORM,
    title: "Add Project",
    submitButtonText: "Add Project",
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "530px"
    }
  },
  configs: [
    {
      label: "Project Name",
      name: "name",
      type: FormFieldType.Text,
      placeholder: "certification name",
      validation: {
        required: true,
        max: 300
      }
    },
    {
      label: "Role",
      name: "role",
      type: FormFieldType.Text,
      placeholder: "certification authority",
      validation: {
        required: true,
        max: 100
      }
    },
    {
      label: "Start Date",
      name: "startDate",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "End Date",
      name: "endDate",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "",
      type: FormFieldType.Checkbox,
      items: [
        {
          label: "Project ongoing",
          name: "ongoing",
          value: "remember",
          validation: {
            required: false
          }
        }
      ],

      disable: {
        by: "ongoing",
        fields: ["endDate"]
      }
    },
    {
      label: "Project URL",
      name: "website",
      type: FormFieldType.Url,
      placeholder: "http://elitecareer.net",
      examplePattern: "http://elitecareer.net",
      validation: {
        required: false,
        max: 300,
        pattern: FormFieldPattern.Url
      }
    },
    {
      label: "Description",
      name: "description",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        max: 15000
      }
    }
  ]
};
