<div *ngIf="formConfigs && formValidationRules" class="row">
  <div
    *ngFor="
      let config of getRequiredConfig(formConfigs, ['propertyName', 'sqft'])
    "
  >
    <div class="col-9" *ngIf="config.name == 'propertyName'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
    <div class="col-3" *ngIf="config.name == 'sqft'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
  </div>
</div>

<div *ngIf="formConfigs && formValidationRules" class="row">
  <div
    *ngFor="
      let config of getRequiredConfig(formConfigs, [
        'propertyType',
        'beds',
        'rooms',
        'baths'
      ])
    "
  >
    <div class="col-3" *ngIf="config.name == 'propertyType'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col-3" *ngIf="config.name == 'beds'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col-3" *ngIf="config.name == 'rooms'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col-3" *ngIf="config.name == 'baths'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
  </div>
</div>

<div *ngIf="formConfigs && formValidationRules" class="row">
  <div
    *ngFor="
      let config of getRequiredConfig(formConfigs, [
        'price',
        'deposite',
        'currencyCode',
        'paymentCycle'
      ])
    "
  >
    <div class="col-3" *ngIf="config.name == 'price'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
    <div class="col-3" *ngIf="config.name == 'deposite'">
      <fusion-form-text-field
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
    <div class="col-3" *ngIf="config.name == 'currencyCode'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col-3" *ngIf="config.name == 'paymentCycle'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
  </div>
</div>

<div *ngIf="formConfigs && formValidationRules" class="row">
  <div
    *ngFor="
      let config of getRequiredConfig(formConfigs, ['familySize', 'kidsPolicy'])
    "
  >
    <div class="col-6" *ngIf="config.name == 'familySize'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col-6" *ngIf="config.name == 'kidsPolicy'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
  </div>
</div>

<div *ngIf="formConfigs && formValidationRules" class="row">
  <div
    *ngFor="
      let config of getRequiredConfig(formConfigs, ['terms', 'verifications'])
    "
  >
    <div class="col-6" *ngIf="config.name == 'terms'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
    <div class="col-6" *ngIf="config.name == 'verifications'">
      <fusion-form-dropdown
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
  </div>
</div>
