import { Action } from "@ngrx/store";
import { IPermission, ICompanyPermission } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";

export enum PermissionsActionTypes {
  LoadPermissions = "[Permissions] Load",
  LoadPermissionsSuccess = "[Permissions] Load Success",
  LoadPermissionsFail = "[Permissions] Load Fail",
  SetPermissionsProvider = "[Permissions] Set Provider",
  AddPermissions = "[Permissions] Add",
  AddPermissionsSuccess = "[Permissions] Add Success",
  AddPermissionsFail = "[Permissions] Add Fail",
  UpdatePermissions = "[Permissions] Update",
  UpdatePermissionsSuccess = "[Permissions] Update Success",
  UpdatePermissionsFail = "[Permissions] Update Fail"
}

export class LoadPermissions implements Action {
  readonly type = PermissionsActionTypes.LoadPermissions;
}

export class LoadPermissionsSuccess implements Action {
  readonly type = PermissionsActionTypes.LoadPermissionsSuccess;

  constructor(public payload: ICompanyPermission) {}
}

export class LoadPermissionsFail implements Action {
  readonly type = PermissionsActionTypes.LoadPermissionsFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export class SetPermissionsProvider implements Action {
  readonly type = PermissionsActionTypes.SetPermissionsProvider;

  constructor(public payload: IPermission) {}
}

export class AddPermissions implements Action {
  readonly type = PermissionsActionTypes.AddPermissions;

  constructor(public payload: IPermission) {}
}

export class AddPermissionsSuccess implements Action {
  readonly type = PermissionsActionTypes.AddPermissionsSuccess;

  constructor(public payload: any) {}
}

export class AddPermissionsFail implements Action {
  readonly type = PermissionsActionTypes.AddPermissionsFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export class UpdatePermissions implements Action {
  readonly type = PermissionsActionTypes.UpdatePermissions;

  constructor(public payload: IPermission) {}
}

export class UpdatePermissionsSuccess implements Action {
  readonly type = PermissionsActionTypes.UpdatePermissionsSuccess;

  constructor(public payload: any) {}
}

export class UpdatePermissionsFail implements Action {
  readonly type = PermissionsActionTypes.UpdatePermissionsFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export type PermissionsActions =
  | LoadPermissions
  | LoadPermissionsSuccess
  | LoadPermissionsFail
  | SetPermissionsProvider
  | AddPermissions
  | AddPermissionsSuccess
  | AddPermissionsFail
  | UpdatePermissions
  | UpdatePermissionsSuccess
  | UpdatePermissionsFail;
