import { Action } from '@ngrx/store';
import { IoAuthCredentials } from '@fusion/common';
import { IError } from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { IoAuthSession } from '../../models/interfaces';
import { NavigationExtras } from '@angular/router';

export enum oAuthActionTypes {
  oAuthStart = '[oAuth] Start',
  oAuthSuccess = '[oAuth] Success',
  oAuthFail = '[oAuth] Fail',
  SessionStart = '[oAuth] Session Start',
  SessionEnd = '[oAuth] Session End',
  SessionRestore = '[oAuth] Session Restore',
  SessionRestoreSuccess = '[oAuth] Session Restore Success',
  SessionRestoreFail = '[oAuth] Session Restore Fail',
  TokenDecode = '[oAuth] Token Decode',
  TokenDecodeSuccess = '[oAuth] Token Decode Success',
  TokenDecodeFail = '[oAuth] Token Decode Fail',
  TokenRefresh = '[oAuth] Token Refresh',
  TokenRefreshSuccess = '[oAuth] Token Refresh Success',
  TokenRefreshFail = '[oAuth] Token Refresh Fail',
  RedirectToHomeApp = '[oAuth] Redirect To Home App',
  RedirectToUserProfileApp = '[oAuth] Redirect To User Profile App',
}

export class oAuthStart implements Action {
  readonly type = oAuthActionTypes.oAuthStart;

  constructor(public payload: IoAuthCredentials) {}
}

export class oAuthSuccess implements Action {
  readonly type = oAuthActionTypes.oAuthSuccess;

  constructor(public payload: IoAuthSession) {}
}

export class oAuthFail implements Action {
  readonly type = oAuthActionTypes.oAuthFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class SessionStart implements Action {
  readonly type = oAuthActionTypes.SessionStart;

  constructor(public payload: any) {}
}

export class SessionEnd implements Action {
  readonly type = oAuthActionTypes.SessionEnd;
}

export class SessionRestore implements Action {
  readonly type = oAuthActionTypes.SessionRestore;
}

export class SessionRestoreSuccess implements Action {
  readonly type = oAuthActionTypes.SessionRestoreSuccess;

  constructor(public payload: IoAuthSession) {}
}

export class SessionRestoreFail implements Action {
  readonly type = oAuthActionTypes.SessionRestoreFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class TokenDecode implements Action {
  readonly type = oAuthActionTypes.TokenDecode;

  constructor(public payload: any) {}
}

export class TokenDecodeSuccess implements Action {
  readonly type = oAuthActionTypes.TokenDecodeSuccess;

  constructor(public payload: any) {}
}

export class TokenDecodeFail implements Action {
  readonly type = oAuthActionTypes.TokenDecodeFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class TokenRefresh implements Action {
  readonly type = oAuthActionTypes.TokenRefresh;
}

export class TokenRefreshSuccess implements Action {
  readonly type = oAuthActionTypes.TokenRefreshSuccess;

  constructor(public payload: IoAuthSession) {}
}

export class TokenRefreshFail implements Action {
  readonly type = oAuthActionTypes.TokenRefreshFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class RedirectToHomeApp implements Action {
  readonly type = oAuthActionTypes.RedirectToHomeApp;
}

export class RedirectToUserProfileApp implements Action {
  readonly type = oAuthActionTypes.RedirectToUserProfileApp;

  constructor(public payload?: NavigationExtras) {}
}

export type oAuthActions =
  | oAuthStart
  | oAuthSuccess
  | oAuthFail
  | SessionStart
  | SessionEnd
  | SessionRestore
  | SessionRestoreSuccess
  | SessionRestoreFail
  | TokenDecode
  | TokenDecodeSuccess
  | TokenDecodeFail
  | TokenRefresh
  | TokenRefreshSuccess
  | TokenRefreshFail
  | RedirectToHomeApp
  | RedirectToUserProfileApp;
