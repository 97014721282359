import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fusion-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit {
  @Input() subscriptions: any[];
  @Input() isOnlySubscriptions: boolean;
  @Output() notifyViewApplication: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifySubscribe: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
