import { IForm, FormFieldType, FormFieldPattern } from "@fusion/form";

export const SignInForm: IForm = {
  metadata: {
    name: "SIGN_IN_FORM",
    title: "Sign In",
    submitButtonText: "Sign In",
    style: {
      topMargin: "0px",
      maxWidth: "500px",
      maxHeight: "300px"
    }
  },
  configs: [
    {
      label: "Email",
      name: "email",
      type: FormFieldType.Email,
      placeholder: "john.deep@example.com",
      examplePattern: "john.deep@example.com",
      validation: {
        required: true,
        pattern: FormFieldPattern.Email
      }
    },
    {
      label: "Password",
      name: "password",
      type: FormFieldType.Password,
      placeholder: "subject",
      validation: {
        required: true
      }
    },
    {
      label: "",
      type: FormFieldType.Checkbox,
      options: [
        {
          label: "Remember me",
          name: "remember",
          value: "remember",
          validations: {
            required: false
          }
        }
      ]
    }
  ]
};
