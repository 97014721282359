<div *ngIf="formConfigs && formValidationRules" class="row">
  <div *ngFor="let config of formConfigs">
    <div class="col-7">
      <fusion-form-text-field
        *ngIf="config.group == 'search' && config.type == 'text'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </div>
    <div class="col-5">
      <fusion-form-dropdown
        *ngIf="config.group == 'search' && config.type == 'dropdown'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-dropdown>
    </div>
  </div>
</div>

<!-- TODO remove this section -->
<div *ngIf="searchForm">
  <mat-form-field class="search-box">
    <mat-label>{{ searchForm?.search.label }}</mat-label>
    <input
      matInput
      [value]="keywords"
      placeholder="{{ searchForm?.search.paceholder }}"
      (keyup)="notifySearch.emit($event.target.value)"
    />
  </mat-form-field>

  <mat-form-field class="search-category">
    <mat-label>{{ searchForm?.searchDropdown.label }}</mat-label>
    <mat-select>
      <mat-option
        *ngFor="let category of searchForm?.searchDropdown.categories"
        value="{{ category.code || category }}"
        (click)="notifySearchCategory.emit(category)"
        >{{ category.name || category }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</div>
