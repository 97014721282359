<div card class="row3">
  <!-- section header -->
  <fusion-career-profile-card-header
    class="row3__section-one"
    [isColor]="false"
    [isEditable]="true"
    [title]="'Educations'"
    [buttonTitle]="'Add'"
    [iconType]="ModalIconType.AddIcon"
    [formValidationRules]="educationFormValidationRules"
    [formConfigs]="educationFormConfigs"
    [formMetadata]="educationFormMetadata"
    [autoCompleteEntities]="autoCompleteEntities"
    (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    (notifyAutocompletion)="notifyAutocompletion.emit($event)"
  ></fusion-career-profile-card-header>

  <!-- section body -->
  <div class="row3__section-two">
    <div
      hover
      bottomDivider
      cardSpacing
      career
      *ngFor="let education of educations"
    >
      <!-- logo -->
      <div careerLogo smallLogo>
        <img
          imageFit
          src="{{
            education.company.logo ||
              '/assets/images/users/company-logo/logoPlaceholder.png'
          }}"
        />
      </div>

      <!-- detail -->
      <div careerDetail>
        <!-- position -->
        <div careerDetailHeader>
          <h5 g6FontColor>{{ education?.company.name }}</h5>
          <div exSmallTitle g6FontColor>
            {{ education?.degreeType }} <span class="dot"></span>
            {{ education.major }}
          </div>
          <div microTitle g5FontColor>
            {{ education?.startDate | date: "MMM, yyyy" }} -
            {{
              education.graduationDate
                ? (education.graduationDate | date: "MMM, yyyy")
                : "current"
            }}
          </div>
          <div microTitle g5FontColor>
            {{ education?.company.address }}, {{ education?.company.city }} -
            {{ education?.company.zipCode }}
          </div>
        </div>

        <!-- summary -->
        <div careerDetailSummary *ngIf="isExpanded && education.activities">
          <h6 g6FontColor>Activities</h6>
          <div [innerHTML]="education.activities | truncate: 1000"></div>
        </div>
      </div>

      <!-- action buttons -->
      <div careerButton hoverDisplay>
        <button
          (click)="notifyDeleteEducation.emit(education.id)"
          class="custom-button"
          mat-fab
          color="accent"
        >
          <mat-icon>delete</mat-icon>
        </button>

        <fusion-career-profile-card-header
          [buttonTitle]="'Edit'"
          [iconType]="ModalIconType.EditIcon"
          [currentEntity]="education"
          [formValidationRules]="educationUpdateFormValidationRules"
          [formConfigs]="educationUpdateFormConfigs"
          [formMetadata]="educationUpdateFormMetadata"
          [autoCompleteEntities]="autoCompleteEntities"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
          (notifyAutocompletion)="notifyAutocompletion.emit($event)"
        ></fusion-career-profile-card-header>
      </div>
    </div>
  </div>

  <!-- section footer -->
  <div *ngIf="educations.length" class="row3__section-three">
    <fusion-common-card-footer
      (notifyCardFooterClick)="notifyCardFooterClick()"
      [title]="isExpanded ? 'View Less' : 'View All'"
    ></fusion-common-card-footer>
  </div>
</div>
