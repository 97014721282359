import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";

@Injectable({
  providedIn: "root"
})
export class EmployerService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.employer.host;
    this.api = this.configService.getConfig().services.employer.api;
  }

  getEmployer(employerId): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/employers/` + employerId;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getCompany(companyId): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/companies/` + companyId;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getCompanies(employerId): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/employers/${employerId}/companies`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postCompany(companyData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/companies`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, companyData, options);
  }

  updateCompany(companyId: string, companyData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/companies/` + companyId;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, companyData, options);
  }

  postEmployer(userData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/employers`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, userData, options);
  }

  postExperience(experienceData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/employees`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, experienceData, options);
  }

  updateExperience(experienceData, experienceId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/employees/${experienceId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, experienceData, options);
  }

  deleteExperience(experienceId: string, userId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/employees/${experienceId}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }

  postEducation(educationData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/educations`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, educationData, options);
  }

  updateEducation(educationData, educationId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/educations/${educationId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, educationData, options);
  }

  deleteEducation(educationId: string, userId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/educations/${educationId}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }

  getUnclaimedCompanies(name, isClaimed: boolean): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/companies?name=${name}&claimed=${isClaimed}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postCompanyClaim(companyId: string, employerId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/companies/${companyId}/claim`;
    const companyData = {
      employer_id: employerId
    };
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, companyData, options);
  }

  searchCompanies(name): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/companies?name=${name}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getRepresentativesRoleProfile(employerId): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/representatives?employer_id=` + employerId;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  uploadLogo(companyId: string, imagePayload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/companies/${companyId}/images`;
    const options = this.requestOptionsService.generateOptions(false, true);

    const formData = new FormData();
    formData.append("file", imagePayload.blob);
    formData.append("name", imagePayload.name);
    formData.append("type", imagePayload.type);
    formData.append("reference", imagePayload.reference);
    return this._http.post(fullUrl, formData, options);
  }
}
