import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/index';
import { ErrorComponent } from './components/error/error.component';
import { ErrorHandlerDirective } from './directives/error-handler.directive';
import { CommonModule } from '@angular/common';
import { FusionMaterialModule } from '@fusion/material';
import {
  ErrorDialogComponent,
  ErrorDialogTemplateComponent
} from './components/error-dialog/error-dialog.component';

@NgModule({
  declarations: [
    ErrorComponent,
    ErrorHandlerDirective,
    ErrorDialogComponent,
    ErrorDialogTemplateComponent
  ],
  imports: [
    CommonModule,
    FusionMaterialModule,
    StoreModule.forFeature('fusionError', reducers)
  ],
  exports: [ErrorComponent, ErrorHandlerDirective],
  entryComponents: [
    ErrorComponent,
    ErrorDialogComponent,
    ErrorDialogTemplateComponent
  ]
})
export class FusionErrorModule {}
