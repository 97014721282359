import { createSelector } from "@ngrx/store";

import { getFusionCompanyState } from "../reducers/index";
import { ICompany } from "../../models/interfaces";

export const getCompanyState = createSelector(getFusionCompanyState, state => {
  if (state == null) {
    return null;
  }
  return state.company;
});

export const getCompany = createSelector(
  getCompanyState,
  (state): ICompany => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
