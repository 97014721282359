import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const CommunityServicesForm: IForm = {
  metadata: {
    name: constants.COMMUNITY_SERVICES_FORM,
    title: "Community Services",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "360px"
    }
  },
  configs: [
    {
      label: "",
      type: FormFieldType.Checkbox,
      isMultiColumnCheckbox: true,
      items: [
        {
          label: "Public Transportation",
          name: "public_transportation",
          value: "public_transportation",
          validation: {
            required: false
          }
        },
        {
          label: "Public Library",
          name: "public_library",
          value: "public_library",
          validation: {
            required: false
          }
        },
        {
          label: "College/University",
          name: "college",
          value: "college",
          validation: {
            required: false
          }
        },
        {
          label: "Grade School",
          name: "grade_school",
          value: "grade_school",
          validation: {
            required: false
          }
        },
        {
          label: "High School",
          name: "high_school",
          value: "high_school",
          validation: {
            required: false
          }
        },
        {
          label: "Parks/Recreation",
          name: "parks",
          value: "parks",
          validation: {
            required: false
          }
        },
        {
          label: "Shopping",
          name: "shopping",
          value: "shopping",
          validation: {
            required: false
          }
        },
        {
          label: "Mosque",
          name: "mosque",
          value: "mosque",
          validation: {
            required: false
          }
        },
        {
          label: "Church",
          name: "church",
          value: "church",
          validation: {
            required: false
          }
        },
        {
          label: "Funeral Homes",
          name: "funeral",
          value: "funeral_homes",
          validation: {
            required: false
          }
        },
        {
          label: "Airport",
          name: "airport",
          value: "airport",
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
