import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { INavigation, topNavigation, ICompany, IUser } from "@fusion/common";
import { FormGroup, FormBuilder } from "@angular/forms";
import { FusionoAuthState } from "../../store/reducers/index";
import { Store } from "@ngrx/store";
import {
  oAuthStart,
  SessionEnd,
  SignUpStart,
  LoadApplications,
  RedirectToHomeApp,
} from "../../store/actions/index";
import {
  getoAuthSessionToken,
  getoAuthUserFullName,
  getoAuthUserProfilePath,
  getoAuthCompanies,
  getCompanyAppBaseUrl,
  getHomeAppBaseUrl,
  getoAuthUser,
  getAppBaseUrls,
} from "../../store/selectors/index";
import { Observable } from "rxjs";
import {
  ModalIconType,
  setFormValidationRules,
  IFormMetadata,
  IFormConfig,
} from "@fusion/form";
import { SignInForm, SignUpForm } from "../../models/forms";
import { NavService } from "../../services/nav/nav.service";
import { IBaseUrls } from '../../models/interfaces';

@Component({
  selector: "fusion-oauth-top-nav",
  templateUrl: "./top-nav.component.html",
  styleUrls: ["./top-nav.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavComponent implements OnInit {
  token$: Observable<string>;
  oAuthUser$: Observable<IUser>;
  companyAppBaseUrl$: Observable<string>;
  appBaseUrls$: Observable<IBaseUrls>;
  homeAppBaseUrl$: Observable<string>;
  oAuthUserName$: Observable<string>;
  authUserProfilePath$: Observable<string>;
  oAuthCompanies$: Observable<ICompany[]>;
  topNavigation: INavigation[] = topNavigation;

  baseUrl: string;
  ModalIconType = ModalIconType;

  // form templates
  signInFormMetadata: IFormMetadata = SignInForm.metadata;
  signInFormConfigs: IFormConfig[] = SignInForm.configs;
  signInFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(SignInForm.configs)
  );

  signUpFormMetadata: IFormMetadata = SignUpForm.metadata;
  signUpFormConfigs: IFormConfig[] = SignUpForm.configs;
  signUpFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(SignUpForm.configs)
  );

  constructor(
    private store: Store<FusionoAuthState>,
    private fb: FormBuilder,
    private navService: NavService
  ) {}

  ngOnInit() {
    // this.store.dispatch(new SessionRestore());
    this.store.dispatch(new LoadApplications());

    this.token$ = this.store.select(getoAuthSessionToken);
    this.oAuthUserName$ = this.store.select(getoAuthUserFullName);
    this.oAuthUser$ = this.store.select(getoAuthUser);
    this.authUserProfilePath$ = this.store.select(getoAuthUserProfilePath);
    this.oAuthCompanies$ = this.store.select(getoAuthCompanies);
    this.companyAppBaseUrl$ = this.store.select(getCompanyAppBaseUrl);
    this.appBaseUrls$ = this.store.select(getAppBaseUrls);
    this.homeAppBaseUrl$ = this.store.select(getHomeAppBaseUrl);
  }

  toggleNav() {
    this.navService.toggleNav();
    this.navService.toggleNavResponsive();
  }

  notifySignInFormSubmit(formData) {
    this.store.dispatch(new oAuthStart(formData.data));
  }

  notifySignUpFormSubmit(formData) {
    this.store.dispatch(new SignUpStart(formData.data));
  }

  signOut() {
    this.store.dispatch(new SessionEnd());
  }

  onClickOfBrand() {
    this.store.dispatch(new RedirectToHomeApp());
  }

  navigateToUserProfile(baseUrl: string) {
    window.location.href = `${baseUrl}`;
  }

  navigateToCompany(
    appBaseUrls: IBaseUrls,
    company: ICompany
  ) {
    if (company.category === "Mosque") {
      window.location.href = `${appBaseUrls.mosque}/${company.id}`;
    } else {
      window.location.href = `${appBaseUrls.company}/${company.id}`;
    }
  }
}
