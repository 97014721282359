export const jobCategories = [
  'Industrial & Production Engineering',
  'Admin/Management',
  'HR',
  'Commercial/Supply Chain',
  'Account/Finance',
  'Garments/Textile',
  'Chemist',
  'Production',
  'Electrical',
  'Mechanical',
  'Engineering',
  'Information Technology',
  'Marketing/Merchandising/Sales',
  'Architect',
  'Bank/Finance/Insurance',
  'Massmedia',
  'Design/Art',
  'Software/Programer',
  'Spa/Salon/Fitness',
  'Call Center/Customer Support',
  'NGO',
  'Automotive',
  'Biotech',
  'Business Development',
  'Government/Federal',
  'Installation/Maint/Repair',
  'Manufacturing',
  'Research',
  'Telecommunications',
  'Transportation',
  'Writing/Editing',
  'Advertisement',
  'Consultant/Training',
  'Receptionist/Customer Care',
  'Doctor/Pharmaceutical/Nurse',
  'Hotel Management',
  'Education',
  'Law',
  'Security/Support Service',
  'Travel/Tourism',
  'Data Entry',
  'Agriculture/Fishery/Livestock',
];
