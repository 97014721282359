<div class="header col2">
  <div centering center logo class="col2__section-one">
    <img
      imageFit
      src="{{
        company?.logo || '/assets/images/users/company-logo/logoPlaceholder.png'
      }}"
    />
  </div>

  <div class="col2__section-two row2">
    <div class="row2__section-one">
      <h3>{{ company?.name }}</h3>
    </div>
    <div class="row2__section-two">
      <div smallTitle>{{ company?.category }}</div>
      <div extraSmallTitle>
        Greater {{ company?.city }} City Area,
        {{ company?.state ? company?.state + ", " : "" }}
        {{ company?.country }} -
        {{ company?.zipCode }}
      </div>
      <!-- <fusion-common-social-icons></fusion-common-social-icons> -->
    </div>
  </div>
</div>
