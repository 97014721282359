import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const SharedServicesForm: IForm = {
  metadata: {
    name: constants.SHARED_SERVICES_FORM,
    title: "Shared Services",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "500px",
      maxHeight: "300px"
    }
  },
  configs: [
    {
      label: "",
      type: FormFieldType.Checkbox,
      isMultiColumnCheckbox: false,
      items: [
        {
          label: "Bedroom",
          name: "bed",
          value: "bed",
          validation: {
            required: false
          }
        },
        {
          label: "Bathroom",
          name: "bath",
          value: "bath",
          validation: {
            required: false
          }
        },
        {
          label: "Kitchen",
          name: "kitchen",
          value: "kitchen",
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
