<div class="form-group">
  <div
    class="example-container"
    [ngClass]="{
      colN: formConfigs.isMultiColumnCheckbox
    }"
  >
    <div *ngFor="let item of formConfigs.items" class="checkbox">
      <mat-checkbox
        color="primary"
        ariaLabel="Some Lebel"
        name="{{ item.name }}"
        value="{{ item.value }}"
        [formControl]="formValidationRules.controls[item.name]"
        required="{{ item.validation.required }}"
        (click)="update(!formValidationRules.controls[item.name].value)"
      >
        <span
          [ngClass]="{
            error:
              !formValidationRules.controls[item.name].valid &&
              !formValidationRules.controls[item.name].disabled &&
              formValidationRules.controls[item.name].touched
          }"
          >{{ item.label }}
          <span
            *ngIf="formValidationRules.controls[item.name].hasError('required')"
            >*</span
          >
        </span>
      </mat-checkbox>
      <mat-error
        class="checkbox-error"
        *ngIf="
          !formValidationRules.controls[item.name].valid &&
          !formValidationRules.controls[item.name].disabled &&
          formValidationRules.controls[item.name].touched
        "
      >
        You must select {{ item.label }}.
      </mat-error>
    </div>
  </div>
</div>
