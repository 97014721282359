import { createSelector } from "@ngrx/store";

import { getFusionArticleState } from "../reducers";
import { IArticle } from "../../models/interfaces";

export const getArticleState = createSelector(getFusionArticleState, state => {
  if (state == null) {
    return null;
  }
  return state.article;
});

export const getCurrentArticleState = createSelector(getArticleState, state => {
  if (state == null) {
    return null;
  }
  return state.current;
});

export const getCurrentArticle = createSelector(
  getCurrentArticleState,
  (state): IArticle => {
    if (state == null) {
      return null;
    }
    return state.article;
  }
);

export const getDraftArticleState = createSelector(getArticleState, state => {
  if (state == null) {
    return null;
  }
  return state.draft;
});

export const isAddingDraftArticle = createSelector(
  getDraftArticleState,
  (state): boolean => {
    if (state == null) {
      return null;
    }
    return state.adding;
  }
);

export const isArticleUpdating = createSelector(
  getDraftArticleState,
  (state): boolean => {
    if (state == null) {
      return null;
    }
    return state.updating;
  }
);

export const getDraftArticle = createSelector(
  getDraftArticleState,
  (state): IArticle => {
    if (state == null) {
      return null;
    }
    return state.article;
  }
);
