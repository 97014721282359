import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class NavService {
  appDrawer: any;
  appDrawerResponsive: any;
  currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  closeNav() {
    this.appDrawer.close();
  }

  openNav() {
    this.appDrawer.open();
  }

  toggleNav() {
    if (this.appDrawer) {
      this.appDrawer.toggle();
    }
  }

  toggleNavResponsive() {
    if (this.appDrawerResponsive) {
      this.appDrawerResponsive.toggle();
    }
  }
}
