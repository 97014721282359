import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { IProfileNav } from "@fusion/common";
import { ArticleState } from "../../store";
import { getRouterUrlRoot } from "@fusion/router";
import {
  getProfileNavigation,
  SetActiveProfileNavigation
} from "@fusion/subscription";

@Component({
  selector: "article-publications",
  templateUrl: "./publications.component.html",
  styleUrls: ["./publications.component.scss"]
})
export class PublicationsComponent implements OnInit {
  profileNav$: Observable<IProfileNav[]>;
  currentRootPath$: Observable<string>;

  constructor(private store: Store<ArticleState>) {}

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.profileNav$ = this.store.select(getProfileNavigation);
  }
}
