import { IProfileNav } from '../models/interfaces/profile-nav.interface';

export function getActiveNav(navDataModel: IProfileNav[], currentPath: string) {
  return navDataModel.map((nav) => {
    if (nav.path === currentPath) {
      nav.active = true;
    } else {
      nav.active = false;
    }
    return nav;
  });
}
