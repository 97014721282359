import { SkillsActionTypes, SkillsActions } from '../actions/skills.actions';
import { ISkill } from '../../models/interfaces';

export interface SkillsState {
  data: ISkill[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: SkillsState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: SkillsActions
): SkillsState {
  switch (action.type) {
    case SkillsActionTypes.LoadSkills: {
      return {
        ...state,
        loading: true
      };
    }
    case SkillsActionTypes.SetSkills: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case SkillsActionTypes.LoadSkillsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
