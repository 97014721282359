import { Component, OnInit, Input } from '@angular/core';
import { ICompany } from '@fusion/common';

@Component({
  selector: 'fusion-company-summary',
  templateUrl: './company-summary.component.html',
  styleUrls: ['./company-summary.component.scss']
})
export class CompanySummaryComponent implements OnInit {
  @Input() company: ICompany;

  constructor() {}

  ngOnInit() {}
}
