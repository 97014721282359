import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  withLatestFrom,
  switchMap
} from "rxjs/operators";

import {
  ArticleActionTypes,
  LoadArticleSuccess,
  LoadArticleFail,
  AddArticleSuccess,
  AddArticleFail,
  AddArticle,
  UpdateArticleSuccess,
  UpdateArticleFail,
  UpdateArticle,
  PublishArticle,
  PublishArticleSuccess,
  PublishArticleFail,
  LoadArticle
} from "../actions/article.actions";
import { ArticleState } from "../reducers/article.reducer";
import { ArticleService, MappingService, mappingType } from "@fusion/service";
import {
  getRouterParams,
  getRouterUrl,
  getRouterUrlRoot,
  getRouterUrlBase
} from "@fusion/router";
import { Params, Router } from "@angular/router";
import { IArticle } from "../../models/interfaces";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionArticleError } from "../../models/enums";

@Injectable()
export class ArticleEffects {
  constructor(
    private actions$: Actions,
    private store: Store<ArticleState>,
    private articleService: ArticleService,
    private mappingService: MappingService,
    private router: Router
  ) {}

  @Effect()
  loadArticle$ = this.actions$.pipe(
    ofType(ArticleActionTypes.LoadArticle),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getRouterUrlRoot)
    ),
    mergeMap(([action, params, rootUrl]: [any, Params, string]) => {
      let errorPayload: IError<FusionArticleError> = {
        code: FusionArticleError.LoadArticleFail,
        source: ErrorSource.Validation,
        data: null
      };

      return this.articleService.getArticleById(params.articleId).pipe(
        switchMap(dataResult => {
          const mappedArticle = this.mappingService.getMappedData(
            dataResult,
            mappingType.camelize
          );
          return [new LoadArticleSuccess(mappedArticle)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Template,
              message:
                "Sorry, we are having some issue loading current article. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadArticle()],
                  title: "Retry"
                }
                // secondary: {
                //   type: ErrorActionType.Navigate,
                //   reference: rootUrl,
                //   title: 'Back'
                // }
              }
            }
          };
          return of(new LoadArticleFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  addArticle$ = this.actions$.pipe(
    ofType<AddArticle>(ArticleActionTypes.AddArticle),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getRouterUrl)
    ),
    mergeMap(([payload, params, url]: [any, Params, string]) => {
      let errorPayload: IError<FusionArticleError> = {
        code: FusionArticleError.AddArticleFail,
        source: ErrorSource.Validation,
        data: null
      };
      let article: IArticle = {
        authorId: params.subscriberId
      };
      const mappedArticle = this.mappingService.getMappedData(
        article,
        mappingType.underscore
      );
      return this.articleService.postArticle(mappedArticle).pipe(
        switchMap(dataResult => {
          this.router.navigate([`${url}/${dataResult.id}`]);
          return [new AddArticleSuccess(dataResult)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Template,
              message:
                "Sorry, we are having some issue creating your article. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new AddArticle()],
                  title: "Retry"
                }
              }
            }
          };
          return of(new AddArticleFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  publishArticle$ = this.actions$.pipe(
    ofType<PublishArticle>(ArticleActionTypes.PublishArticle),
    map(action => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getRouterUrlBase)
    ),
    mergeMap(([payload, params, baseUrl]: [IArticle, Params, string]) => {
      let errorPayload: IError<FusionArticleError> = {
        code: FusionArticleError.PublishArticleFail,
        source: ErrorSource.Validation,
        data: null
      };
      const articleId = params.articleId;
      let article: IArticle = {
        ...payload,
        authorId: params.subscriberId,
        updatedAt: new Date(),
        publishedAt: new Date(),
        active: true
      };
      const mappedArticle = this.mappingService.getMappedData(
        article,
        mappingType.underscore
      );
      return this.articleService.updateArticle(articleId, mappedArticle).pipe(
        switchMap(dataResult => {
          this.router.navigate([`${baseUrl}/myarticles`]);
          return [new PublishArticleSuccess(dataResult)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue publishing your article. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new PublishArticle(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new PublishArticleFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  updateArticle$ = this.actions$.pipe(
    ofType<UpdateArticle>(ArticleActionTypes.UpdateArticle),
    map(action => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getRouterUrlRoot)
    ),
    mergeMap(([payload, params, rootUrl]: [IArticle, Params, string]) => {
      let errorPayload: IError<FusionArticleError> = {
        code: FusionArticleError.UpdateArticleFail,
        source: ErrorSource.Validation,
        data: null
      };
      const articleId = params.articleId;
      const article = {
        ...payload,
        updatedAt: new Date(),
        authorId: params.subscriberId
      };
      const mappedArticle = this.mappingService.getMappedData(
        article,
        mappingType.underscore
      );
      return this.articleService.updateArticle(articleId, mappedArticle).pipe(
        switchMap(dataResult => {
          return [new UpdateArticleSuccess(dataResult)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue updating your article. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UpdateArticle(payload), new LoadArticle()],
                  title: "Retry"
                },
                secondary: {
                  type: ErrorActionType.Navigate,
                  reference: rootUrl,
                  title: "Back"
                }
              }
            }
          };
          return of(new UpdateArticleFail(errorPayload));
        })
      );
    })
  );
}
