import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const CoverLetterForm: IForm = {
  metadata: {
    name: constants.COVER_LETTER_FORM,
    title: "Sign In",
    submitButtonText: "Next",
    style: {
      topMargin: "0px",
      maxWidth: "540px",
      maxHeight: "380px"
    }
  },
  configs: [
    {
      label: "Cover Letter",
      name: "coverLetter",
      type: FormFieldType.Ckeditor,
      placeholder: "add your cover letter",
      validation: {
        required: true,
        min: 10,
        max: 300
      }
    }
  ]
};
