import { createSelector } from '@ngrx/store';

import { getLocationState } from '../reducers';

export const getPlacesState = createSelector(
  getLocationState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.places;
  }
);
