<div class="row3">
  <div bottomDivider cardSpacing tRadius class="row3__section-one col2">
    <div smallLogo class="col2__section-one">
      <img
        imageFit
        src="{{
          company?.logo ||
            '/assets/images/users/company-logo/logoPlaceholder.png'
        }}"
      />
    </div>
    <div class="col2__section-two">
      <h5 g6FontColor>{{ company.name }}</h5>
      <div xSmallTitle g5FontColor class="">
        {{ company.type }} <span class="dot"></span> {{ company.category }}
      </div>
      <div microTitle g4FontColor class="">
        {{ company.city }}, {{ company.state }} - {{ company.zipCode }}
      </div>
    </div>
  </div>
  <div
    cardSpacing
    class="row3__section-two"
    [innerHTML]="company?.companyDetail | truncate: 230"
  ></div>
  <div cardSpacing centeringRight class="row3__section-three">
    <button mat-stroked-button (click)="notifyViewCompany.emit(company.id)">
      View Company Profile
    </button>
  </div>
</div>
