import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, map, switchMap } from 'rxjs/operators';

import {
  SignUpActionTypes,
  SignUpSuccess,
  SignUpFail,
  SignUpStart,
} from '../actions/sign-up.actions';
import { FusionoAuthState } from '../reducers';
import { Store } from '@ngrx/store';
import { MappingService, mappingType, TokenService } from '@fusion/service';
import { IUser, IoAuthCredentials } from '@fusion/common';
import { oAuthStart } from '../actions';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';

@Injectable()
export class SignUpEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionoAuthState>,
    private tokenService: TokenService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<SignUpStart>(SignUpActionTypes.SignUpStart),
    map((action) => action.payload),
    mergeMap((payload: IUser) => {
      let errorPayload: IError<FusionoAuthError> = {
        code: FusionoAuthError.SignUpFail,
        source: ErrorSource.Validation,
        data: null,
      };

      const credential: IoAuthCredentials = {
        email: payload.email,
        password: payload.password,
      };
      const mappedUser = this.mappingService.getMappedData(
        payload,
        mappingType.underscore
      );
      return this.tokenService.postUser(mappedUser).pipe(
        switchMap((dataResult) => {
          return [new SignUpSuccess(dataResult), new oAuthStart(credential)];
        }),
        catchError((error) => {
          const dupEmail = `'${payload.email}' email is already registered.`;
          const code = error.status;
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                code === 409
                  ? dupEmail
                  : 'Sorry, we are having some issue creating your account. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new SignUpStart(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new SignUpFail(errorPayload));
        })
      );
    })
  );
}
