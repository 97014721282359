import { CompanyActionTypes, CompanyActions } from "../actions/company.actions";
import { ICompany } from "../../models/interfaces";

export interface CompanyState {
  data: ICompany;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CompanyState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CompanyActions
): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.LoadCompany: {
      return {
        ...state,
        loading: true
      };
    }
    case CompanyActionTypes.LoadCompanySuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case CompanyActionTypes.LoadCompanyFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
