import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';

import {
  ArticlesActionTypes,
  LoadArticlesSuccess,
  LoadArticlesFail,
  LoadArticles
} from '../actions/articles.actions';
import { FusionArticleState } from '../reducers';
import { ArticleService, MappingService, mappingType } from '@fusion/service';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from '@fusion/error';
import { FusionArticleError } from '../../models/enums';

@Injectable()
export class ArticlesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionArticleState>,
    private articleService: ArticleService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType(ArticlesActionTypes.LoadArticles),
    mergeMap((action: any) => {
      let errorPayload: IError<FusionArticleError> = {
        code: FusionArticleError.UpdateArticleFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.articleService.getArticles().pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData(
            dataResult,
            mappingType.camelize
          );
          return [new LoadArticlesSuccess(mappedData)];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading articles. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadArticles()],
                  title: 'Retry'
                }
              }
            }
          };
          return of(new LoadArticlesFail(errorPayload));
        })
      );
    })
  );
}
