import { Component, OnInit, Input } from "@angular/core";
import { ICompany } from "../../models/interfaces";

@Component({
  selector: "fusion-company-header",
  templateUrl: "./company-header.component.html",
  styleUrls: ["./company-header.component.scss"]
})
export class CompanyHeaderComponent implements OnInit {
  @Input() company: ICompany;

  constructor() {}

  ngOnInit() {}
}
