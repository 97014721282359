<div class="rowN">
  <div *ngFor="let article of articles" class="article-preview col2">
    <div class="col2__section-one">
      <div
        pointer
        mediumTitle
        semiBold
        routerLink="{{ getPreviewDir(article.id) }}"
      >
        {{ article.title || 'Untitled article'}}
      </div>
      <div extraSmallFont class="subtitle">{{ article.subtitle }}</div>
      <div g5FontColor extraSmallFont class="t-padding-4">
        <span *ngIf="article.active; else today">Published {{
          article?.publishedAt | fancyTime
        }} </span>
        <ng-template #today>
            <span>Last updated {{article.updatedAt | fancyTime}} </span>
          </ng-template>
        <span class="dot"></span><span> {{article?.author?.firstName}} {{article?.author?.lastName}}</span>
      </div>
    </div>
    <div
      *ngIf="article.bannerImage"
      pointer
      image
      class="col2__section-two"
      routerLink="{{ getPreviewDir(article.id) }}"
    >
      <img
        imageFit
        class="radius"
        [src]="article.bannerImage"
      />
    </div>
  </div>
</div>
