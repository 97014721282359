import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromPlaces from './places.reducer';

export interface LocationState {
  places: fromPlaces.PlacesState;
}

export const reducers: ActionReducerMap<LocationState> = {
  places: fromPlaces.reducer
};

export const getLocationState = createFeatureSelector<LocationState>(
  'location'
);

// export reducer state interfaces
