import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { IExperience } from '../../models/interfaces';

export const getExperiencesState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.experiences;
  }
);

export const getExperiences = createSelector(
  getExperiencesState,
  (experience): IExperience[] => {
    if (experience == null) {
      return null;
    }
    return experience.data;
  }
);
