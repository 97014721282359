<div class="row2">
  <div class="row2__section-one">
    <div border radius image class="banner">
      <img *ngIf="bannerImage" radius imageFit [src]="bannerImage.url" />
      <div imageOverlayLight centering center g3FontColor>
        Banner Image
      </div>
    </div>
  </div>

  <div class="row2__section-two colN">
    <ng-container *ngFor="let image of images">
      <div image>
        <img radius imageFit [src]="image.url" />
        <div imageOverlayTransparent class="row2">
          <div centering centerRight>
            <mat-icon
              pointer
              aria-hidden="false"
              aria-label="selected"
              color="warn"
              (click)="notifyImageDelete.emit(image)"
              >cancel</mat-icon
            >
          </div>
          <div centering center>
            <button
              mat-stroked-button
              color="accent"
              (click)="notifyBannerSelection.emit(image)"
            >
              Select as Banner
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
