import { SubscriptionsEffects } from './subscriptions.effects';
import { SubscribeEffects } from './subscribe.effects';
import { RedirectSubscriberEffects } from './redirect-subscriber.effects';
import { CurrentAppEffects } from './current-app.effects';
import { ProfileNavigationEffects } from './profile-navigation.effects';
import { CurrentSubscriptionEffects } from './current-subscription.effects';
import { PermissionsEffects } from './permissions.effects';
import { ErrorEffects } from './error.effects';

// include all effects
export const effects: any[] = [
  SubscriptionsEffects,
  SubscribeEffects,
  RedirectSubscriberEffects,
  CurrentAppEffects,
  ProfileNavigationEffects,
  CurrentSubscriptionEffects,
  PermissionsEffects,
  ErrorEffects
];

// export from effects
