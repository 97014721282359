import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
  // ElementRef
} from '@angular/core';
import { IFilePayload } from '@fusion/common';
import { S3Service } from '@fusion/service';

@Component({
  selector: 'fusion-form-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @Input() allowedTypes: string[] = [];
  @Input() filePath: string;
  @Input() isPreview: boolean;
  @Output()
  notifyFileUpload: EventEmitter<IFilePayload> = new EventEmitter<
    IFilePayload
  >();
  private readonly supportedPreviewFileType = ['png', 'jpg', 'jpeg', 'gif'];
  selectedFiles: FileList;
  isUploadFail: boolean;
  isValidFileType: boolean;
  selectedFile: File;
  selectedFileExtension: string;
  value: string;
  url: string;

  constructor(private uploadService: S3Service) {}

  ngOnInit() {}

  @ViewChild('inputFile', { static: false }) inputFile: any;
  // fileInput: ElementRef;
  selectFile($event) {
    this.selectedFiles = $event.target.files;

    if (this.selectedFiles && this.selectedFiles.item(0)) {
      this.selectedFile = this.selectedFiles.item(0);
      this.selectedFileExtension = this.selectedFile.name.split('.').pop();
      this.selectedFileExtension = this.selectedFile.name.split('.').pop();
      this.isValidFileType = this.isValidFile(this.selectedFileExtension);
      this.url = null;
      this.setPreview();
    }

    if (this.selectedFiles && this.isValidFileType) {
      // this.uploadService.uploadfile(this.selectedFile, this.filePath);

      this.notifyFileUpload.emit({
        service: 'user',
        path: this.filePath,
        fileName: this.selectedFile.name,
        fileContent: this.selectedFile
      });
    }
  }

  setPreview() {
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile); // read file as data url
      reader.onload = ($event) => {
        this.url = <string>$event.target['result'];
      };
    }
  }

  isValidFile(currentType): boolean {
    return this.allowedTypes.indexOf(currentType.toLowerCase()) > -1 || false;
  }

  isPreviewSupported(): boolean {
    return (
      this.supportedPreviewFileType.indexOf(
        this.selectedFileExtension.toLowerCase()
      ) > -1 || false
    );
  }
}
