import { IFormConfig, IFormFieldValidation } from "../interfaces";
import { FormFieldType } from "../enums";
import {
  Validators,
  ValidatorFn,
  FormGroup,
  FormControl,
} from "@angular/forms";

export function setFormValidationRules(
  formConfigs: IFormConfig[]
): { [key: string]: (boolean | ValidatorFn)[] } {
  let mappedRules: { [key: string]: (boolean | ValidatorFn)[] } = {};

  formConfigs.map((formField) => {
    if (formField.type === FormFieldType.Group) {
      formField.configs.map((field) => {
        if (field.name && field.validation) {
          mappedRules[field.name] = getValidations(field.validation, null);
        }

        if (field.items) {
          field.items.map((item) => {
            mappedRules[item.name] = getValidations(item.validation, false);
          });
        }
      });
    }

    if (formField.name && formField.validation) {
      mappedRules[formField.name] = getValidations(formField.validation, null);
    }

    if (formField.items) {
      formField.items.map((item) => {
        mappedRules[item.name] = getValidations(item.validation, false);
      });
    }
  });

  return mappedRules;
}

function getValidations(
  validation: IFormFieldValidation,
  defaultValue?: boolean
): (boolean | ValidatorFn)[] {
  let validationRules: ValidatorFn[] = getValidationRules(validation);
  return [defaultValue, Validators.compose(validationRules)];
}

// add/remove new display controllers
export function setDisplayControllers(
  formConfigs: IFormConfig[],
  formGroup: FormGroup,
  isMergeDisplayConfigs: boolean
) {
  const displayItem: IFormConfig = formConfigs.find((conf) => conf.display);
  if (displayItem && displayItem.display.configs) {
    const displayConfigs = displayItem.display.configs;
    displayConfigs.map((conf) => {
      if (isMergeDisplayConfigs) {
        addControllers(conf, formGroup);
      } else {
        removeControllers(conf, formGroup);
      }
    });
  }
}

function addControllers(conf: IFormConfig, formGroup: FormGroup) {
  if (conf.validation) {
    const controller = getFormController(conf.validation);
    formGroup.addControl(conf.name, controller);
  }

  if (conf.items) {
    conf.items.map((item) => {
      const controller = getFormController(item.validation, "");
      formGroup.addControl(item.name, controller);
    });
  }
}

function removeControllers(conf: IFormConfig, formGroup: FormGroup) {
  if (conf.validation) {
    formGroup.removeControl(conf.name);
  }

  if (conf.items) {
    conf.items.map((item) => {
      formGroup.removeControl(item.name);
    });
  }
}

function getFormController(
  validation: IFormFieldValidation,
  defaultValue?: string | boolean
): FormControl {
  let validationRules: ValidatorFn[] = getValidationRules(validation);
  const validators =
    validationRules.length > 0 ? [Validators.compose(validationRules)] : [];
  return new FormControl(defaultValue, validators);
}

function getValidationRules(validation: IFormFieldValidation): ValidatorFn[] {
  let validationRules: ValidatorFn[] = [];
  if (validation.required) {
    validationRules = [...validationRules, Validators.required];
  }
  if (validation.pattern) {
    validationRules = [
      ...validationRules,
      Validators.pattern(validation.pattern),
    ];
  }
  if (validation.max) {
    validationRules = [
      ...validationRules,
      Validators.maxLength(validation.max),
    ];
  }
  if (validation.min) {
    validationRules = [
      ...validationRules,
      Validators.minLength(validation.min),
    ];
  }
  return validationRules;
}

export function getUpdatedFormConfigs(
  formConfigs: IFormConfig[],
  isMergeDisplayConfigs?: boolean
): IFormConfig[] {
  const displayItem: IFormConfig = formConfigs.find((conf) => conf.display);
  if (displayItem) {
    const displayConfigs = displayItem.display.configs;
    if (isMergeDisplayConfigs) {
      formConfigs = [...formConfigs, ...displayConfigs];
    } else {
      formConfigs = formConfigs.filter(
        (conf) => !displayConfigs.includes(conf)
      );
    }
  }

  return formConfigs;
}
