import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import { mergeMap, catchError, map } from "rxjs/operators";

import {
  RatingActionTypes,
  LoadRating,
  LoadRatingSuccess,
  LoadRatingFail
} from "../actions/index";

@Injectable()
export class RatingEffects {
  constructor(public actions$: Actions) {}

  @Effect()
  getRating$ = this.actions$.pipe(
    ofType<LoadRating>(RatingActionTypes.LoadRating),
    map(action => action.payload),
    mergeMap((payload: any) => {
      return [new LoadRatingSuccess(payload)];
    })
  );
}
