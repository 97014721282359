import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  CanActivateChild
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { FusionSubscriptionState } from '../reducers';
import { LoadCurrentApp } from '../actions';

@Injectable({
  providedIn: 'root'
})
export class ApplicationGuard implements CanActivateChild {
  constructor(private store: Store<FusionSubscriptionState>) {}
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let applicationId = next.data.applicationId;
    let currentPath = next.data.currentPath || '';
    this.store.dispatch(
      new LoadCurrentApp({
        applicationId,
        currentPath
      })
    );
    return of(true);
  }
}
