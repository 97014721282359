import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { ISubscriptionPayload } from "../../models/interfaces";

export enum SubscribeActionTypes {
  SubscribeStart = "[Subscribe] Start",
  SubscribeSuccess = "[Subscribe] Success",
  SubscribeFail = "[Subscribe] Fail"
}

export class SubscribeStart implements Action {
  readonly type = SubscribeActionTypes.SubscribeStart;

  constructor(public payload: ISubscriptionPayload) {}
}

export class SubscribeSuccess implements Action {
  readonly type = SubscribeActionTypes.SubscribeSuccess;

  constructor(public payload: any) {}
}

export class SubscribeFail implements Action {
  readonly type = SubscribeActionTypes.SubscribeFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export type SubscribeActions =
  | SubscribeStart
  | SubscribeSuccess
  | SubscribeFail;
