<!-- Facebook -->
<a
  href="http://www.facebook.com/sharer.php?u=https://elitecareer.net{{
    currentUrl
  }}&amp;hashtag=%23job"
  target="_blank"
>
  <mat-icon color="{{ color }}" svgIcon="facebook-box"></mat-icon>
</a>

<!-- LinkedIn -->
<a
  href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://elitecareer.net{{
    currentUrl
  }}&amp;hashtags=job,jobs"
  target="_blank"
>
  <mat-icon color="{{ color }}" svgIcon="linkedin-box"></mat-icon>
</a>

<!-- Twitter -->
<a
  href="https://twitter.com/share?url=https://elitecareer.net{{
    currentUrl
  }}&amp;text=Simple%20Share%20Buttons&amp;hashtags=job,jobs"
  target="_blank"
>
  <mat-icon color="{{ color }}" svgIcon="twitter-box"></mat-icon>
</a>

<!-- Google+ -->
<a
  href="https://plus.google.com/share?url=https://elitecareer.net"
  target="_blank"
>
  <mat-icon color="{{ color }}" svgIcon="google-plus-box"></mat-icon>
</a>
