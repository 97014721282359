import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { IProfileNav } from "@fusion/common";
import { ArticleState } from "../../store/reducers";
import { getRouterUrlRoot } from "@fusion/router";
import {
  getProfileNavigation,
  SetActiveProfileNavigation
} from "@fusion/subscription";
import {
  IArticle,
  LoadPrivateArticles,
  getPrivateArticles,
  FusionArticleError
} from "@fusion/article";
import { IErrorHandlingConfig, getErrorHandlingConfig } from "@fusion/error";

@Component({
  selector: "article-my-articles",
  templateUrl: "./my-articles.component.html",
  styleUrls: ["./my-articles.component.scss"]
})
export class MyArticlesComponent implements OnInit {
  profileNav$: Observable<IProfileNav[]>;
  currentRootPath$: Observable<string>;
  articles$: Observable<IArticle[]>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;

  constructor(private store: Store<ArticleState>) {}

  ngOnInit() {
    // load active private articles
    this.store.dispatch(new LoadPrivateArticles(true));

    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.profileNav$ = this.store.select(getProfileNavigation);
    this.articles$ = this.store.select(getPrivateArticles);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.LoadPrivateArticlesFail)
    );
  }
}
