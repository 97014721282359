import { Component, OnInit, Input } from '@angular/core';
import { IPanel } from '../../models/interfaces/panel.interface';

@Component({
  selector: 'fusion-common-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {
  @Input() panels: IPanel[];

  constructor() {}

  ngOnInit() {}
}
