<div pageSpacing largePage pageBody>
  <div pageTitle>
    <fusion-common-back
      [currentRootPath]="currentRootPath$ | async"
      [targetDir]="''"
      [buttonText]="'back'"
    ></fusion-common-back>
  </div>
  <div pageContent>
    <div content>
      <ng-container
        *ngIf="
          errorHandlingConfig$ | async as errorHandlingConfig;
          else articleView
        "
      >
        <ng-template [fusionErrorHandler]="errorHandlingConfig"></ng-template>
      </ng-container>
      <ng-template #articleView>
        <fusion-article-view [article]="article$ | async"></fusion-article-view>
      </ng-template>
    </div>
    <div adSpace></div>
  </div>
</div>
