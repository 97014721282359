import { Component, OnInit, Inject, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { IErrorHandlingConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  @Input() errorHandlingConfig: IErrorHandlingConfig;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ErrorDialogTemplateComponent, {
      disableClose: true,
      data: this.errorHandlingConfig
    });
  }
}

@Component({
  selector: 'fusion-error-dialog-template',
  templateUrl: 'error-dialog-template.component.html'
})
export class ErrorDialogTemplateComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public errorHandlingConfig: IErrorHandlingConfig
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onNotifyErrorHandlingAction() {
    this.dialogRef.close();
  }
}
