<div class="carousel-container">
  <div *ngFor="let carouselItem of carouselItems; let i = index">
    <div
      class="carousel-{{i}}"
      [ngClass]="{ active: isActive(i), inactive: !isActive(i) }"
    >
      <!-- <img class="carousel-img" src={{carouselItem.img}} /> -->
      <div radius whiteFontColor class="row2 message-section v-padding-6">
        <div centering exxlargeTitle microBold class="row2__section-one">
          {{ carouselItem.title }}
        </div>
        <div centering mediumFont class="row2__section-two">
          {{ carouselItem.description }}
        </div>
      </div>
    </div>
    <!--
      <img src="assets/images/carousel/left-chevron-active.png" class="prev" alt="Left Arrow" (click)="previous()">
      <img src="assets/images/carousel/right-chevron-active.png" class="next" alt="Right Arrow" (click)="next()">
    -->
  </div>
  <div class="indicators">
    <span
      class="dot"
      (click)="currentSlide(i)"
      *ngFor="let carouselItem of carouselItems; let i = index"
      [ngClass]="{ active: currentIndex === i }"
    >
    </span>
  </div>
</div>
