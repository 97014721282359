<div class="row card">
  <div *ngIf="formConfig && validationRules" class="col-12">
    <div
      *ngFor="let config of formConfig"
      class="list-option card-content-padding"
    >
      <mat-selection-list color="primary" dense>
        <h6>{{ config.label }}</h6>
        <mat-list-option
          color="primary"
          selected="{{getSelectionState(option, config.name, selectedJobLevels, selectedJobTypes)}}"
          #selectedOption
          class="options"
          *ngFor="let option of config.options"
          (click)="submitOption(option, config.name, selectedOption.selected)"
        >
          {{ option }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
</div>
