import {
  PermissionsActionTypes,
  PermissionsActions
} from "../actions/permissions.actions";
import { ICompanyPermission, IPermission } from "../../models/interfaces";

export interface PermissionsState {
  companyPermission: ICompanyPermission;
  currentPermission: IPermission;
  loading: boolean;
  loaded: boolean;
}

export const initialState: PermissionsState = {
  companyPermission: null,
  currentPermission: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: PermissionsActions
): PermissionsState {
  switch (action.type) {
    case PermissionsActionTypes.LoadPermissions: {
      return {
        ...state,
        loading: true
      };
    }
    case PermissionsActionTypes.LoadPermissionsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        companyPermission: action.payload
      };
    }
    case PermissionsActionTypes.LoadPermissionsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case PermissionsActionTypes.SetPermissionsProvider: {
      return {
        ...state,
        currentPermission: action.payload
      };
    }
  }
  return state;
}
