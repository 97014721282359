import * as constants from "../constants";
import * as dataModel from "../data";
import { IForm } from "../interfaces";
import { FormFieldType, FormFieldGroup } from "../enums";

export const LeaseTermsForm: IForm = {
  metadata: {
    name: constants.LEASE_TERMS_FORM,
    title: "Lease Terms",
    submitButtonText: "Next",
    stepper: {
      isStepper: true,
      isFirstStep: false
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "300px"
    }
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.HomeProperty,
      configs: [
        {
          label: "Available Lease Terms",
          name: "terms",
          type: FormFieldType.Dropdown,
          isMultiSelectDropdown: true,
          options: dataModel.leaseTerms,
          validation: {
            required: false
          }
        },
        {
          label: "Required Verifications",
          name: "verifications",
          type: FormFieldType.Dropdown,
          isMultiSelectDropdown: true,
          options: dataModel.leaseVarifications,
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
