import { Injectable } from '@angular/core';
import { IError } from '../../models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor() {}

  error(message: IError<any> | Error) {
    console.error(message);
  }
}
