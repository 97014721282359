import { createSelector } from "@ngrx/store";

import { getFusionoAuthState } from "../reducers";
import { getRouterParams } from "@fusion/router";
import { getoAuthUserId } from "./oauth.selectors";
import { UserType } from "@fusion/common";

export const getUserState = createSelector(getFusionoAuthState, state => {
  if (state == null) {
    return null;
  }
  return state.user;
});

export const getUserType = createSelector(
  getRouterParams,
  getoAuthUserId,
  (params, userId): UserType => {
    if (params == null || userId == null) {
      return null;
    }
    if (params.subscriberId === userId) {
      return UserType.User;
    }
    if (params.userId) {
      return UserType.User;
    }
    return UserType.Company;
  }
);
