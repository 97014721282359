import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import {
  IErrorHandlingConfig,
  IErrorAction,
  IAction
} from '../../models/interfaces';
import { Store, Action } from '@ngrx/store';
import { FusionErrorState } from '../../store/reducers';
import { ResetError } from '../../store';
import { Router, NavigationEnd } from '@angular/router';
import { ErrorActionType } from '../../models/enums';

export interface IErrorComponentConfig {
  errorHandlingConfig: IErrorHandlingConfig;
}

@Component({
  selector: 'fusion-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  @Input() errorHandlingConfig: IErrorHandlingConfig;
  @Output() notifyErrorHandlingAction: EventEmitter<any> = new EventEmitter<
    any
  >();

  constructor(private store: Store<FusionErrorState>, private router: Router) {}

  ngOnInit() {}

  errorHandlingActionPrimary(action: IAction) {
    this.processErrorHandlingAction(action);
  }

  errorHandlingActionSecondary(action: IAction) {
    this.processErrorHandlingAction(action);
  }

  processErrorHandlingAction(action: IAction) {
    this.notifyErrorHandlingAction.emit();
    this.store.dispatch(new ResetError());
    switch (action.type) {
      case ErrorActionType.Dispatch: {
        const actions: Action[] = <Action[]>action.reference;
        actions.forEach((a) => this.store.dispatch(a));
        break;
      }
      case ErrorActionType.Navigate: {
        this.router.navigate([<string>action.reference]);
        break;
      }
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetError());
  }
}
