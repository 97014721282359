import { Action } from "@ngrx/store";
import { ISkill } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum SkillsActionTypes {
  SetSkills = "[Skills] Set",
  LoadSkills = "[Skills] Load",
  LoadSkillsSuccess = "[Skills] Load Success",
  LoadSkillsFail = "[Skills] Load Fail"
}

export class LoadSkills implements Action {
  readonly type = SkillsActionTypes.LoadSkills;

  constructor(public payload: ISkill[]) {}
}

export class SetSkills implements Action {
  readonly type = SkillsActionTypes.SetSkills;

  constructor(public payload: ISkill[]) {}
}

export class LoadSkillsSuccess implements Action {
  readonly type = SkillsActionTypes.LoadSkillsSuccess;

  constructor(public payload: any) {}
}

export class LoadSkillsFail implements Action {
  readonly type = SkillsActionTypes.LoadSkillsFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type SkillsActions =
  | SetSkills
  | LoadSkills
  | LoadSkillsSuccess
  | LoadSkillsFail;
