import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

import {
  ProfileNavigationActionTypes,
  SetProfileNavigationSuccess,
  SetProfileNavigationFail,
  SetProfileNavigation
} from '../actions/profile-navigation.actions';
import { IError, ErrorSource } from '@fusion/error';
import { FusionSubscriptionError } from '../../models/enums';

@Injectable()
export class ProfileNavigationEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<SetProfileNavigation>(
      ProfileNavigationActionTypes.SetProfileNavigation
    ),
    map((action) => action.payload),
    mergeMap((payload: any) => of(new SetProfileNavigationSuccess(payload))),
    catchError((error) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.SetProfileNavigationFail,
        source: ErrorSource.Validation,
        data: error
      };
      return of(new SetProfileNavigationFail(errorPayload));
    })
  );
}
