import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IExperience } from "../../models/interfaces";
import {
  ModalIconType,
  setFormValidationRules,
  IFormMetadata,
  IFormConfig
} from "@fusion/form";
import { ExperienceForm, ExperienceUpdateForm } from "../../models/forms";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "fusion-career-profile-experiences",
  templateUrl: "./experiences.component.html",
  styleUrls: ["./experiences.component.scss"]
})
export class ExperiencesComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() notifyDeleteExperience: EventEmitter<any> = new EventEmitter<any>();
  @Input() experiences: IExperience[];
  @Input() autoCompleteEntities: any;

  // form setup
  experienceFormMetadata: IFormMetadata = ExperienceForm.metadata;
  experienceFormConfigs: IFormConfig[] = ExperienceForm.configs;
  experienceFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(ExperienceForm.configs)
  );
  experienceUpdateFormMetadata: IFormMetadata = ExperienceUpdateForm.metadata;
  experienceUpdateFormConfigs: IFormConfig[] = ExperienceUpdateForm.configs;
  experienceUpdateFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(ExperienceUpdateForm.configs)
  );

  isExpanded: boolean = false;
  ModalIconType = ModalIconType;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  notifyCardFooterClick() {
    this.isExpanded = this.isExpanded ? false : true;
  }
}
