import { Action } from "@ngrx/store";
import { IExperience } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum ExperiencesActionTypes {
  SetExperiences = "[Experiences] Set",
  LoadExperiences = "[Experiences] Load",
  LoadExperiencesSuccess = "[Experiences] Load Success",
  LoadExperiencesFail = "[Experiences] Load Fail",
  AddExperiences = "[Experiences] Add",
  AddExperiencesSuccess = "[Experiences] Add Success",
  AddExperiencesFail = "[Experiences] Add Fail",
  UpdateExperiences = "[Experiences] Update",
  UpdateExperiencesSuccess = "[Experiences] Update Success",
  UpdateExperiencesFail = "[Experiences] Update Fail",
  DeleteExperiences = "[Experiences] Delete",
  DeleteExperiencesSuccess = "[Experiences] Delete Success",
  DeleteExperiencesFail = "[Experiences] Delete Fail"
}

export class SetExperiences implements Action {
  readonly type = ExperiencesActionTypes.SetExperiences;

  constructor(public payload: IExperience[]) {}
}

export class LoadExperiences implements Action {
  readonly type = ExperiencesActionTypes.LoadExperiences;

  constructor(public payload: IExperience[]) {}
}

export class LoadExperiencesSuccess implements Action {
  readonly type = ExperiencesActionTypes.LoadExperiencesSuccess;

  constructor(public payload: any) {}
}

export class LoadExperiencesFail implements Action {
  readonly type = ExperiencesActionTypes.LoadExperiencesFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class AddExperiences implements Action {
  readonly type = ExperiencesActionTypes.AddExperiences;

  constructor(public payload: any) {}
}

export class AddExperiencesSuccess implements Action {
  readonly type = ExperiencesActionTypes.AddExperiencesSuccess;

  constructor(public payload: any) {}
}

export class AddExperiencesFail implements Action {
  readonly type = ExperiencesActionTypes.AddExperiencesFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class UpdateExperiences implements Action {
  readonly type = ExperiencesActionTypes.UpdateExperiences;

  constructor(public payload: any) {}
}

export class UpdateExperiencesSuccess implements Action {
  readonly type = ExperiencesActionTypes.UpdateExperiencesSuccess;

  constructor(public payload: any) {}
}

export class UpdateExperiencesFail implements Action {
  readonly type = ExperiencesActionTypes.UpdateExperiencesFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class DeleteExperiences implements Action {
  readonly type = ExperiencesActionTypes.DeleteExperiences;

  constructor(public payload: string) {}
}

export class DeleteExperiencesSuccess implements Action {
  readonly type = ExperiencesActionTypes.DeleteExperiencesSuccess;

  constructor(public payload: any) {}
}

export class DeleteExperiencesFail implements Action {
  readonly type = ExperiencesActionTypes.DeleteExperiencesFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type ExperiencesActions =
  | SetExperiences
  | LoadExperiences
  | LoadExperiencesSuccess
  | LoadExperiencesFail
  | AddExperiences
  | AddExperiencesSuccess
  | AddExperiencesFail
  | UpdateExperiences
  | UpdateExperiencesSuccess
  | UpdateExperiencesFail
  | DeleteExperiences
  | DeleteExperiencesSuccess
  | DeleteExperiencesFail;
