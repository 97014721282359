<mat-chip-list>
  <mat-chip *ngFor="let data of metadata" color="{{chipColor}}" selected="true">
    <span
      *ngIf="data.salary"
      [innerHTML]="
        (data.salary | currency: data.currencyCode) + ' / ' + data.unit
      "
    ></span>
    <span *ngIf="!data.currencyCode">{{ data }}</span>
    <span *ngIf="!data.salary && data.currencyCode">Negotiable</span>
  </mat-chip>
</mat-chip-list>
