import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { IArticle } from '../../models/interfaces';
import { ImageUpload } from './quill-image-upload';
import { RequestOptionsService, ConfigService } from '@fusion/service';
import { FormGroup, FormControl } from '@angular/forms';

// declare const require: any;
// const Quill = require('quill');
// Quill.register('modules/imageUpload', ImageUpload);

declare const require: any;
const Quill = require('quill');
Quill.register(Quill.import('attributors/attribute/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/background'), true);
Quill.register(Quill.import('attributors/style/color'), true);
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/font'), true);
Quill.register(Quill.import('attributors/style/size'), true);
Quill.register(Quill.import('attributors/class/color'), true);
Quill.register(Quill.import('attributors/class/direction'), true);
Quill.register(Quill.import('attributors/class/font'), true);
Quill.register(Quill.import('attributors/class/size'), true);
Quill.register('modules/imageUpload', ImageUpload);

@Component({
  selector: 'fusion-article-editor',
  templateUrl: './article-editor.component.html',
  styleUrls: ['./article-editor.component.scss'],
})
export class ArticleEditorComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Output() notifyArticleSubmit: EventEmitter<IArticle> = new EventEmitter<
    IArticle
  >();
  @Output() notifyArticlePublish: EventEmitter<IArticle> = new EventEmitter<
    IArticle
  >();
  @Input() currentRootPath: string;
  currentArticle: IArticle;
  @Input() isUpdating: boolean;
  quillConfig = {
    toolbar: '.toolbar',
    imageUpload: {
      url: '',
      method: 'POST',
      headers: {
        Authorization: ``,
      },
      callbackOK: (serverResponse: Object, next: Function) => {
        next(serverResponse);
      },
      callbackKO: (serverError: string) => {
        alert(serverError);
      },
      checkBeforeSend: (file: Object, next: Function) => {
        next(file);
      },
    },
  };
  articleFormGroup: FormGroup;
  isUnsaved: boolean;
  currentImage;
  quillEditorRef;
  maxUploadFileSize = 1000000;

  constructor(
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {}

  ngOnInit() {
    this.isUnsaved = false;
    // this.article = {
    //   title: '',
    //   subtitle: '',
    //   content: ''
    // };
    // this.setTextareaProperty();
    // this.initArticleFormGroup(this.article);
  }

  @Input()
  set article(article: IArticle) {
    this.currentArticle = article;
    this.initArticleFormGroup(article);
  }

  private initArticleFormGroup(article: IArticle) {
    this.articleFormGroup = new FormGroup({
      title: new FormControl(article ? article.title : null),
      subtitle: new FormControl(article ? article.subtitle : null),
      content: new FormControl(article ? article.content : null),
    });
  }

  getFormControlValue(key: string) {
    return this.articleFormGroup.get(key).value;
  }

  ngAfterViewInit() {
    // this.setTextareaProperty();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentArticle && this.currentArticle.id) {
      this.quillConfig.imageUpload.url =
        this.configService.getConfig().services.article.host +
        `/api/v1/articles/${this.currentArticle.id}/image`;
    }
    this.quillConfig.imageUpload.headers.Authorization = this.requestOptionsService
      .generateOptions()
      .headers.get('Authorization');
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.currentArticle = {
          ...this.currentArticle,
          bannerImage: reader.result,
        };
      };
    }
  }

  onSelectionChanged = (event) => {
    // console.log('On onSelectionChanged', event);
    if (event.oldRange == null) {
      // this.onFocus();
    }
    if (event.range == null) {
      // this.onBlur();
    }
  };

  onContentChanged = (event) => {
    // console.log(event);
  };

  onFocus = (data) => {
    // console.log('On Focus', data);
  };
  saveArticle() {
    this.isUnsaved = false;
    this.currentArticle = {
      ...this.currentArticle,
      ...this.articleFormGroup.value
    };
    this.notifyArticleSubmit.emit(this.currentArticle);
  }

  unsavedArticle() {
    this.isUnsaved = true;
    // this.notifyArticleSubmit.emit(this.article);
  }

  publishArticle() {
    this.notifyArticlePublish.emit(this.currentArticle);
  }

  setTextareaProperty() {
    const areas = ['title-textarea', 'subtitle-textarea'];
    areas.map((ar) => {
      const textarea = document.getElementById(ar);
      const heightLimit = 200; /* Maximum height: 200px */
      textarea.onmouseover = function () {
        textarea.style.height = ''; /* Reset the height*/
        textarea.style.height =
          Math.min(textarea.scrollHeight, heightLimit) + 'px';
      };
      textarea.oninput = function () {
        textarea.style.height = ''; /* Reset the height*/
        textarea.style.height =
          Math.min(textarea.scrollHeight, heightLimit) + 'px';
      };
    });
  }

  getEditorInstance(editorInstance: any) {
    this.quillEditorRef = editorInstance;

    // const toolbar = editorInstance.getModule('toolbar');
    // toolbar.addHandler('image', ImageUpload);
  }

  // imageHandler = (image, callback) => {
  //   const input = <HTMLInputElement> document.getElementById('fileInputField');
  //   document.getElementById('fileInputField').onchange = () => {
  //     let file: File;
  //     file = input.files[0];
  //     // file type is only image.
  //     if (/^image\//.test(file.type)) {
  //       if (file.size > this.maxUploadFileSize) {
  //         alert('Image needs to be less than 1MB');
  //       } else {
  //         const reader  = new FileReader();
  //         reader.onload = () =>  {
  //           const range = this.quillEditorRef.getSelection();
  //           const img = '<img src="' + reader.result + '" />';
  //           this.quillEditorRef.clipboard.dangerouslyPasteHTML(range.index, img);
  //           // this.quillEditorRef.insertEmbed(1, 'image', reader.result);
  //         };
  //         reader.readAsDataURL(file);
  //       }
  //     } else {
  //         alert('You could only upload images.');
  //     }
  //   };

  //   input.click();
  // }
}
