import { oAuthActionTypes, oAuthActions } from '../actions/oauth.actions';
import { IoAuthSession, IoAuthUser } from '../../models/interfaces';

export interface oAuthState {
  session: IoAuthSession;
  sessionProfile: IoAuthUser;
  isInProgress: boolean;
  isSuccess: boolean;
}

export const initialState: oAuthState = {
  session: null,
  sessionProfile: null,
  isInProgress: false,
  isSuccess: false
};

export function reducer(
  state = initialState,
  action: oAuthActions
): oAuthState {
  switch (action.type) {
    case oAuthActionTypes.oAuthStart: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case oAuthActionTypes.oAuthSuccess:
    case oAuthActionTypes.SessionRestoreSuccess:
    case oAuthActionTypes.TokenRefreshSuccess: {
      return {
        ...state,
        isInProgress: false,
        session: action.payload,
      };
    }
    case oAuthActionTypes.SessionEnd: {
      return initialState;
    }
    case oAuthActionTypes.TokenDecodeSuccess: {
      return {
        ...state,
        sessionProfile: action.payload,
      };
    }
    case oAuthActionTypes.oAuthFail: {
      return {
        ...state,
        isInProgress: false,
      };
    }
  }
  return state;
}
