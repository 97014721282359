import { Action } from '@ngrx/store';

export enum PlacesActionTypes {
  LoadPlaces = '[Places] Load',
  LoadPlacesSuccess = '[Places] Load Success',
  LoadPlacesFail = '[Places] Load Fail',
  LoadPlacesDetails = '[Places] Load Detail',
  LoadPlacesDetailsSuccess = '[Places] Load Details Success',
  LoadPlacesDetailsFail = '[Places] Load Details Fail',
  SetSessionToken = '[Places] Set Session Token',
  ResetSessionToken = '[Places] Reset Session Token',
}

export class LoadPlaces implements Action {
  readonly type = PlacesActionTypes.LoadPlaces;

  constructor( public payload: string ) { }
}

export class LoadPlacesSuccess implements Action {
  readonly type = PlacesActionTypes.LoadPlacesSuccess;

  constructor( public payload: any ) { }
}

export class LoadPlacesFail implements Action {
  readonly type = PlacesActionTypes.LoadPlacesFail;
}

export class LoadPlacesDetails implements Action {
  readonly type = PlacesActionTypes.LoadPlacesDetails;

  constructor( public payload: string ) { }
}

export class LoadPlacesDetailsSuccess implements Action {
  readonly type = PlacesActionTypes.LoadPlacesDetailsSuccess;

  constructor( public payload: any ) { }
}

export class LoadPlacesDetailsFail implements Action {
  readonly type = PlacesActionTypes.LoadPlacesDetailsFail;
}

export class SetSessionToken implements Action {
  readonly type = PlacesActionTypes.SetSessionToken;

  constructor( public payload: string ) { }
}

export class ResetSessionToken implements Action {
  readonly type = PlacesActionTypes.ResetSessionToken;
}

export type PlacesActions =
  | LoadPlaces
  | LoadPlacesSuccess
  | LoadPlacesFail
  | LoadPlacesDetails
  | LoadPlacesDetailsSuccess
  | LoadPlacesDetailsFail
  | SetSessionToken
  | ResetSessionToken;
