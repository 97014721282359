import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { IRating } from '../../models/interfaces';

export const getRatingState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.rating;
  }
);

export const getRatingData = createSelector(
  getRatingState,
  (rating) => {
    if (rating == null) {
      return null;
    }
    return rating.data;
  }
);

export const getRatings = createSelector(
  getRatingData,
  (ratings): IRating[] => {
    if (ratings == null) {
      return null;
    }
    const maxCount = Math.max.apply(
      Math,
      ratings.map((r) => r.count).map(Number)
    );

    return ratings.map((r) => {
      const abc = {
        rating: r.rating,
        count: r.count,
        parcent: getPercentage(maxCount, r.count)
      };
      return Object.assign({}, abc);
    });
  }
);

function getPercentage(max, current) {
  if (max === current) {
    return 100;
  } else {
    return (100 / max) * current;
  }
}
