import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const SearchCompanyForm: IForm = {
  metadata: {
    name: constants.SEARCH_COMPANY_FORM,
    title: "Search Company",
    submitButtonText: "Next",
    style: {
      topMargin: "0px"
    }
  },
  configs: [
    {
      label: "Search your company",
      name: "name",
      type: FormFieldType.Text,
      placeholder: "Search your company",
      validation: {
        required: true
      }
    }
  ]
};
