import * as constants from "../constants";
import { jobTypes } from "../data";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const ManageJobApplicationForm: IForm = {
  metadata: {
    name: constants.COVER_LETTER_FORM,
    title: "Manage this Application",
    submitButtonText: "Update",
    style: {
      topMargin: "0px",
      maxWidth: "740px",
      maxHeight: "400px"
    }
  },
  configs: [
    {
      label: "Review Option",
      name: "jobType",
      type: FormFieldType.Dropdown,
      options: jobTypes,
      validation: {
        required: true
      }
    },
    {
      label: "Event Location",
      name: "location",
      type: FormFieldType.Text,
      placeholder: "event location",
      validation: {
        required: true,
        min: 5,
        max: 30
      }
    },
    {
      label: "Event Date",
      name: "date",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Message",
      name: "coverLetter",
      type: FormFieldType.Ckeditor,
      placeholder: "add your cover letter",
      validation: {
        required: true,
        min: 10,
        max: 300
      }
    }
  ]
};
