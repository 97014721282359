export const degreeType = [
  'Advanced Professional',
  'Associate Degree',
  'Bachelor Degree/Under Graduate',
  'First Professional Degree',
  'High School Diploma/GED',
  'Masters Degree/Graduate',
  'Doctoral Degree (Ph.D.)',
  'Post Doctoral (Study/Research)'
];
