import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { mergeMap } from 'rxjs/operators';

import { of } from 'rxjs';
import { SetError } from '@fusion/error';
import {
  ArticleActionTypes,
  ArticlesActionTypes,
  PrivateArticlesActionTypes
} from '../actions/index';

@Injectable()
export class ErrorEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  catchError$ = this.actions$.pipe(
    ofType(
      ArticleActionTypes.AddArticleFail,
      ArticleActionTypes.LoadArticleFail,
      ArticleActionTypes.LoadDraftArticleFail,
      ArticleActionTypes.PublishArticleFail,
      ArticleActionTypes.UpdateArticleFail,
      ArticlesActionTypes.LoadArticlesFail,
      PrivateArticlesActionTypes.LoadPrivateArticlesFail
    ),
    mergeMap((action: any) => {
      return of(new SetError(action.payload));
    })
  );
}
