import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const PublicationForm: IForm = {
  metadata: {
    name: constants.PUBLICATION_FORM,
    title: "Add Publication",
    submitButtonText: "Send",
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "530px"
    }
  },
  configs: [
    {
      label: "Publication Title",
      name: "title",
      type: FormFieldType.Text,
      placeholder: "certification name",
      validation: {
        required: true,
        max: 300
      }
    },
    {
      label: "Publisher",
      name: "publisher",
      type: FormFieldType.Text,
      placeholder: "certification name",
      validation: {
        required: true,
        max: 300
      }
    },
    {
      label: "Publishing Date",
      name: "publishedAt",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Publication URL",
      name: "website",
      type: FormFieldType.Url,
      placeholder: "http://elitecareer.net",
      examplePattern: "http://elitecareer.net",
      validation: {
        required: true,
        min: 2,
        max: 30,
        pattern:
          "^((https?|http|ftp|smtp)://)(www.)?[a-z0-9]+.[a-z]+(/[a-zA-Z0-9#]+/?)*$"
      }
    },
    {
      label: "Description",
      name: "description",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        max: 3000
      }
    }
  ]
};
