import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IProfileNav } from '../../models/interfaces/profile-nav.interface';

@Component({
  selector: 'fusion-common-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss']
})
export class ProfileNavComponent implements OnInit {
  @Output() notifyActiveNav: EventEmitter<IProfileNav> = new EventEmitter<
    IProfileNav
  >();
  @Input() currentRootPath: string;
  @Input() badgeCount: number;
  @Input() profileNav: IProfileNav[];

  constructor() {}

  ngOnInit() {}
}
