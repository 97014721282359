import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

// from reducer
import * as fromoAuth from "./oauth.reducer";
import * as fromUserProfile from "./user-profile.reducer";
import * as fromSignUp from "./sign-up.reducer";
import * as fromApplications from "./applications.reducer";
import * as fromUser from "./user.reducer";

export interface FusionoAuthState {
  oAuth: fromoAuth.oAuthState;
  userProfile: fromUserProfile.UserProfileState;
  signUp: fromSignUp.SignUpState;
  applications: fromApplications.ApplicationsState;
  user: fromUser.UserState;
}

export const reducers: ActionReducerMap<FusionoAuthState> = {
  oAuth: fromoAuth.reducer,
  userProfile: fromUserProfile.reducer,
  signUp: fromSignUp.reducer,
  applications: fromApplications.reducer,
  user: fromUser.reducer
};

export const getFusionoAuthState = createFeatureSelector<FusionoAuthState>(
  "fusionoAuth"
);

// export reducer state interfaces
