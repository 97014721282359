import * as constants from "../constants";
import { FormFieldType } from "../enums";
import { IForm } from "../interfaces";

export const AmenitiesForm: IForm = {
  metadata: {
    name: constants.AMENITIES_FORM,
    title: "Amenities",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px"
      // max_height: '530px'
    }
  },
  configs: [
    {
      label: "",
      type: FormFieldType.Checkbox,
      isMultiColumnCheckbox: true,
      items: [
        {
          label: "Laundry",
          name: "laundry",
          value: "laundry",
          validation: {
            required: false
          }
        },
        {
          label: "Pool",
          name: "pool",
          value: "pool",
          validation: {
            required: false
          }
        },
        {
          label: "Elevator",
          name: "elevator",
          value: "elevator",
          validation: {
            required: false
          }
        },
        {
          label: "Parking Spot",
          name: "parking_spot",
          value: "parking_spot",
          validation: {
            required: false
          }
        },
        {
          label: "Fireplace",
          name: "fireplace",
          value: "fireplace",
          validation: {
            required: false
          }
        },
        {
          label: "Alarm System",
          name: "alarm_system",
          value: "alarm_system",
          validation: {
            required: false
          }
        },
        {
          label: "Air Conditioning",
          name: "air_conditioning",
          value: "air_conditioning",
          validation: {
            required: false
          }
        },
        {
          label: "Gym",
          name: "gym",
          value: "fitness_center",
          validation: {
            required: false
          }
        },
        {
          label: "Doorman",
          name: "doorman",
          value: "doorman",
          validation: {
            required: false
          }
        },
        {
          label: "Dishwasher",
          name: "dishwasher",
          value: "dishwasher",
          validation: {
            required: false
          }
        },
        {
          label: "Deck",
          name: "deck",
          value: "deck",
          validation: {
            required: false
          }
        },
        {
          label: "Storage",
          name: "storage",
          value: "storage",
          validation: {
            required: false
          }
        },
        {
          label: "Wheelchair Accessible",
          name: "wheelchair_accessible",
          value: "wheelchair_accessible",
          validation: {
            required: false
          }
        },
        {
          label: "Hardwood Floors",
          name: "hardwood_floors",
          value: "hardwood_floors",
          validation: {
            required: false
          }
        },
        {
          label: "Closet",
          name: "closet",
          value: "closet",
          validation: {
            required: false
          }
        },
        {
          label: "Carpet",
          name: "carpet",
          value: "carpet",
          validation: {
            required: false
          }
        },
        {
          label: "Garden",
          name: "garden",
          value: "garden",
          validation: {
            required: false
          }
        },
        {
          label: "Balcony",
          name: "balcony",
          value: "balcony",
          validation: {
            required: false
          }
        },
        {
          label: "Furnished",
          name: "furnished",
          value: "furnished",
          validation: {
            required: false
          }
        },
        {
          label: "View",
          name: "view",
          value: "view",
          validation: {
            required: false
          }
        },
        {
          label: "Student Friendly",
          name: "student_friendly",
          value: "student_friendly",
          validation: {
            required: false
          }
        },
        {
          label: "High Rise",
          name: "high_rise",
          value: "high_rise",
          validation: {
            required: false
          }
        },
        {
          label: "Electric",
          name: "electric",
          value: "electric",
          validation: {
            required: false
          }
        },
        {
          label: "Gas",
          name: "gas",
          value: "Gas",
          validation: {
            required: false
          }
        },
        {
          label: "Water",
          name: "water",
          value: "Water",
          validation: {
            required: false
          }
        },
        {
          label: "Trash pickup",
          name: "trash_pickup",
          value: "Trash pickup",
          validation: {
            required: false
          }
        },
        {
          label: "TV/Cable/Satellite",
          name: "tv",
          value: "tv",
          validation: {
            required: false
          }
        },
        {
          label: "Internet/WiFi",
          name: "internet",
          value: "internet",
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
