import { Injectable } from "@angular/core";
import {
  Breakpoints,
  BreakpointObserver,
  BreakpointState
} from "@angular/cdk/layout";
import { Observable, of } from "rxjs";
import { tap, map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class DeviceService {
  /*
   * is Current viewport is mobile
   */
  isMobile$: Observable<boolean>;

  constructor(private _breakpointObserver: BreakpointObserver) {
    this.observeViewPort();
  }

  /**
   * observe view port changes and set isMobile$
   * to true if is either ipad or mobile
   *
   * Read more: https://material.angular.io/cdk/layout/overview
   * Read more: material breakpoints breadk down https://material.io/design/layout/responsive-layout-grid.html#breakpoints
   */
  observeViewPort(): void {
    this.isMobile$ = this._breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(map((result: BreakpointState) => result.matches));
  }
}
