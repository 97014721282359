import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap, map } from 'rxjs/operators';

import { 
  PlacesActionTypes,
  LoadPlacesSuccess,
  LoadPlacesFail,
  LoadPlaces
 } from '../actions/places.actions';
import { GoogleApiService } from '@fusion/service';

@Injectable()
export class PlacesEffects {
  constructor(
    private actions$: Actions,
    private googleApi: GoogleApiService
  ) {}

  @Effect()
  effect$ = this.actions$
    .pipe(
      ofType<LoadPlaces>(PlacesActionTypes.LoadPlaces),
      map(action => action.payload),
      mergeMap((payload: any) => {
        return this.googleApi.getPlaceAutocomplete(payload, 'try-1').pipe(
          switchMap(dataResult => {
            return of(new LoadPlacesSuccess(dataResult))
          }),
          catchError(error => of(new LoadPlacesFail()))
        )
      }),
      catchError(error => of(new LoadPlacesFail()))
    );
}
