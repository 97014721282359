import {
  CompaniesActionTypes,
  CompanyActionTypes,
  UnclaimedCompaniesActionTypes,
  UsersActionTypes
} from "../../store/actions";

export type FusionCompanyError =
  | CompaniesActionTypes
  | CompanyActionTypes
  | UnclaimedCompaniesActionTypes
  | UsersActionTypes;
export const FusionCompanyError = {
  ...CompaniesActionTypes,
  ...CompanyActionTypes,
  ...UnclaimedCompaniesActionTypes,
  ...UsersActionTypes
};
