import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IEducation } from "../../models/interfaces";
import {
  ModalIconType,
  IFormMetadata,
  IFormConfig,
  setFormValidationRules
} from "@fusion/form";
import { EducationForm, EducationUpdateForm } from "../../models/forms";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "fusion-career-profile-educations",
  templateUrl: "./educations.component.html",
  styleUrls: ["./educations.component.scss"]
})
export class EducationsComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Output() notifyDeleteEducation: EventEmitter<string> = new EventEmitter<
    string
  >();

  @Input() educations: IEducation[];
  @Input() autoCompleteEntities: any;

  // form setup
  educationFormMetadata: IFormMetadata = EducationForm.metadata;
  educationFormConfigs: IFormConfig[] = EducationForm.configs;
  educationFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(EducationForm.configs)
  );
  educationUpdateFormMetadata: IFormMetadata = EducationUpdateForm.metadata;
  educationUpdateFormConfigs: IFormConfig[] = EducationUpdateForm.configs;
  educationUpdateFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(EducationUpdateForm.configs)
  );

  isExpanded: boolean = false;
  ModalIconType = ModalIconType;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  notifyCardFooterClick() {
    this.isExpanded = this.isExpanded ? false : true;
  }
}
