import { Action } from "@ngrx/store";
import { IProject } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum ProjectsActionTypes {
  SetProjects = "[Projects] Set",
  LoadProjects = "[Projects] Load",
  LoadProjectsSuccess = "[Projects] Load Success",
  LoadProjectsFail = "[Projects] Load Fail",
  AddProject = "[Projects] Add",
  AddProjectSuccess = "[Projects] Add Success",
  AddProjectFail = "[Projects] Add Fail",
  UpdateProject = "[Projects] Update",
  UpdateProjectSuccess = "[Projects] Update Success",
  UpdateProjectFail = "[Projects] Update Fail",
  DeleteProject = "[Projects] Delete",
  DeleteProjectSuccess = "[Projects] Delete Success",
  DeleteProjectFail = "[Projects] Delete Fail"
}

export class SetProjects implements Action {
  readonly type = ProjectsActionTypes.SetProjects;

  constructor(public payload: IProject[]) {}
}

export class LoadProjects implements Action {
  readonly type = ProjectsActionTypes.LoadProjects;

  constructor(public payload: IProject[]) {}
}

export class LoadProjectsSuccess implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsSuccess;

  constructor(public payload: any) {}
}

export class LoadProjectsFail implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class AddProject implements Action {
  readonly type = ProjectsActionTypes.AddProject;

  constructor(public payload: IProject[]) {}
}

export class AddProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.AddProjectSuccess;

  constructor(public payload: any) {}
}

export class AddProjectFail implements Action {
  readonly type = ProjectsActionTypes.AddProjectFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class UpdateProject implements Action {
  readonly type = ProjectsActionTypes.UpdateProject;

  constructor(public payload: IProject[]) {}
}

export class UpdateProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.UpdateProjectSuccess;

  constructor(public payload: any) {}
}

export class UpdateProjectFail implements Action {
  readonly type = ProjectsActionTypes.UpdateProjectFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class DeleteProject implements Action {
  readonly type = ProjectsActionTypes.DeleteProject;

  constructor(public payload: string) {}
}

export class DeleteProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectSuccess;

  constructor(public payload: any) {}
}

export class DeleteProjectFail implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type ProjectsActions =
  | SetProjects
  | LoadProjects
  | LoadProjectsSuccess
  | LoadProjectsFail
  | AddProject
  | AddProjectSuccess
  | AddProjectFail
  | UpdateProject
  | UpdateProjectSuccess
  | UpdateProjectFail
  | DeleteProject
  | DeleteProjectSuccess
  | DeleteProjectFail;
