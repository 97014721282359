import { Component, OnInit, Input } from '@angular/core';
import { IArticle } from '../../models/interfaces';

@Component({
  selector: 'fusion-article-add',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit {
  @Input() articles: IArticle[];

  constructor() {}

  ngOnInit() {}
}
