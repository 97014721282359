import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  ModalIconType,
  IFormConfig,
  IFormMetadata,
  getValidationRulesWithPresetValue
} from "@fusion/form";

@Component({
  selector: "fusion-career-profile-card-header",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.scss"]
})
export class CardHeaderComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<string> = new EventEmitter<
    string
  >();

  @Input() formConfigs: IFormConfig[];
  @Input() formMetadata: IFormMetadata;
  @Input() formValidationRules: FormGroup;
  @Input() isEditIcon: boolean;
  @Input() isEditable: boolean;
  @Input() title: string;
  @Input() currentEntity: any;
  @Input() autoCompleteEntities: any;
  @Input() entityType: string;
  @Input() formName: string;
  @Input() isColor: boolean;
  @Input() buttonTitle: string;
  @Input() iconType: ModalIconType;

  // form setup
  updatedValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {
    this.updateValidationRules();
  }

  updateValidationRules() {
    this.updatedValidationRules = getValidationRulesWithPresetValue(
      this.formValidationRules,
      this.formConfigs,
      this.currentEntity
    );
  }
}
