import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fusion-common-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {
  @Input() description: any;
  @Input() defaultLength: number;

  isShowLess: boolean = true;
  textLength: number;

  constructor() {}

  ngOnInit() {
    this.textLength = this.defaultLength || 500;
  }

  setLength(des: any) {
    this.textLength = this.isShowLess ? des.length : this.defaultLength || 500;
    this.isShowLess = this.isShowLess ? false : true;
  }
}
