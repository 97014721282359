import {
  UnclaimedCompaniesActionTypes,
  UnclaimedCompaniesActions
} from "../actions/unclaimed-companies.actions";
import { ICompany } from "../../models/interfaces";

export interface UnclaimedCompaniesState {
  data: ICompany[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: UnclaimedCompaniesState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: UnclaimedCompaniesActions
): UnclaimedCompaniesState {
  switch (action.type) {
    case UnclaimedCompaniesActionTypes.LoadUnclaimedCompanies: {
      return {
        ...state,
        loading: true
      };
    }
    case UnclaimedCompaniesActionTypes.LoadUnclaimedCompaniesSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case UnclaimedCompaniesActionTypes.LoadUnclaimedCompaniesFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
