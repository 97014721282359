import { 
  PlacesActionTypes,
  PlacesActions
} from '../actions/places.actions';

export interface PlacesState {
  placeAutocomplete: any;
  placeDetails: any;
  loading: boolean;
  loaded: boolean;
  sessionToken: string
}

export const initialState: PlacesState = {
  placeAutocomplete: null,
  placeDetails: null,
  loading: false,
  loaded: false,
  sessionToken: null
};

export function reducer(
  state = initialState,
  action: PlacesActions
): PlacesState {
  switch (action.type) {
    case PlacesActionTypes.LoadPlaces: {
      return {
        ...state,
        loading: true
      };
    }
    case PlacesActionTypes.LoadPlacesSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        placeAutocomplete: action.payload
      };
    }
    case PlacesActionTypes.LoadPlacesFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case PlacesActionTypes.LoadPlacesDetails: {
      return {
        ...state,
        loading: true
      };
    }
    case PlacesActionTypes.LoadPlacesDetailsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        placeDetails: action.payload
      };
    }
    case PlacesActionTypes.LoadPlacesDetailsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case PlacesActionTypes.SetSessionToken: {
      return {
        ...state,
        sessionToken: action.payload
      };
    }
    case PlacesActionTypes.ResetSessionToken: {
      return {
        ...state,
        sessionToken: null
      };
    }
  }
  return state;
}
