import { RatingActionTypes, RatingActions } from '../actions/rating.actions';
import { IRating } from '../../models/interfaces';

export interface RatingState {
  data: IRating[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: RatingState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: RatingActions
): RatingState {
  switch (action.type) {
    case RatingActionTypes.LoadRating: {
      return {
        ...state,
        loading: true
      };
    }
    case RatingActionTypes.SetRating: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case RatingActionTypes.LoadRatingFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
