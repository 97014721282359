import { 
  RedirectSubscriberActionTypes,
  RedirectSubscriberActions
} from '../actions/redirect-subscriber.actions';

export interface RedirectSubscriberState {
  data: any;
  loading: boolean;
  loaded: boolean;
}

export const initialState: RedirectSubscriberState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: RedirectSubscriberActions
): RedirectSubscriberState {
  switch (action.type) {
    case RedirectSubscriberActionTypes.LoadRedirectSubscriber: {
      return {
        ...state,
        loading: true
      };
    }
    case RedirectSubscriberActionTypes.LoadRedirectSubscriberSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case RedirectSubscriberActionTypes.LoadRedirectSubscriberFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
