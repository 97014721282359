import * as constants from "../constants";
import * as dataModel from "../data";
import { FormFieldType, FormFieldGroup } from "../enums";
import { IForm } from "../interfaces";

export const AddressForm: IForm = {
  metadata: {
    name: constants.ADDRESS_FORM,
    title: "Address",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "400px"
    }
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: "Address",
          name: "address",
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 50
          }
        },
        {
          label: "Unit#",
          name: "unit",
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10
          }
        },
        {
          label: "City",
          name: "city",
          type: FormFieldType.Text,
          placeholder: "city",
          validation: {
            required: true,
            max: 30
          }
        },
        {
          label: "State",
          name: "state",
          type: FormFieldType.Text,
          validation: {
            required: false
          }
        },
        {
          label: "Zip Code",
          name: "zipCode",
          type: FormFieldType.Text,
          placeholder: "zip code",
          examplePattern: "11432",
          validation: {
            required: true,
            max: 10,
            pattern: "[0-9]+"
          }
        },
        {
          label: "Country",
          name: "country",
          type: FormFieldType.Dropdown,
          options: dataModel.countries,
          validation: {
            required: true
          }
        }
      ]
    }
  ]
};
