import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromArticle from './article.reducer';
import * as fromArticles from './articles.reducer';
import * as fromPrivateArticles from './private-articles.reducer';

export interface FusionArticleState {
  article: fromArticle.ArticleState;
  articles: fromArticles.ArticlesState;
  privateArticles: fromPrivateArticles.PrivateArticlesState;
}

export const reducers: ActionReducerMap<FusionArticleState> = {
  article: fromArticle.reducer,
  articles: fromArticles.reducer,
  privateArticles: fromPrivateArticles.reducer
};

export const getFusionArticleState = createFeatureSelector<FusionArticleState>(
  'fusionArticle'
);

// export reducer state interfaces
