<div *ngIf="formConfigs && formValidationRules">
  <div
    class="form-group"
    [ngClass]="{
      'has-error':
        !formValidationRules.controls[formConfigs.name].valid &&
        formValidationRules.controls[formConfigs.name].touched
    }"
  >
    <div
      class="editor-title"
      [ngClass]="{
        error:
          !formValidationRules.controls[formConfigs.name].valid &&
          formValidationRules.controls[formConfigs.name].touched
      }"
    >
      {{ formConfigs.label }}
      <span
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('required')
        "
        >*</span
      >
    </div>
    <div class="example-container inputGroupContainer">
      <ckeditor
        [(ngModel)]="ckeditorContent"
        class="editor"
        [config]="defaultToolConfig"
        debounce="1"
        [formControl]="formValidationRules.controls[formConfigs.name]"
      >
      </ckeditor>

      <div class="row">
        <div class="col-9">
          <mat-error
            *ngIf="
              formValidationRules.controls[formConfigs.name].hasError(
                'required'
              ) && formValidationRules.controls[formConfigs.name].touched
            "
          >
            You must include a {{ formConfigs.label }}.
          </mat-error>
          <mat-error
            *ngIf="
              formValidationRules.controls[formConfigs.name].hasError(
                'pattern'
              ) && formValidationRules.controls[formConfigs.name].touched
            "
          >
            Your {{ formConfigs.label }} is invalid format. Allowed format is
            <b>{{ formConfigs.examplePattern }}</b>
          </mat-error>
          <mat-error
            *ngIf="
              formValidationRules.controls[formConfigs.name].hasError(
                'minlength'
              ) && formValidationRules.controls[formConfigs.name].touched
            "
          >
            Your {{ formConfigs.label }} must be at least
            {{ formConfigs.validation.min }} characters long.
          </mat-error>
          <mat-error
            *ngIf="
              formValidationRules.controls[formConfigs.name].hasError(
                'maxlength'
              ) && formValidationRules.controls[formConfigs.name].touched
            "
          >
            Your {{ formConfigs.label }} cannot exceed
            {{ formConfigs.validation.max }} characters.
          </mat-error>
        </div>
        <div class="col-3 count">
          <mat-hint align="end"
            >{{
              formValidationRules.controls[formConfigs.name]?.value?.length || 0
            }}
            / {{ formConfigs.validation.max }}</mat-hint
          >
        </div>
      </div>
    </div>
  </div>
</div>
