import { NgModule } from "@angular/core";
import { FusionCommonComponent } from "./fusion-common.component";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { FooterComponent } from "./footer/footer.component";
import { FusionMaterialModule } from "@fusion/material";
import { CardHeaderComponent } from "./card-header/card-header.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { PaginatorComponent } from "./paginator/paginator.component";
import { MetadataComponent } from "./metadata/metadata.component";
import { SortComponent } from "./sort/sort.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { RouterModule } from "@angular/router";
import { DialogHeaderComponent } from "./dialog-header/dialog-header.component";
import { ExpansionPanelComponent } from "./expansion-panel/expansion-panel.component";
import { CardFooterComponent } from "./card-footer/card-footer.component";
import { SocialIconsComponent } from "./social-icons/social-icons.component";
import { ProfileNavComponent } from "./profile-nav/profile-nav.component";
import { ImageGalleryComponent } from "./image-gallery/image-gallery.component";
import { GalleryModule } from "@ks89/angular-modal-gallery";
import { EmptyStateComponent } from "./empty-state/empty-state.component";
import { DescriptionComponent } from "./description/description.component";
import { PageTitleComponent } from "./page-title/page-title.component";
import { BackComponent } from "./back/back.component";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { ImagesPreviewComponent } from "./images-preview/images-preview.component";
import { ImageCropComponent } from "./image-crop/image-crop.component";
import { AngularCropperjsModule } from "angular-cropperjs";
import { LogoUploadComponent } from "./logo-upload/logo-upload.component";

@NgModule({
  declarations: [
    FusionCommonComponent,
    SideNavComponent,
    FooterComponent,
    CardHeaderComponent,
    PaginatorComponent,
    MetadataComponent,
    SortComponent,
    CarouselComponent,
    DialogHeaderComponent,
    ExpansionPanelComponent,
    CardFooterComponent,
    SocialIconsComponent,
    ProfileNavComponent,
    ImageGalleryComponent,
    EmptyStateComponent,
    DescriptionComponent,
    PageTitleComponent,
    BackComponent,
    ImageUploadComponent,
    ImagesPreviewComponent,
    ImageCropComponent,
    LogoUploadComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FusionMaterialModule,
    AngularCropperjsModule,
    GalleryModule.forRoot()
  ],
  exports: [
    FusionCommonComponent,
    SideNavComponent,
    FooterComponent,
    CardHeaderComponent,
    PaginatorComponent,
    MetadataComponent,
    SortComponent,
    CarouselComponent,
    DialogHeaderComponent,
    ExpansionPanelComponent,
    CardFooterComponent,
    SocialIconsComponent,
    ProfileNavComponent,
    ImageGalleryComponent,
    EmptyStateComponent,
    DescriptionComponent,
    PageTitleComponent,
    BackComponent,
    ImageUploadComponent,
    ImagesPreviewComponent,
    ImageCropComponent,
    LogoUploadComponent
  ]
})
export class FusionCommonModule {}
