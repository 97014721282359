import { Action } from '@ngrx/store';
import { IError } from '@fusion/error';
import { FusionArticleError } from '../../models/enums';

export enum ArticlesActionTypes {
  LoadArticles = '[Articles] Load',
  LoadArticlesSuccess = '[Articles] Load Success',
  LoadArticlesFail = '[Articles] Load Fail'
}

export enum ArticlesErrorActionTypes {
  LoadArticlesFail = '[Articles] Load Fail'
}

export class LoadArticles implements Action {
  readonly type = ArticlesActionTypes.LoadArticles;
}

export class LoadArticlesSuccess implements Action {
  readonly type = ArticlesActionTypes.LoadArticlesSuccess;

  constructor(public payload: any) {}
}

export class LoadArticlesFail implements Action {
  readonly type = ArticlesActionTypes.LoadArticlesFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export type ArticlesActions =
  | LoadArticles
  | LoadArticlesSuccess
  | LoadArticlesFail;
