import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  CompanyActionTypes,
  LoadCompanySuccess,
  LoadCompanyFail,
  LoadCompany,
  AddCompany,
  AddCompanySuccess,
  AddCompanyFail,
  ClaimCompany,
  ClaimCompanySuccess,
  UpdateCompany,
  UpdateCompanySuccess,
  UpdateCompanyFail,
  ClaimCompanyFail,
  UploadLogo,
  UploadLogoSuccess,
  UploadLogoFail,
  UploadBanner,
  UploadBannerFail,
} from '../actions/company.actions';
import { EmployerService, MappingService, mappingType } from '@fusion/service';
import { Store } from '@ngrx/store';
import { FusionCompanyState } from '../reducers/index';
import { getRouterParams, getRouterUrlRoot, Navigate } from '@fusion/router';
import { Params } from '@angular/router';
import { LoadUserProfile, getUserType } from '@fusion/oauth';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionCompanyError } from '../../models/enums';
import { ICompany } from '../../models/interfaces';
import { UserType, IFilePayload, IFileUpload } from '@fusion/common';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionCompanyState>,
    private employerService: EmployerService,
    private mappingService: MappingService
  ) {}

  @Effect()
  loadCompany$ = this.actions$.pipe(
    ofType<LoadCompany>(CompanyActionTypes.LoadCompany),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getUserType)
    ),
    mergeMap(([payload, params, userType]: [any, Params, UserType]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.LoadCompanyFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const companyId =
        params.companyId ||
        (userType === UserType.Company ? params.subscriberId : null);
      if (!companyId) {
        return of();
      }
      return this.employerService.getCompany(payload || companyId).pipe(
        map((result) => {
          const mappedData = this.mappingService.getMappedData<ICompany>(
            result,
            mappingType.camelize
          );
          return new LoadCompanySuccess(mappedData);
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue loading company. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadCompany(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new LoadCompanyFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  addCompany$ = this.actions$.pipe(
    ofType<AddCompany>(CompanyActionTypes.AddCompany),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getRouterUrlRoot)
    ),
    switchMap(([payload, params, rootUrl]: [any, Params, string]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.AddCompanyFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const userId = params.userId;
      if (typeof payload.name === 'string') {
        let companyPayload = this.mappingService.getMappedData<any>(
          payload,
          mappingType.underscore
        );
        companyPayload = {
          ...companyPayload,
          user_id: userId,
        };
        return this.employerService.postCompany(companyPayload).pipe(
          switchMap((result) => {
            if (companyPayload.category === 'Mosque') {
              return [
                new AddCompanySuccess(result),
                new LoadUserProfile(),
                new Navigate(rootUrl),
              ];
            }
            return [new AddCompanySuccess(result), new LoadUserProfile()];
          }),
          catchError((error) => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  'Sorry, we are having some issue adding company. Please try again later.',
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new AddCompany(payload)],
                    title: 'Retry',
                  },
                },
              },
            };
            return of(new AddCompanyFail(errorPayload));
          })
        );
      }
      if (typeof payload.name === 'object') {
        const companyId = payload.name.id;
        return of(new ClaimCompany(companyId));
      }
    })
  );

  @Effect()
  updateCompany$ = this.actions$.pipe(
    ofType<UpdateCompany>(CompanyActionTypes.UpdateCompany),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    switchMap(([payload, params]: [any, Params]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.UpdateCompanyFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const companyId = params.companyId;
      const mappedData = this.mappingService.getMappedData(
        payload,
        mappingType.underscore
      );
      return this.employerService.updateCompany(companyId, mappedData).pipe(
        switchMap((result) => {
          return [
            new UpdateCompanySuccess(result),
            new LoadCompany(),
            new LoadUserProfile(),
          ];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue updating company. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UpdateCompany(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new UpdateCompanyFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  uploadLogo$ = this.actions$.pipe(
    ofType<UploadLogo>(CompanyActionTypes.UploadLogo),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    switchMap(([payload, params]: [IFileUpload, Params]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.UploadLogoFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const companyId = params.companyId;
      return this.employerService.uploadLogo(companyId, payload).pipe(
        switchMap((dataResult) => {
          return [
            new UploadLogoSuccess(dataResult),
            new LoadCompany(),
            new LoadUserProfile(),
          ];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue uploading log. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UploadLogo(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new UploadLogoFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  uploadBanner$ = this.actions$.pipe(
    ofType<UploadBanner>(CompanyActionTypes.UploadBanner),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    switchMap(([payload, params]: [IFilePayload, Params]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.UploadBannerFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const companyId = params.companyId;
      return this.employerService.uploadLogo(companyId, payload).pipe(
        switchMap((dataResult) => {
          return [new UploadLogoSuccess(dataResult), new LoadCompany()];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue uploading banner. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UploadBanner(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new UploadBannerFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  claimCompany$ = this.actions$.pipe(
    ofType<ClaimCompany>(CompanyActionTypes.ClaimCompany),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    switchMap(([payload, params]: [any, Params]) => {
      let errorPayload: IError<FusionCompanyError> = {
        code: FusionCompanyError.ClaimCompanyFail,
        source: ErrorSource.Validation,
        data: null,
      };
      const employerId = params.userId;
      return this.employerService.postCompanyClaim(payload, employerId).pipe(
        switchMap((dataResult) => {
          return [new ClaimCompanySuccess(dataResult), new LoadUserProfile()];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                'Sorry, we are having some issue claiming your company. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new ClaimCompany(payload)],
                  title: 'Retry',
                },
              },
            },
          };
          return of(new ClaimCompanyFail(errorPayload));
        })
      );
    })
  );
}
