import { Injectable } from "@angular/core";
import * as strings from "@angular-devkit/core/src/utils/strings";

export enum mappingType {
  camelize,
  underscore,
}

@Injectable({
  providedIn: "root",
})
export class MappingService {
  constructor() {}

  getMappedData<T>(inputData: any, expectedType: mappingType): T {
    const isExtensible = Object.isExtensible(inputData);
    if (!isExtensible && this.isObject(inputData)) {
      const extensibleInput = Object.assign({}, inputData);
      return this.getConvertedData(extensibleInput, expectedType);
    }

    return this.getConvertedData(inputData, expectedType);
  }

  private getConvertedData<T>(inputData: any, expectedType: mappingType): T {
    if (inputData && (this.isObject(inputData) || Array.isArray(inputData))) {
      Object.entries(inputData).forEach(([key, value]) => {
        const newKey = this.getConvertedKey(key, expectedType);
        inputData[newKey] = value;

        if (newKey !== key) {
          delete inputData[key];
        }

        if (value && (this.isObject(value) || Array.isArray(value))) {
          const isExtensible = Object.isExtensible(value);
          if (!isExtensible) {
            const extensibleInput = Array.isArray(value)
              ? Object.assign([], value)
              : Object.assign({}, value);
            inputData[newKey] = extensibleInput;
            this.getConvertedData(inputData[newKey], expectedType);
          } else {
            this.getConvertedData(value, expectedType);
          }
        }
      });
    }

    return inputData;
  }

  private getConvertedKey(str: string, expectedType: mappingType) {
    switch (expectedType) {
      case mappingType.camelize: {
        return strings.camelize(str);
      }
      case mappingType.underscore: {
        return strings.underscore(str);
      }
    }
  }

  private isObject(value: any) {
    return value && typeof value === "object" && value.constructor === Object;
  }
}
