import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";
import { FormFieldType, FormFieldGroup } from "../../models/enums";

@Component({
  selector: "fusion-form-handler",
  templateUrl: "./form-handler.component.html",
  styleUrls: ["./form-handler.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormHandlerComponent implements OnInit {
  @Input() formConfigs: IFormConfig[];
  @Input() formValidationRules: FormGroup;
  @Input() autoCompleteEntities: any;
  @Input() entityType: string;
  @Output() notifyFormValidationUpdate = new EventEmitter<boolean>();
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();

  FormFieldType = FormFieldType;
  FormFieldGroup = FormFieldGroup;

  constructor() {}

  ngOnInit() {}
}
