import { Action } from "@ngrx/store";
import { IJobseeker } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum JobseekerProfileActionTypes {
  LoadJobseekerProfile = "[Jobseeker Profile] Load",
  LoadJobseekerProfileSuccess = "[Jobseeker Profile] Load Success",
  LoadJobseekerProfileFail = "[Jobseeker Profile] Load Fail",
  AddJobseekerProfile = "[Jobseeker Profile] Add",
  AddJobseekerProfileSuccess = "[Jobseeker Profile] Add Success",
  AddJobseekerProfileFail = "[Jobseeker Profile] Add Fail",
  UpdateJobseekerProfile = "[Jobseeker Profile] Update",
  UpdateJobseekerProfileFail = "[Jobseeker Profile] Update Fail"
}

export class LoadJobseekerProfile implements Action {
  readonly type = JobseekerProfileActionTypes.LoadJobseekerProfile;

  constructor(public payload?: string) {}
}

export class LoadJobseekerProfileSuccess implements Action {
  readonly type = JobseekerProfileActionTypes.LoadJobseekerProfileSuccess;

  constructor(public payload: IJobseeker) {}
}

export class LoadJobseekerProfileFail implements Action {
  readonly type = JobseekerProfileActionTypes.LoadJobseekerProfileFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class AddJobseekerProfile implements Action {
  readonly type = JobseekerProfileActionTypes.AddJobseekerProfile;
}

export class AddJobseekerProfileSuccess implements Action {
  readonly type = JobseekerProfileActionTypes.AddJobseekerProfileSuccess;

  constructor(public payload: IJobseeker) {}
}

export class AddJobseekerProfileFail implements Action {
  readonly type = JobseekerProfileActionTypes.AddJobseekerProfileFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class UpdateJobseekerProfile implements Action {
  readonly type = JobseekerProfileActionTypes.UpdateJobseekerProfile;

  constructor(public payload?: IJobseeker) {}
}

export class UpdateJobseekerProfileFail implements Action {
  readonly type = JobseekerProfileActionTypes.UpdateJobseekerProfileFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type JobseekerProfileActions =
  | LoadJobseekerProfile
  | LoadJobseekerProfileSuccess
  | LoadJobseekerProfileFail
  | AddJobseekerProfile
  | AddJobseekerProfileSuccess
  | AddJobseekerProfileFail
  | UpdateJobseekerProfile
  | UpdateJobseekerProfileFail;
