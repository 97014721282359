import { ErrorActionTypes, ErrorActions } from '../actions/error.actions';
import { IError } from '../../models/interfaces';

export interface ErrorState {
  data: IError<any>;
}

export const initialState: ErrorState = {
  data: null
};

export function reducer(
  state = initialState,
  action: ErrorActions
): ErrorState {
  switch (action.type) {
    case ErrorActionTypes.SetError: {
      return {
        ...state,
        data: action.payload
      };
    }
    case ErrorActionTypes.ResetError: {
      return initialState;
    }
  }
  return state;
}
