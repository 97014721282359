import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.job.host;
    this.api = this.configService.getConfig().services.job.api;
  }

  getContentItems(limit: number, offset: number, keywords): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/jobs?limit=${limit}&offset=${offset}&keywords=${keywords}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  getJobs(payload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/search`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, payload, options);
  }

  getContentItemById(jobId): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobs/` + jobId;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  getJobApplications(payload: any): Observable<any> {
    const fullUrl = `${this.baseUrl}${this.api}/applications?id=${
      payload.id
    }&type=${payload.type}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  getApplicationById(id: string): Observable<any> {
    const fullUrl = `${this.baseUrl}${this.api}/applications/${id}`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.get(fullUrl, options);
  }

  postJob(job: any) {
    const fullUrl = `${this.baseUrl}${this.api}/jobs`;
    const options = this.requestOptionsService.generateOptions();

    return this._http.post(fullUrl, job, options);
  }
}
