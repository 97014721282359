import {
  Directive,
  ViewContainerRef,
  Input,
  ComponentFactoryResolver,
  OnInit
} from "@angular/core";
import { ErrorItem } from "../components/error-item/error-item";
import {
  IErrorComponentConfig,
  ErrorComponent
} from "../components/error/error.component";
import { IErrorHandlingConfig } from "../models/interfaces";
import { ErrorHandlingType } from "../models/enums";
import { ErrorDialogComponent } from "../components/error-dialog/error-dialog.component";

@Directive({
  selector: "[fusionErrorHandler]"
})
export class ErrorHandlerDirective implements OnInit {
  @Input("fusionErrorHandler") errorHandlingConfig: IErrorHandlingConfig;
  @Input() errorHandlingType: ErrorHandlingType;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    if (this.errorHandlingConfig) {
      this.loadComponent();
    }
  }

  loadComponent() {
    const errorComponent = this.getErrorComponent(this.errorHandlingConfig);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      errorComponent.component
    );

    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(
      componentFactory
    );
    (<IErrorComponentConfig>(
      componentRef.instance
    )).errorHandlingConfig = this.errorHandlingConfig;
  }

  getErrorComponent(errorHandlingConfig: IErrorHandlingConfig) {
    if (this.errorHandlingType) {
      errorHandlingConfig = {
        ...errorHandlingConfig,
        type: this.errorHandlingType
      };
    }
    switch (errorHandlingConfig.type) {
      case ErrorHandlingType.Template: {
        return new ErrorItem(ErrorComponent, errorHandlingConfig);
      }
      case ErrorHandlingType.Dialog: {
        return new ErrorItem(ErrorDialogComponent, errorHandlingConfig);
      }
      default: {
        return new ErrorItem(ErrorComponent, errorHandlingConfig);
      }
    }
  }
}
