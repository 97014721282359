import { Component, OnInit, Input } from '@angular/core';
import { IArticle } from '../../models/interfaces';

@Component({
  selector: 'fusion-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.scss']
})
export class ArticleViewComponent implements OnInit {
  @Input() article: IArticle;
  @Input() isEditable: boolean;

  constructor() {}

  ngOnInit() {}
}
