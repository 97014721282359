import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import {
  RouterStateSerializer,
  RouterReducerState,
  routerReducer
} from '@ngrx/router-store';

import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Params
} from '@angular/router';
import { Injectable } from "@angular/core";

// from reducer

export interface RouterStateUrl {
  url: string;
  urlRoot: string;
  urlBase: string;
  queryParams: Params;
  parentParams: Params;
  params: Params;
}

export interface FusionRouterState {
  routerReducer: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<FusionRouterState> = {
  routerReducer: routerReducer
};

export const getFusionRouterState = createFeatureSelector<FusionRouterState>(
  'fusionRouter'
);

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    // destructuring
    const { url } = routerState;
    const urlRoot = removeLastDirectoryPartOf(url);
    const urlBase = removeLastDirectoryPartOf(urlRoot);
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    const parentParams = state.firstChild ? state.firstChild.params : null;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;
    return { url, urlRoot, urlBase, queryParams, parentParams, params };
  }
}

function removeLastDirectoryPartOf(url) {
  const the_arr = url.split('/');
  the_arr.pop();
  return the_arr.join('/');
}

// export reducer state interfaces
