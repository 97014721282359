import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers } from "./store/reducers/index";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { effects } from "./store/effects/index";
import { RouterModule } from "@angular/router";
import { TopNavComponent } from "./containers/top-nav/top-nav.component";
import { FusionMaterialModule } from "@fusion/material";
import { FusionCommonModule } from "@fusion/common";
import { FusionFormModule } from "@fusion/form";
import { FusionPipeModule } from "@fusion/pipe";
import { guards } from "./store/guards/index";
import { SideNavComponent } from "./containers/side-nav/side-nav.component";
import { BodyComponent } from "./containers/body/body.component";
import { SignInComponent } from "./containers/sign-in/sign-in.component";
import { SignUpComponent } from "./containers/sign-up/sign-up.component";

@NgModule({
  declarations: [
    TopNavComponent,
    SideNavComponent,
    BodyComponent,
    SignInComponent,
    SignUpComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FusionMaterialModule,
    FusionCommonModule,
    FusionFormModule,
    FusionPipeModule,
    StoreModule.forFeature("fusionoAuth", reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [
    TopNavComponent,
    SideNavComponent,
    BodyComponent,
    SignInComponent,
    SignUpComponent,
  ],
  providers: [guards],
})
export class FusionoAuthModule {}
