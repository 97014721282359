import {
  EducationsActionTypes,
  ExperiencesActionTypes,
  JobseekerProfileActionTypes,
  ProjectsActionTypes,
  PublicationsActionTypes,
  RatingActionTypes,
  SkillsActionTypes
} from "../../store/actions/index";

export type FusionCareerProfileError =
  | EducationsActionTypes
  | ExperiencesActionTypes
  | JobseekerProfileActionTypes
  | ProjectsActionTypes
  | PublicationsActionTypes
  | RatingActionTypes
  | SkillsActionTypes;
export const FusionCareerProfileError = {
  ...EducationsActionTypes,
  ...ExperiencesActionTypes,
  ...JobseekerProfileActionTypes,
  ...ProjectsActionTypes,
  ...PublicationsActionTypes,
  ...RatingActionTypes,
  ...SkillsActionTypes
};
