import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private windowRef: WindowRefService) {}

  getConfig(): any {
    return this.windowRef.nativeWindow.fusionConfig.config;
  }
}
