import {
  CurrentAppActionTypes,
  CurrentSubscriptionActionTypes,
  PermissionsActionTypes,
  ProfileNavigationActionTypes,
  RedirectSubscriberActionTypes,
  SubscribeActionTypes,
  SubscriptionsActionTypes
} from '../../store/actions';

export type FusionSubscriptionError =
  | CurrentAppActionTypes
  | CurrentSubscriptionActionTypes
  | PermissionsActionTypes
  | ProfileNavigationActionTypes
  | RedirectSubscriberActionTypes
  | SubscribeActionTypes
  | SubscriptionsActionTypes;
export const FusionSubscriptionError = {
  ...CurrentAppActionTypes,
  ...CurrentSubscriptionActionTypes,
  ...PermissionsActionTypes,
  ...ProfileNavigationActionTypes,
  ...RedirectSubscriberActionTypes,
  ...SubscribeActionTypes,
  ...SubscriptionsActionTypes
};
