import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { ImageType } from "../../models/enums";
import { IImage } from "../../models/interfaces";

@Component({
  selector: "fusion-common-images-preview",
  templateUrl: "./images-preview.component.html",
  styleUrls: ["./images-preview.component.scss"]
})
export class ImagesPreviewComponent implements OnInit {
  @Output() notifyBannerSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyImageDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() images: IImage[];
  @Input() bannerImage: IImage;
  @Input() imageType: ImageType;

  constructor() {}

  ngOnInit() {}
}
