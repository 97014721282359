import {
  ExperiencesActionTypes,
  ExperiencesActions
} from '../actions/experiences.actions';
import { IExperience } from '../../models/interfaces';

export interface ExperiencesState {
  data: IExperience[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: ExperiencesState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: ExperiencesActions
): ExperiencesState {
  switch (action.type) {
    case ExperiencesActionTypes.LoadExperiences: {
      return {
        ...state,
        loading: true
      };
    }
    case ExperiencesActionTypes.SetExperiences: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case ExperiencesActionTypes.LoadExperiencesFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
