import { createSelector } from '@ngrx/store';

import { getFusionSubscriptionState } from '../reducers';

export const getRedirectSubscriberState = createSelector(
  getFusionSubscriptionState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.redirectSubscriber;
  }
);
