import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { IProfileNav } from "@fusion/common";
import { ArticleState } from "../../store/index";
import { getRouterUrl } from "@fusion/router";
import {
  getProfileNavigation,
  SetActiveProfileNavigation
} from "@fusion/subscription";
import {
  LoadArticles,
  IArticle,
  getArticles,
  FusionArticleError
} from "@fusion/article";
import { IErrorHandlingConfig, getErrorHandlingConfig } from "@fusion/error";

@Component({
  selector: "articles",
  templateUrl: "./articles.component.html",
  styleUrls: ["./articles.component.scss"]
})
export class ArticlesComponent implements OnInit {
  profileNav$: Observable<IProfileNav[]>;
  currentRootPath$: Observable<string>;
  articles$: Observable<IArticle[]>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;

  constructor(private store: Store<ArticleState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadArticles());

    this.currentRootPath$ = this.store.select(getRouterUrl);
    this.profileNav$ = this.store.select(getProfileNavigation);
    this.articles$ = this.store.select(getArticles);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.LoadArticlesFail)
    );
  }
}
