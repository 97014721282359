<button
  mat-mini-fab
  [matMenuTriggerFor]="menu"
  fxShow="true"
  fxHide.gt-sm
  color="primary"
>
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <mat-button-toggle-group
    class="profile-nav"
    #group="matButtonToggleGroup"
    vertical="true"
  >
    <mat-button-toggle
      *ngFor="let nav of profileNav"
      value="left"
      checked="{{ nav.active }}"
      routerLink="{{ currentRootPath }}/{{ nav.path }}"
      (click)="notifyActiveNav.emit(nav)"
    >
      <mat-icon>{{ nav.icon }}</mat-icon>
      <span
        *ngIf="nav.isBadged"
        matBadge="{{ badgeCount }}"
        matBadgeOverlap="false"
        matBadgePosition="above after"
        matBadgeColor="primary"
        matBadgeSize="medium"
        class="l-padding-2"
        >{{ nav.title }}</span
      >
      <span *ngIf="!nav.isBadged" class="l-padding-2">{{ nav.title }}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</mat-menu>

<div class="menu" fxShow="true" fxHide.lt-md>
  <mat-button-toggle-group
    class="profile-nav"
    #group="matButtonToggleGroup"
    vertical="true"
  >
    <mat-button-toggle
      *ngFor="let nav of profileNav"
      value="left"
      checked="{{ nav.active }}"
      routerLink="{{ currentRootPath }}/{{ nav.path }}"
      (click)="notifyActiveNav.emit(nav)"
    >
      <mat-icon>{{ nav.icon }}</mat-icon>
      <span
        *ngIf="nav.isBadged"
        matBadge="{{ badgeCount }}"
        matBadgeOverlap="false"
        matBadgePosition="above after"
        matBadgeColor="primary"
        matBadgeSize="medium"
        class="l-padding-2"
        >{{ nav.title }}</span
      >
      <span *ngIf="!nav.isBadged" class="l-padding-2">{{ nav.title }}</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
