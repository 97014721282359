import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICompany } from '@fusion/common';
import { IFormMetadata } from '@fusion/form';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fusion-company-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<any> = new EventEmitter<any>();
  @Input() formValidationRules: FormGroup;
  @Input() formConfigs: Object;
  @Input() formMetadata: IFormMetadata;
  @Input() autoCompleteEntities: ICompany[];
  @Input() selectedCompany: ICompany;
  @Input() isLoading: boolean;
  @Input() isNavigateMode: boolean;
  @Input() currentRootPath: string;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {}

  navigateTo(baseRoute: string, path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
