import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const CertificationForm: IForm = {
  metadata: {
    name: constants.CERTIFICATION_FORM,
    title: "Add Certification",
    submitButtonText: "Submit",
    style: {
      topMargin: "0px"
    }
  },
  configs: [
    {
      label: "Certification Name",
      name: "certificationName",
      type: FormFieldType.Text,
      placeholder: "certification name",
      validation: {
        required: true,
        min: 2,
        max: 30
      }
    },
    {
      label: "Certification Authority",
      name: "certificationAuthority",
      type: FormFieldType.Text,
      placeholder: "certification authority",
      validation: {
        required: true,
        min: 2,
        max: 30
      }
    },
    {
      label: "Certification Date",
      name: "certificationDate",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    {
      label: "Certification URL",
      name: "website",
      type: FormFieldType.Url,
      placeholder: "http://elitecareer.net",
      examplePattern: "http://elitecareer.net",
      validation: {
        required: true,
        min: 2,
        max: 30,
        pattern:
          "^((https?|http|ftp|smtp)://)(www.)?[a-z0-9]+.[a-z]+(/[a-zA-Z0-9#]+/?)*$"
      }
    }
  ]
};
