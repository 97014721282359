import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ISideNav } from '../../models/interfaces';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../../store/reducers';
import {
  getoAuthCompanies,
  getCompanyAppBaseUrl,
  getoAuthUserProfilePath,
  getoAuthUser,
  getoAuthSessionToken,
} from '../../store/selectors';
import { Observable, Subscription } from 'rxjs';
import { IUser, ICompany } from '@fusion/common';
import { SessionEnd, oAuthStart, SignUpStart } from '../../store/actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ModalIconType,
  IFormMetadata,
  IFormConfig,
  setFormValidationRules,
} from '@fusion/form';
import { SignInForm, SignUpForm } from '../../models/forms';
import { NavService } from '../../services/nav/nav.service';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';

@Component({
  selector: 'fusion-oauth-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SideNavComponent implements OnInit {
  @Input() mode: string;
  @Input() isOpen: boolean;
  @Input() isHomeApp: boolean;
  @Input() sideNav: ISideNav[];
  @Input() currentRootPath: string;

  token$: Observable<string>;
  oAuthUser$: Observable<IUser>;
  oAuthCompanies$: Observable<ICompany[]>;
  companyAppBaseUrl$: Observable<string>;
  authUserProfilePath$: Observable<string>;
  breakpoint$: Observable<BreakpointState>;

  breakpointSubscription: Subscription;
  ModalIconType = ModalIconType;

  // form templates
  signInFormMetadata: IFormMetadata = SignInForm.metadata;
  signInFormConfigs: IFormConfig[] = SignInForm.configs;
  signInFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(SignInForm.configs)
  );

  signUpFormMetadata: IFormMetadata = SignUpForm.metadata;
  signUpFormConfigs: IFormConfig[] = SignUpForm.configs;
  signUpFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(SignUpForm.configs)
  );

  @ViewChild('appDrawer', { static: true }) appDrawer: ElementRef;
  constructor(
    private store: Store<FusionoAuthState>,
    private fb: FormBuilder,
    private navService: NavService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.breakpoint$ = this.breakpointObserver.observe(['(max-width: 960px)']);
    this.navService.appDrawer = this.appDrawer;
    this.token$ = this.store.select(getoAuthSessionToken);
    this.oAuthUser$ = this.store.select(getoAuthUser);
    this.oAuthCompanies$ = this.store.select(getoAuthCompanies);
    this.companyAppBaseUrl$ = this.store.select(getCompanyAppBaseUrl);
    this.authUserProfilePath$ = this.store.select(getoAuthUserProfilePath);
  }

  notifySignInFormSubmit(formData) {
    this.store.dispatch(new oAuthStart(formData.data));
  }

  notifySignUpFormSubmit(formData) {
    this.store.dispatch(new SignUpStart(formData.data));
  }

  navigateToCompany(baseUrl: string, companyId: string) {
    window.location.href = `${baseUrl}/${companyId}`;
  }

  navigateToUserProfile(baseUrl: string) {
    window.location.href = `${baseUrl}`;
  }

  toggleNav(isResponsive: boolean) {
    if (isResponsive) {
      this.navService.toggleNav();
      this.navService.toggleNavResponsive();
    }
  }

  signOut() {
    this.store.dispatch(new SessionEnd());
  }
}
