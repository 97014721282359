import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { IApplication } from "../../models/interfaces";

export enum RedirectSubscriberActionTypes {
  LoadRedirectSubscriber = "[Redirect Subscriber] Load",
  LoadRedirectSubscriberSuccess = "[Redirect Subscriber] Load Success",
  LoadRedirectSubscriberFail = "[Redirect Subscriber] Load Fail"
}

export class LoadRedirectSubscriber implements Action {
  readonly type = RedirectSubscriberActionTypes.LoadRedirectSubscriber;

  constructor(public payload: IApplication) {}
}

export class LoadRedirectSubscriberSuccess implements Action {
  readonly type = RedirectSubscriberActionTypes.LoadRedirectSubscriberSuccess;

  constructor(public payload: any) {}
}

export class LoadRedirectSubscriberFail implements Action {
  readonly type = RedirectSubscriberActionTypes.LoadRedirectSubscriberFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export type RedirectSubscriberActions =
  | LoadRedirectSubscriber
  | LoadRedirectSubscriberSuccess
  | LoadRedirectSubscriberFail;
