import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionoAuthError } from "../../models/enums";

export enum SignUpActionTypes {
  SignUpStart = "[Sign Up] Start",
  SignUpSuccess = "[Sign Up] Success",
  SignUpFail = "[Sign Up] Fail"
}

export class SignUpStart implements Action {
  readonly type = SignUpActionTypes.SignUpStart;

  constructor(public payload: any) {}
}

export class SignUpSuccess implements Action {
  readonly type = SignUpActionTypes.SignUpSuccess;

  constructor(public payload: any) {}
}

export class SignUpFail implements Action {
  readonly type = SignUpActionTypes.SignUpFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export type SignUpActions = SignUpStart | SignUpSuccess | SignUpFail;
