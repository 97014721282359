import {
  JobseekerProfileActionTypes,
  JobseekerProfileActions
} from '../actions/jobseeker-profile.actions';
import { IJobseeker } from '../../models/interfaces';

export interface JobseekerProfileState {
  data: IJobseeker;
  loading: boolean;
  loaded: boolean;
}

export const initialState: JobseekerProfileState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: JobseekerProfileActions
): JobseekerProfileState {
  switch (action.type) {
    case JobseekerProfileActionTypes.LoadJobseekerProfile: {
      return {
        ...state,
        loading: true
      };
    }
    case JobseekerProfileActionTypes.LoadJobseekerProfileSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case JobseekerProfileActionTypes.LoadJobseekerProfileFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
