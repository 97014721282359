import * as constants from "../constants";
import { roles } from "../data";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const PermissionForm: IForm = {
  metadata: {
    name: constants.PERMISSION_FORM,
    title: "Add Permission",
    submitButtonText: "Send",
    triggerButtonIcon: "add",
    style: {
      topMargin: "0px",
      maxWidth: "500px",
      maxHeight: "300px"
    }
  },
  configs: [
    {
      label: "Email/Username",
      name: "user",
      type: FormFieldType.Autocomplete,
      // optionType: 'user',
      placeholder: "Search by Email/Username",
      autoFillItems: [],
      validation: {
        required: true
      }
    },
    {
      label: "Role",
      name: "role",
      type: FormFieldType.Dropdown,
      isDynamicDropdownOptions: true,
      options: roles,
      validation: {
        required: true
      }
    }
  ]
};
