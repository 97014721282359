import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromError from './error.reducer';

export interface FusionErrorState {
  error: fromError.ErrorState;
}

export const reducers: ActionReducerMap<FusionErrorState> = {
  error: fromError.reducer
};

export const getFusionErrorState = createFeatureSelector<FusionErrorState>(
  'fusionError'
);

// export reducer state interfaces
export const fusionErrorLibraryInitialState: FusionErrorState = {
  error: fromError.initialState
};
