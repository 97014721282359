import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Maps, GoogleApiService } from "../../services";

const colors = [
  "red",
  "blue",
  "green",
  "yellow",
  "brown",
  "BurlyWood",
  "Cyan",
  "DarkGreen",
  "DarkOrchid",
  "DarkOliveGreen",
  "Fuchsia",
  "GoldenRod",
  "Indigo",
  "LightCoral",
  "MediumSlateBlue",
];
let colorIndex = 0;

@Component({
  selector: "fusion-form-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  @ViewChild("map", { static: true }) mapElementRef: ElementRef;
  private map: google.maps.Map;
  constructor(public apiService: GoogleApiService) {}

  ngOnInit(): void {
    this.apiService.api.then((maps) => {
      debugger
      this.initMap(maps);
    });
  }

  initMap(maps: Maps) {
    this.map = new maps.Map(this.mapElementRef.nativeElement, {
      zoom: 16,
    });

    let location = {
      lat: 40.71161009999999,
      lng: -73.79713199999999,
    };

    this.map.setCenter(location);

    const color = colors[colorIndex++ % colors.length];
    const pin = this.pin(color);

    const marker = new google.maps.Marker({
      position: location,
      animation: google.maps.Animation.DROP,
      map: this.map,
      label: "My Location Name",
      icon: pin
    });
  }

  pin(color) {
    return {
      path:
        "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0",
      fillColor: '#ff9800',
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 1,
      scale: 1,
    };
  }
}
