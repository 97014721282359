<ng-container *ngFor="let config of formConfigs">
  <fusion-form-dropdown
    *ngIf="config.name == 'applyType'"
    [formConfigs]="config"
    [formValidationRules]="formValidationRules"
  ></fusion-form-dropdown>

  <ng-container [ngSwitch]="getApplyTypeControlValue('applyType', config)">
    <ng-container *ngSwitchCase="'email'">
      <fusion-form-text-field
        *ngIf="config.name == 'email'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchCase="'redirect'">
      <fusion-form-text-field
        *ngIf="config.name == 'url'"
        [formConfigs]="config"
        [formValidationRules]="formValidationRules"
      ></fusion-form-text-field>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <!-- TODO may be fetch error component -->
      <div class="apply-type__empty"></div>
    </ng-container>
  </ng-container>
</ng-container>
