import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'fusion-common-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {
  @Output()
  notifyDialogClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() title: string;

  constructor() {}

  ngOnInit() {}
}
