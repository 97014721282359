import { IProfileNav } from '../interfaces/profile-nav.interface';

export const userNav: IProfileNav[] = [
  {
    title: 'My Subscriptions',
    path: '',
    icon: 'library_books',
    active: true
  },
  {
    title: 'Subscribe',
    path: 'subscribe',
    icon: 'library_add',
    active: false
  },
  {
    title: 'Companies',
    path: 'companies',
    icon: 'business',
    active: false
  },
  {
    title: 'Notifications',
    path: 'notifications',
    icon: 'notifications_none',
    isBadged: true,
    active: false
  },
  {
    title: 'Settings',
    path: 'settings',
    icon: 'settings',
    active: false
  }
];
