import { Action } from "@ngrx/store";
import { ICurrentAppMetadata, IApplication } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";

export enum CurrentAppActionTypes {
  LoadCurrentApp = "[Current App] Load",
  LoadCurrentAppSuccess = "[Current App] Load Success",
  LoadCurrentAppFail = "[Current App] Load Fail"
}

export class LoadCurrentApp implements Action {
  readonly type = CurrentAppActionTypes.LoadCurrentApp;

  constructor(public payload: ICurrentAppMetadata) {}
}

export class LoadCurrentAppSuccess implements Action {
  readonly type = CurrentAppActionTypes.LoadCurrentAppSuccess;

  constructor(public payload: IApplication) {}
}

export class LoadCurrentAppFail implements Action {
  readonly type = CurrentAppActionTypes.LoadCurrentAppFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export type CurrentAppActions =
  | LoadCurrentApp
  | LoadCurrentAppSuccess
  | LoadCurrentAppFail;
