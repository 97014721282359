import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const PropertyDateForm: IForm = {
  metadata: {
    name: constants.PROPERTY_DATE_FORM,
    title: "Property Date",
    submitButtonText: "Next",
    stepper: {
      isStepper: true,
      isLastStep: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "400px",
      maxHeight: "250px"
    }
  },
  configs: [
    {
      label: "Available from",
      name: "availabilityDate",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    }
  ]
};
