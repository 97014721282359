import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const SkillForm: IForm = {
  metadata: {
    name: constants.SKILL_FORM,
    title: "Add Skill",
    submitButtonText: "Send",
    style: {
      topMargin: "0px",
      maxWidth: "500px",
      maxHeight: "350px"
    }
  },
  configs: [
    {
      label: "Skill",
      name: "skill",
      type: FormFieldType.Text,
      placeholder: "skill",
      validation: {
        required: true,
        max: 30
      }
    },
    {
      label: "Start Date",
      name: "start",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    }
  ]
};
