import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ISearchForm } from '@fusion/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'fusion-form-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() formConfigs: any;
  @Input() formValidationRules: FormGroup;
  @Output() notifySearch: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  notifySearchCategory: EventEmitter<string> = new EventEmitter<string>();
  @Input() keywords: string;
  @Input() selectedJobCategory: string;
  @Input() searchForm: ISearchForm;

  constructor() {}

  ngOnInit() {}
}
