import { Action } from '@ngrx/store';
import { IError } from '../../models/interfaces';

export enum ErrorActionTypes {
  SetError = '[Error] Set',
  ResetError = '[Error] Reset'
}

export class SetError implements Action {
  readonly type = ErrorActionTypes.SetError;

  constructor(public payload: IError<any>) {}
}

export class ResetError implements Action {
  readonly type = ErrorActionTypes.ResetError;
}

export type ErrorActions = SetError | ResetError;
