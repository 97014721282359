import {
  UserProfileActionTypes,
  UserProfileActions
} from "../actions/user-profile.actions";
import { IPermission } from "../../models/interfaces";

export interface IUserProfile {
  employer: IPermission[];
  representative: IPermission[];
  subscriptions: IPermission[];
}

export interface UserProfileState {
  data: IUserProfile;
  loading: boolean;
  loaded: boolean;
}

export const initialState: UserProfileState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: UserProfileActions
): UserProfileState {
  switch (action.type) {
    case UserProfileActionTypes.LoadUserProfile: {
      return {
        ...state,
        loading: true
      };
    }
    case UserProfileActionTypes.LoadUserProfileSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case UserProfileActionTypes.LoadUserProfileFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
