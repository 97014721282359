import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import * as moment from "moment";
import { IUserCredential } from "@fusion/common";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";

@Injectable({
  providedIn: "root"
})
export class TokenService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.token.host;
    this.api = this.configService.getConfig().services.token.api;
  }

  getUserToken(userCredential: IUserCredential): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/user`;
    const options = this.requestOptionsService.generateOptions(true);
    return this._http.post(fullUrl, userCredential, options);
  }

  getRefreshToken(token: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/token/refresh`;
    const options = this.requestOptionsService.generateOptions(true);
    return this._http.post(fullUrl, { token }, options);
  }

  getUtc(date) {
    return moment.utc(date, "YYYY/MM/DD H:mm:ss");
  }

  postUser(user: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, user, options);
  }
}
