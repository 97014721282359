import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import { mergeMap, catchError, withLatestFrom, map } from "rxjs/operators";

import {
  RedirectSubscriberActionTypes,
  LoadRedirectSubscriberSuccess,
  LoadRedirectSubscriberFail,
  LoadRedirectSubscriber,
} from "../actions/redirect-subscriber.actions";
import { FusionSubscriptionState } from "../reducers/index";
import { Store } from "@ngrx/store";
import { Params, Router } from "@angular/router";
import { getRouterParams } from "@fusion/router";
import { getApplications } from "@fusion/oauth";
import { IError, ErrorSource } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { IApplication } from "../../models/interfaces";

@Injectable()
export class RedirectSubscriberEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private router: Router
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<LoadRedirectSubscriber>(
      RedirectSubscriberActionTypes.LoadRedirectSubscriber
    ),
    map((action) => action.payload),
    withLatestFrom(
      this.store.select(getRouterParams),
      this.store.select(getApplications)
    ),
    mergeMap(([payload, params, apps]: [IApplication, Params, any]) => {
      const userId = params.userId;
      const companyId = params.companyId;
      const appSubscriberId = companyId ? companyId : userId;
      const protocol = apps.protocol;
      const domain = apps.domain;
      const subdomains = apps.subdomains;

      // TODO rename payload.domain to payload.subdomain
      switch (payload.domain) {
        case "companies": {
          this.router.navigate([`${userId}/companies`]);
          return of(new LoadRedirectSubscriberSuccess(payload));
        }
        case "mosques": {
          this.router.navigate([`${userId}/mosques`]);
          return of(new LoadRedirectSubscriberSuccess(payload));
        }
        case subdomains.rent: {
          window.location.href = `${protocol}://${subdomains.rent}.${domain}/${appSubscriberId}/search`;
          break;
        }
        case subdomains.company: {
          window.location.href = `${protocol}://${subdomains.company}.${domain}/${payload.id}`;
          break;
        }
        case subdomains.mosque: {
          window.location.href = `${protocol}://${subdomains.mosque}.${domain}/${payload.id}`;
          break;
        }
        default: {
          window.location.href = `${protocol}://${
            subdomains[payload.domain]
          }.${domain}/${appSubscriberId}`;
        }
      }

      return of(new LoadRedirectSubscriberSuccess(payload));
    }),
    catchError((error) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.LoadRedirectSubscriberFail,
        source: ErrorSource.Validation,
        data: error,
      };
      return of(new LoadRedirectSubscriberFail(errorPayload));
    })
  );
}
