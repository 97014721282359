import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionoAuthError } from "../../models/enums";

export enum ApplicationsActionTypes {
  SetApplications = "[Applications] Set",
  LoadApplications = "[Applications] Load",
  LoadApplicationsSuccess = "[Applications] Load Success",
  LoadApplicationsFail = "[Applications] Load Fail"
}

export class SetApplications implements Action {
  readonly type = ApplicationsActionTypes.SetApplications;

  constructor(public payload: any) {}
}

export class LoadApplications implements Action {
  readonly type = ApplicationsActionTypes.LoadApplications;
}

export class LoadApplicationsSuccess implements Action {
  readonly type = ApplicationsActionTypes.LoadApplicationsSuccess;

  constructor(public payload: any) {}
}

export class LoadApplicationsFail implements Action {
  readonly type = ApplicationsActionTypes.LoadApplicationsFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export type ApplicationsActions =
  | SetApplications
  | LoadApplications
  | LoadApplicationsSuccess
  | LoadApplicationsFail;
