import {
  ProjectsActionTypes,
  ProjectsActions
} from '../actions/projects.actions';
import { IProject } from '../../models/interfaces';

export interface ProjectsState {
  data: IProject[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: ProjectsState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: ProjectsActions
): ProjectsState {
  switch (action.type) {
    case ProjectsActionTypes.LoadProjects: {
      return {
        ...state,
        loading: true
      };
    }
    case ProjectsActionTypes.SetProjects: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case ProjectsActionTypes.LoadProjectsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
