import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";

@Injectable({
  providedIn: "root"
})
export class AuthorizationService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.authorization.host;
    this.api = this.configService.getConfig().services.authorization.api;
  }

  getUserAuthProfile(userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/auth/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }
}
