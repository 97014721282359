import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IPublication } from "../../models/interfaces";
import {
  ModalIconType,
  IFormMetadata,
  IFormConfig,
  setFormValidationRules
} from "@fusion/form";
import { PublicationForm, PublicationUpdateForm } from "../../models/forms";
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: "fusion-career-profile-publications",
  templateUrl: "./publications.component.html",
  styleUrls: ["./publications.component.scss"]
})
export class PublicationsComponent implements OnInit {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyDeletePublication: EventEmitter<any> = new EventEmitter<
    any
  >();
  @Input() publications: IPublication[];

  publicationFormMetadata: IFormMetadata = PublicationForm.metadata;
  publicationFormConfigs: IFormConfig[] = PublicationForm.configs;
  publicationFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(PublicationForm.configs)
  );
  publicationUpdateFormMetadata: IFormMetadata = PublicationUpdateForm.metadata;
  publicationUpdateFormConfigs: IFormConfig[] = PublicationUpdateForm.configs;
  publicationUpdateFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(PublicationUpdateForm.configs)
  );

  isExpanded: boolean = false;
  ModalIconType = ModalIconType;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  notifyCardFooterClick() {
    this.isExpanded = this.isExpanded ? false : true;
  }
}
