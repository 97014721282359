import {
  IForm,
  FormFieldType,
  FormFieldGroup,
  FormFieldPattern,
} from '@fusion/form';
import { countries } from '@fusion/common';

export const SignUpForm: IForm = {
  metadata: {
    name: 'SIGN_UP_FORM',
    title: 'Sign Up',
    submitButtonText: 'Sign Up',
    style: {
      topMargin: '0px',
      maxWidth: '740px',
      maxHeight: '550px',
    },
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Name,
      configs: [
        {
          label: 'First Name',
          name: 'firstName',
          type: FormFieldType.Text,
          placeholder: 'John',
          validation: {
            required: true,
            max: 30,
          },
        },
        {
          label: 'Middle Name',
          name: 'middleName',
          type: FormFieldType.Text,
          placeholder: 'Deep',
          validation: {
            required: false,
            max: 30,
          },
        },
        {
          label: 'Last Name',
          name: 'lastName',
          type: FormFieldType.Text,
          placeholder: 'Deep',
          validation: {
            required: true,
            max: 30,
          },
        },
      ],
    },
    {
      label: 'Date of Birth (MM/DD/YYYY)',
      name: 'dateOfBirth',
      type: FormFieldType.Date,
      placeholder: 'MM/DD/YYYY',
      examplePattern: '12/30/1985',
      validation: {
        required: true,
      },
    },
    {
      label: 'Gender',
      name: 'gender',
      type: FormFieldType.Radio,
      options: [
        {
          label: 'Male',
          value: 'male',
        },
        {
          label: 'Female',
          value: 'female',
        },
      ],
      validation: {
        required: true,
      },
    },
    {
      label: 'Phone#',
      name: 'phoneNo',
      type: FormFieldType.Phone,
      placeholder: '(347) 123-1234',
      validation: {
        required: true,
      },
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: 'Address',
          name: 'address',
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'Unit#',
          name: 'unit',
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 30,
          },
        },
        {
          label: 'City',
          name: 'city',
          type: FormFieldType.Text,
          placeholder: 'city',
          validation: {
            required: true,
            max: 100,
          },
        },
        {
          label: 'State',
          name: 'state',
          type: FormFieldType.Text,
          validation: {
            required: false,
          },
        },
        {
          label: 'Zip Code',
          name: 'zipCode',
          type: FormFieldType.Text,
          placeholder: 'zip code',
          examplePattern: '11432',
          validation: {
            required: false,
            max: 10,
            pattern: FormFieldPattern.Number,
          },
        },
        {
          label: 'Country',
          name: 'country',
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true,
          },
        },
      ],
    },
    {
      label: 'Email',
      name: 'email',
      type: FormFieldType.Email,
      placeholder: 'john.deep@example.com',
      examplePattern: 'john.deep@example.com',
      validation: {
        required: true,
        pattern: FormFieldPattern.Email,
      },
    },
    {
      label: 'Password',
      name: 'password',
      type: FormFieldType.Password,
      placeholder: 'password',
      validation: {
        required: true,
        min: 6
      },
    },
    {
      label: 'User Choice',
      type: FormFieldType.Checkbox,
      items: [
        {
          label: 'Privacy Policy & Terms of Use',
          name: 'privacy',
          value: 'privacy',
          validation: {
            required: true,
          },
        },
        {
          label: 'Promotional Email',
          name: 'subscribe',
          value: 'subscribe',
          validation: {
            required: false,
          },
        },
      ],
    },
  ],
};
