import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fusion-common-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {
  @Input() metadata: any;
  @Input() chipColor: string;

  constructor() {}

  ngOnInit() {}
}
