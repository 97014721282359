import {
  CurrentAppActionTypes,
  CurrentAppActions
} from "../actions/current-app.actions";
import { ICurrentAppMetadata, IApplication } from "../../models/interfaces";

export interface CurrentAppState {
  metadata: ICurrentAppMetadata;
  data: IApplication;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentAppState = {
  metadata: null,
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CurrentAppActions
): CurrentAppState {
  switch (action.type) {
    case CurrentAppActionTypes.LoadCurrentApp: {
      return {
        ...state,
        loading: true,
        metadata: action.payload
      };
    }
    case CurrentAppActionTypes.LoadCurrentAppSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case CurrentAppActionTypes.LoadCurrentAppFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
