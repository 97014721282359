import { UserActionTypes, UserActions } from "../actions/user.actions";
import { IUser } from "@fusion/common";

export interface UserState {
  data: IUser;
  loading: boolean;
  loaded: boolean;
}

export const initialState: UserState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.UpdateUser: {
      return {
        ...state,
        loading: true
      };
    }
    case UserActionTypes.UpdateUserSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case UserActionTypes.UpdateUserFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
