import * as constants from "../constants";
import * as dataModel from "../data";
import { IForm } from "../interfaces";
import { FormFieldType, FormFieldGroup } from "../enums";

export const HomerentSearchForm: IForm = {
  metadata: {
    name: constants.HOMERENT_SEARCH_FORM,
    title: "Homerent Search",
    submitButtonText: "Search",
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "400px"
    }
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Search,
      configs: [
        {
          label: "Search Home for Rent",
          name: "keywords",
          type: FormFieldType.Text,
          placeholder: "Search by Address, City, Zip Code or Country!",
          validation: {
            required: false,
            max: 50
          }
        },
        {
          label: "Search by Category",
          name: "search-category",
          type: FormFieldType.Dropdown,
          options: dataModel.countries,
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
