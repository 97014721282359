import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { IEducation } from '../../models/interfaces';

export const getEducationsState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.educations;
  }
);

export const getEducations = createSelector(
  getEducationsState,
  (state): IEducation[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
