import { IProfileNav } from '../interfaces/profile-nav.interface';

export const employerProfileNav: IProfileNav[] = [
  {
    title: 'Companies',
    path: '',
    icon: 'business',
    active: true
  },
  {
    title: 'Profile',
    path: 'profile',
    icon: 'person',
    active: true
  },
  {
    title: 'Feed',
    path: 'feed',
    icon: 'wallpaper',
    active: false
  },
  {
    title: 'Settings',
    path: 'settings',
    icon: 'settings',
    active: false
  }
];
