import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';

// from reducer

export interface ArticleState {}

export const reducers: ActionReducerMap<ArticleState> = {};

export const metaReducers: MetaReducer<ArticleState>[] = !environment.production
  ? []
  : [];

// export reducer state interfaces
