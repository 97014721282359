import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap
} from "rxjs/operators";

import {
  SubscriptionsActionTypes,
  LoadSubscriptionsSuccess,
  LoadSubscriptionsFail
} from "../actions/subscriptions.actions";
import { Store, Action } from "@ngrx/store";
import {
  SubscriptionService,
  MappingService,
  mappingType
} from "@fusion/service";
import { FusionSubscriptionState } from "../reducers/index";
import { oAuthActionTypes, getoAuthUserId } from "@fusion/oauth";
import { SubscribeActionTypes, LoadSubscriptions } from "../actions/index";
import { getRouterParams } from "@fusion/router";
import { Params } from "@angular/router";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { ISubscription } from "../../models/interfaces";

@Injectable()
export class SubscriptionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionSubscriptionState>,
    private subscriptionService: SubscriptionService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType(
      SubscriptionsActionTypes.LoadSubscriptions,
      SubscribeActionTypes.SubscribeSuccess,
      oAuthActionTypes.oAuthSuccess,
      oAuthActionTypes.SessionEnd
    ),
    withLatestFrom(
      this.store.select(getoAuthUserId),
      this.store.select(getRouterParams)
    ),
    mergeMap(([action, userId, params]: [Action, string, Params]) => {
      let errorPayload: IError<FusionSubscriptionError> = {
        code: FusionSubscriptionError.LoadSubscriptionsFail,
        source: ErrorSource.Validation,
        data: null
      };
      const companyId = params.companyId;
      let subscriberId = params.subscriberId || companyId ? companyId : userId;

      return this.subscriptionService.getApplications(subscriberId).pipe(
        switchMap(dataResult => {
          const mappedData = this.mappingService.getMappedData<ISubscription[]>(
            dataResult,
            mappingType.camelize
          );
          return [new LoadSubscriptionsSuccess(mappedData)];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue loading your subscriptions. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadSubscriptions()],
                  title: "Retry"
                }
              }
            }
          };
          return of(new LoadSubscriptionsFail(errorPayload));
        })
      );
    })
  );
}
