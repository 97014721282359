import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor(private cookieService: NgxCookieService) {}

  get(cookiename: string): Observable<any> {
    return of({ token: this.cookieService.get(cookiename) });
  }

  set(
    cookiename: string,
    value: string,
    expiration?: any,
    path?: string,
    domain?: string,
    secure?: boolean
  ) {
    this.cookieService.set(cookiename, value, expiration, path, domain, secure);
  }

  delete(cookiename: string) {
    this.cookieService.delete(cookiename);
  }
  
  deleteAll(path: string, domain: string) {
    this.cookieService.deleteAll(path, domain);
  }
}
