import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ISideNav } from '@fusion/oauth';
import { Store } from '@ngrx/store';
import { ArticleState } from './store';
import { getRelativeUrl } from '@fusion/router';
import { getProfileNavigation } from '@fusion/subscription';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'article-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Article | Dot to Circle';

  currentRootPath$: Observable<string>;
  sideNav$: Observable<ISideNav[]>;

  constructor(private store: Store<ArticleState>, private updates: SwUpdate) {
    this.updates.available.subscribe((event) => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }

  ngOnInit() {
    this.currentRootPath$ = this.store.select(getRelativeUrl);
    this.sideNav$ = this.store.select(getProfileNavigation);
  }
}
