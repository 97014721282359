import * as constants from "../constants";
import * as data from "../data";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const JobSearchOptionsForm: IForm = {
  metadata: {
    name: constants.JOB_SEARCH_OPTIONS_FORM,
    title: "Employment Type",
    submitButtonText: "Job Search",
    style: {
      topMargin: "0px",
      maxWidth: "540px",
      maxHeight: "380px"
    }
  },
  configs: [
    {
      label: "Employment Type",
      name: "jobType",
      type: FormFieldType.List,
      options: data.jobTypes,
      validation: {
        required: false
      }
    },
    {
      label: "Job Level",
      name: "jobLevel",
      type: FormFieldType.List,
      options: data.jobLevels,
      validation: {
        required: false
      }
    }
  ]
};
