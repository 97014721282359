import { Validators } from "@angular/forms";

import { SignUpForm } from "./signup.form";
import { SignInForm } from "./signin.form";
import { UpdateUserProfileForm } from "./update-user-profile.form";
import { JobSubmissionForm } from "./job-submission.form";
import { ContactUsForm } from "./contact-us.form";
import { SkillForm } from "./skill.form";
import { RepresentativeForm } from "./representative.form";
import { CertificationForm } from "./certification.form";
import { EducationForm } from "./education.form";
import { ExperienceForm } from "./experience.form";
import { ProjectForm } from "./project.form";
import { PublicationForm } from "./publication.form";
import { CareerSummaryForm } from "./career-summary.form";
import { JobSearchForm } from "./job-search.form";
import { JobSearchOptionsForm } from "./job-search-options.form";
import { CompanyForm } from "./company.form";
import { SearchCompanyForm } from "./search-company.form";
import { CoverLetterForm } from "./cover-letter.form";
import { ManageJobApplicationForm } from "./manage-job-application.form";
import { PropertyDetailForm } from "./property-detail.form";
import { AddressForm } from "./address.form";
import { PetPolicyForm } from "./pet-policy.form";
import { PropertyDescriptionForm } from "./property-description.form";
import { AmenitiesForm } from "./amenities.form";
import { PropertyDateForm } from "./property-date.form";
import { CommunityServicesForm } from "./community-services.form";
import { SharedServicesForm } from "./shared-services.form";
import { IncludedUtilitiesForm } from "./included-utilities.form";
import { SmokingPolicyForm } from "./smoking-policy.form";
import { FamilyPreferencesForm } from "./family-preferences.form";
import { LeaseTermsForm } from "./lease-terms.form";
import { HomerentSearchForm } from "./homeproperty-search.form";
import { PermissionForm } from "./permission.form";
import { CompanyUpdateForm } from "./company-update.form";
import { IFormConfig, IFormFieldValidation } from "../interfaces";
import { FormFieldType } from "../enums";

export const formConfigs = {
  signup: SignUpForm.configs,
  signin: SignInForm.configs,
  updateUserProfile: UpdateUserProfileForm.configs,
  jobSubmission: JobSubmissionForm.configs,
  contactUs: ContactUsForm.configs,
  addSkill: SkillForm.configs,
  addRepresentative: RepresentativeForm.configs,
  addCertification: CertificationForm.configs,
  addEducation: EducationForm.configs,
  addExperience: ExperienceForm.configs,
  addProject: ProjectForm.configs,
  addPublication: PublicationForm.configs,
  addJsCareerSummary: CareerSummaryForm.configs,
  jobSearchForm: JobSearchForm.configs,
  jobSearchOptionsForm: JobSearchOptionsForm.configs,
  addCompany: CompanyForm.configs,
  updateCompany: CompanyUpdateForm.configs,
  searchCompany: SearchCompanyForm.configs,
  addCoverLetter: CoverLetterForm.configs,
  manageJobApplication: ManageJobApplicationForm.configs,
  propertyDetail: PropertyDetailForm.configs,
  address: AddressForm.configs,
  petPolicy: PetPolicyForm.configs,
  propertyDescription: PropertyDescriptionForm.configs,
  amenities: AmenitiesForm.configs,
  propertyDate: PropertyDateForm.configs,
  communityServices: CommunityServicesForm.configs,
  sharedServices: SharedServicesForm.configs,
  includedUtilities: IncludedUtilitiesForm.configs,
  smokingPolicy: SmokingPolicyForm.configs,
  familyPreferences: FamilyPreferencesForm.configs,
  leaseTerms: LeaseTermsForm.configs,
  homerentSearch: HomerentSearchForm.configs,
  permission: PermissionForm.configs
};

export const formValidationRules = {
  signup: rulesMapper(SignUpForm.configs),
  signin: rulesMapper(SignInForm.configs),
  updateUserProfile: rulesMapper(UpdateUserProfileForm.configs),
  jobSubmission: rulesMapper(JobSubmissionForm.configs),
  contactUs: rulesMapper(ContactUsForm.configs),
  addSkill: rulesMapper(SkillForm.configs),
  addRepresentative: rulesMapper(RepresentativeForm.configs),
  addCertification: rulesMapper(CertificationForm.configs),
  addEducation: rulesMapper(EducationForm.configs),
  addExperience: rulesMapper(ExperienceForm.configs),
  addProject: rulesMapper(ProjectForm.configs),
  addPublication: rulesMapper(PublicationForm.configs),
  addJsCareerSummary: rulesMapper(CareerSummaryForm.configs),
  jobSearchForm: rulesMapper(JobSearchForm.configs),
  jobSearchOptionsForm: rulesMapper(JobSearchOptionsForm.configs),
  addCompany: rulesMapper(CompanyForm.configs),
  updateCompany: rulesMapper(CompanyUpdateForm.configs),
  searchCompany: rulesMapper(SearchCompanyForm.configs),
  addCoverLetter: rulesMapper(CoverLetterForm.configs),
  manageJobApplication: rulesMapper(ManageJobApplicationForm.configs),
  propertyDetail: rulesMapper(PropertyDetailForm.configs),
  address: rulesMapper(AddressForm.configs),
  petPolicy: rulesMapper(PetPolicyForm.configs),
  propertyDescription: rulesMapper(PropertyDescriptionForm.configs),
  amenities: rulesMapper(AmenitiesForm.configs),
  propertyDate: rulesMapper(PropertyDateForm.configs),
  communityServices: rulesMapper(CommunityServicesForm.configs),
  sharedServices: rulesMapper(SharedServicesForm.configs),
  includedUtilities: rulesMapper(IncludedUtilitiesForm.configs),
  smokingPolicy: rulesMapper(SmokingPolicyForm.configs),
  familyPreferences: rulesMapper(FamilyPreferencesForm.configs),
  leaseTerms: rulesMapper(LeaseTermsForm.configs),
  homerentSearch: rulesMapper(HomerentSearchForm.configs),
  permission: rulesMapper(PermissionForm.configs)
};

export const formMetadata = {
  signup: SignUpForm.metadata,
  signin: SignInForm.metadata,
  updateUserProfile: UpdateUserProfileForm.metadata,
  jobSubmission: JobSubmissionForm.metadata,
  contactUs: ContactUsForm.metadata,
  addSkill: SkillForm.metadata,
  addRepresentative: RepresentativeForm.metadata,
  addCertification: CertificationForm.metadata,
  addEducation: EducationForm.metadata,
  addExperience: ExperienceForm.metadata,
  addProject: ProjectForm.metadata,
  addPublication: PublicationForm.metadata,
  addJsCareerSummary: CareerSummaryForm.metadata,
  jobSearchForm: JobSearchForm.metadata,
  jobSearchOptionsForm: JobSearchOptionsForm.metadata,
  addCompany: CompanyForm.metadata,
  updateCompany: CompanyUpdateForm.metadata,
  searchCompany: SearchCompanyForm.metadata,
  addCoverLetter: CoverLetterForm.metadata,
  manageJobApplication: ManageJobApplicationForm.metadata,
  propertyDetail: PropertyDetailForm.metadata,
  address: AddressForm.metadata,
  petPolicy: PetPolicyForm.metadata,
  propertyDescription: PropertyDescriptionForm.metadata,
  amenities: AmenitiesForm.metadata,
  propertyDate: PropertyDateForm.metadata,
  communityServices: CommunityServicesForm.metadata,
  sharedServices: SharedServicesForm.metadata,
  includedUtilities: IncludedUtilitiesForm.metadata,
  smokingPolicy: SmokingPolicyForm.metadata,
  familyPreferences: FamilyPreferencesForm.metadata,
  leaseTerms: LeaseTermsForm.metadata,
  homerentSearch: HomerentSearchForm.metadata,
  permission: PermissionForm.metadata
};

function rulesMapper(formModel: IFormConfig[]) {
  let mappedRules = {};

  formModel.map(formField => {
    if (formField.type === FormFieldType.Group) {
      formField.configs.map(field => {
        if (field.name && field.validation) {
          mappedRules[field.name] = getValidations(field.validation, null);
        }

        if (field.items) {
          field.items.map(item => {
            mappedRules[item.name] = getValidations(item.validation, false);
          });
        }
      });
    }

    if (formField.name && formField.validation) {
      mappedRules[formField.name] = getValidations(formField.validation, null);
    }

    if (formField.items) {
      formField.items.map(item => {
        mappedRules[item.name] = getValidations(item.validation, false);
      });
    }
  });

  return mappedRules;
}

function getValidations(
  validation: IFormFieldValidation,
  defaultValue?: boolean
) {
  let validationRules = [];
  if (validation.required) {
    validationRules = [...validationRules, Validators.required];
  }
  if (validation.pattern) {
    validationRules = [
      ...validationRules,
      Validators.pattern(validation.pattern)
    ];
  }
  if (validation.max) {
    validationRules = [
      ...validationRules,
      Validators.maxLength(validation.max)
    ];
  }
  if (validation.min) {
    validationRules = [
      ...validationRules,
      Validators.minLength(validation.min)
    ];
  }
  return [defaultValue, Validators.compose(validationRules)];
}
