import { Component, OnInit, Input } from "@angular/core";
import { IFormConfig } from "../../models/interfaces";
import { FormGroup, ValidatorFn, Validators } from "@angular/forms";

@Component({
  selector: "fusion-form-job-apply-type",
  templateUrl: "./job-apply-type.component.html",
  styleUrls: ["./job-apply-type.component.scss"],
})
export class JobApplyTypeComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {}

  updateApplyControl(type: string, config: IFormConfig) {
    this.resetFormControlValidators("url");
    this.resetFormControlValidators("email");
    switch (type) {
      case "email": {
        this.setFormControlValidators("email", [
          Validators.required,
          Validators.pattern(config.validation.pattern),
        ]);
        break;
      }
      case "redirect": {
        this.setFormControlValidators("url", [
          Validators.required,
          Validators.pattern(config.validation.pattern),
        ]);
        break;
      }
    }
  }

  getApplyTypeControlValue(key: string, config: IFormConfig) {
    const value = this.formValidationRules.get(key).value;
    this.updateApplyControl(value, config);
    return value;
  }

  private setFormControlValidators(key: string, validators: ValidatorFn[]) {
    this.formValidationRules.controls[key].setValidators(validators);
    this.formValidationRules.controls[key].updateValueAndValidity();
  }

  private resetFormControlValidators(key: string) {
    this.formValidationRules.controls[key].clearValidators();
    this.formValidationRules.controls[key].updateValueAndValidity();
  }
}
