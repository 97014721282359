<div bPageSpacing class="row3">
  <div
    centering
    centerLeft
    fixedPosition
    class="row3__section-one toolbar col2"
    quill-editor-toolbar
  >
    <span class="col2__section-one">
      <span class="ql-formats ph3">
        <select class="ql-header" [title]="'Header'">
          <option selected></option>
          <option value="3">Section Title 1</option>
          <option value="4">Section Title 2</option>
        </select>
      </span>
      <span class="ql-formats ph3">
        <button class="ql-bold" [title]="'Bold'"></button>
        <button class="ql-italic" [title]="'Italic'"></button>
        <button class="ql-underline" [title]="'Underline'"></button>
        <button class="ql-strike" [title]="'Strike'"></button>
      </span>
      <span class="ql-formats ph3">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
      </span>
      <span class="ql-formats ph3">
        <!-- <button class="ql-formula"></button> -->
        <button class="ql-blockquote"></button>
        <button class="ql-code-block"></button>
      </span>
      <span class="ql-formats ph3">
        <button class="ql-link"></button>
        <button class="ql-image"></button>
      </span>
    </span>
    <!-- <span class="menu-spacer"></span> -->
    <span class="col2__section-two action-button">
      <ng-container *ngIf="isUpdating; else saved">
        <span centering center class="saving ph3"
          >Saving<span>.</span><span>.</span><span>.</span></span
        >
      </ng-container>
      <ng-template #saved>
        <ng-container *ngIf="isUnsaved; else saved_state">
          <span centering center class="saving ph3"></span>
        </ng-container>
        <ng-template #saved_state>
          <span centering center g3FontColor class="saving ph3">Saved</span>
        </ng-template>
      </ng-template>
      <button class="publish" (click)="publishArticle()">
        Publish
      </button>
    </span>
  </div>

  <div class="row3__section-two">
    <div image>
      <img imageFit [src]="currentArticle?.bannerImage || ''" />
      <div class="overlay">
        <fusion-common-back
          [currentRootPath]="currentRootPath"
          [targetDir]="''"
          [buttonText]="'back'"
        ></fusion-common-back>
        <div id="ember74" class="cover-image__drop ember-view">
          <label
            centering
            center
            class="cover-image__label"
            for="cover-image__file-input"
          >
            <mat-icon for="cover-image__file-input">cloud_upload</mat-icon>
          </label>

          <input
            accept="image/*"
            id="cover-image__file-input"
            class="cover-image__file-input"
            type="file"
            (change)="onFileChange($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    microPage
    pageSpacing
    bPageSpacing
    class="row3__section-three"
    [formGroup]="articleFormGroup"
  >
    <mat-form-field fullWidth appearance="standard">
      <textarea
        matInput
        formControlName="title"
        placeholder="Article Title"
        maxlength="100"
        required
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        class="post-headline"
        (blur)="saveArticle()"
        (focus)="unsavedArticle()"
      ></textarea>
    </mat-form-field>
    <mat-form-field fullWidth appearance="standard">
      <textarea
        matInput
        formControlName="subtitle"
        placeholder="Subtitle"
        maxlength="175"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        class="post-subheadline"
        (blur)="saveArticle()"
        (focus)="unsavedArticle()"
      ></textarea>
    </mat-form-field>

    <!-- [modules]="quillConfig" -->
    <quill-editor
      formControlName="content"
      [modules]="quillConfig"
      [styles]="{
        fontSize: '16px',
        border: 'none',
        padding: '0px',
        margin: '0px'
      }"
      [placeholder]="'Let the world know your talent!'"
      (onSelectionChanged)="onSelectionChanged($event)"
      (onContentChanged)="onContentChanged($event)"
      (onFocus)="unsavedArticle()"
      (onBlur)="saveArticle()"
      (onEditorCreated)="getEditorInstance($event)"
    >
    </quill-editor>
    <input type="file" style="display: none;" id="fileInputField" />
    <!-- <hr />
      <quill-view [content]="htmlText" format="text" theme="snow"></quill-view> -->
  </div>
</div>
