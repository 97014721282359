<div
  cardSpacing
  bgColor
  tRadius
  *ngIf="title"
  class="col2"
  [ngClass]="{ 'card-header-color': isColor }"
>
  <div
    exMediumTitle
    class="col2__section-one"
    [ngClass]="{ 'title-color': isColor }"
  >
    {{ title }}
  </div>
  <div centeringRight *ngIf="isEditable" class="col2__section-two">
    <button class="custom-button" mat-fab color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
