import {
  ApplicationsActionTypes,
  ApplicationsActions
} from "../actions/applications.actions";
import { IApplication } from "../../models/interfaces";

export interface ApplicationsState {
  data: IApplication;
  loading: boolean;
  loaded: boolean;
}

export const initialState: ApplicationsState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: ApplicationsActions
): ApplicationsState {
  switch (action.type) {
    case ApplicationsActionTypes.LoadApplications: {
      return {
        ...state,
        loading: true
      };
    }
    case ApplicationsActionTypes.LoadApplicationsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case ApplicationsActionTypes.SetApplications: {
      return {
        ...state,
        data: action.payload
      };
    }
    case ApplicationsActionTypes.LoadApplicationsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
