import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"]
})
export class DatepickerComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {}
}
