import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogTemplateComponent } from "../../containers/dialog-template/dialog-template.component";
import { debounceTime } from "rxjs/operators";
import { ModalIconType } from "../../models/enums";
import { IFormMetadata, IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-dialog",
  templateUrl: "./form-dialog.component.html",
  styleUrls: ["./form-dialog.component.scss"]
})
export class FormDialogComponent implements OnInit, OnChanges {
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() isDialog: boolean;
  @Input() autoCompleteEntities: any;
  @Input() entityType: string;
  @Input() formValidationRules: FormGroup;
  @Input() formConfigs: IFormConfig;
  @Input() formMetadata: IFormMetadata;
  @Input() isEditable: boolean;
  @Input() isAddable: boolean;
  @Input() buttonTitle: string;
  @Input() isNavOption: boolean = false;
  @Input() iconType: ModalIconType;

  ModalIconType = ModalIconType;

  private dialogRef: MatDialogRef<DialogTemplateComponent>;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.autoCompleteEntities = this.autoCompleteEntities;
    }
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(DialogTemplateComponent, {
      width: this.formMetadata.style
        ? this.formMetadata.style.maxWidth
        : "300px",
      // height: this.formMetadata.style
      //   ? this.formMetadata.style.maxHeight
      //   : null,
      // maxWidth: this.formMetadata.style
      //   ? this.formMetadata.style.max_width
      //   : '300px',
      maxHeight: '80vh',
      data: {
        isDialog: this.isDialog,
        autoCompleteEntities: this.autoCompleteEntities,
        formValidationRules: this.formValidationRules,
        formConfigs: this.formConfigs,
        formMetadata: this.formMetadata
      }
    });

    this.dialogRef.componentInstance.notifyAutocompletion
      .pipe(debounceTime(500))
      .subscribe((inputData: string) => {
        this.notifyAutocompletion.emit(inputData);
      });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notifyFormSubmit.emit({
          data: result,
          name: this.formMetadata.name
        });
      }
    });
  }
}
