import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlaceComponent } from "./containers/place/place.component";
import { MapComponent } from "./containers/map/map.component";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/reducers';
import { effects } from './store/effects';

@NgModule({
  declarations: [PlaceComponent, MapComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature("fusionLocation", reducers),
    EffectsModule.forFeature(effects)
  ],
  exports: [PlaceComponent, MapComponent],
})
export class FusionLocationModule {}
