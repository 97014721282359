<div *ngIf="formConfigs && formValidationRules">
  <div
    class="form-group"
    [ngClass]="{
      'has-error':
        !formValidationRules.controls[formConfigs.name].valid &&
        formValidationRules.controls[formConfigs.name].touched
    }"
  >
    <div class="example-container inputGroupContainer">
      <mat-form-field class="col-12">
        <input
          matInput
          aria-label="Country"
          [matAutocomplete]="auto"
          [formControl]="formValidationRules.controls[formConfigs.name]"
          placeholder="{{ formConfigs.placeholder }}"
          required="{{
            formValidationRules.controls[formConfigs.name].hasError('required')
          }}"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName">
          <div
            *ngIf="
              formConfigs?.autocompleteOptionType == 'user';
              else other_option
            "
          >
            <mat-option
              *ngFor="let entity of autoCompleteEntities"
              [value]="entity"
            >
              <img
                style="vertical-align:middle;"
                aria-hidden
                src="{{
                  entity.profilePicture ||
                    '/assets/images/users/profile-picture/profile.png'
                }}"
                height="25"
              />
              <span> {{ entity?.firstName }} {{ entity?.lastName }}</span> |
              <small>{{ entity?.address }}, </small>
              <small *ngIf="entity.city">{{ entity?.city }}, </small>
              <small *ngIf="entity.state">{{ entity?.state }}, </small>
              <small *ngIf="entity.country">{{ entity?.country }} </small>
              <small *ngIf="entity.zipCode">- {{ entity?.zipCode }}</small>
            </mat-option>
          </div>

          <ng-template #other_option>
            <mat-option
              *ngFor="let entity of autoCompleteEntities"
              [value]="entity"
            >
              <img
                style="vertical-align:middle;"
                aria-hidden
                src="{{
                  entity.logo ||
                    '/assets/images/users/company-logo/logoPlaceholder.png'
                }}"
                height="25"
              />
              <span> {{ entity.name }}</span> |
              <small>{{ entity?.address }}, </small>
              <small *ngIf="entity.city">{{ entity?.city }}, </small>
              <small *ngIf="entity.state">{{ entity?.state }}, </small>
              <small *ngIf="entity.country">{{ entity?.country }} </small>
              <small *ngIf="entity.zipCode">- {{ entity?.zip_code }}</small>
            </mat-option>
          </ng-template>
        </mat-autocomplete>
      </mat-form-field>

      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('required') &&
          formValidationRules.controls[formConfigs.name].touched
        "
      >
        You must include {{ formConfigs.label }}.
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError('pattern') &&
          formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} is invalid. Allowed format is
        <b>{{ formConfigs.examplePattern }}</b>
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError(
            'minlength'
          ) && formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} must be at least
        {{ formConfigs.validation.min }} characters long.
      </mat-error>
      <mat-error
        *ngIf="
          formValidationRules.controls[formConfigs.name].hasError(
            'maxlength'
          ) && formValidationRules.controls[formConfigs.name].touched
        "
      >
        Your {{ formConfigs.label }} cannot exceed
        {{ formConfigs.validation.max }} characters.
      </mat-error>
    </div>
  </div>
</div>
