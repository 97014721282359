import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Output() notifyFormValidationUpdate = new EventEmitter<boolean>();
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {
    this.formValidationRules.valueChanges.subscribe((formData: any) => {
      if (this.formConfigs.disable) {
        this.setDisabledProperties(formData);
      }
    });
  }

  update(value) {
    if (this.formConfigs.display) {
      this.notifyFormValidationUpdate.emit(value);
    }
  }

  setDisabledProperties(formData) {
    if (formData[this.formConfigs.disable.by]) {
      this.formConfigs.disable.fields.map((field) => {
        this.formValidationRules.controls[field].disable({
          onlySelf: true,
        });
      });
    } else {
      this.formConfigs.disable.fields.map((field) => {
        if (this.formValidationRules.controls[field]) {
          this.formValidationRules.controls[field].enable({
            onlySelf: true,
          });
        }
      });
    }
  }
}
