import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const IncludedUtilitiesForm: IForm = {
  metadata: {
    name: constants.INCLUDED_UTILITIES_FORM,
    title: "Included Utilities",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "500px",
      maxHeight: "300px"
    }
  },
  configs: [
    {
      label: "",
      type: FormFieldType.Checkbox,
      isMultiColumnCheckbox: false,
      items: [
        {
          label: "Electric",
          name: "electric",
          value: "electric",
          validation: {
            required: false
          }
        },
        {
          label: "Water",
          name: "water",
          value: "water",
          validation: {
            required: false
          }
        },
        {
          label: "Gas",
          name: "gas",
          value: "gas",
          validation: {
            required: false
          }
        },
        {
          label: "Internet/WiFi",
          name: "internet",
          value: "internet",
          validation: {
            required: false
          }
        },
        {
          label: "TV/Cable/Satellite",
          name: "tv",
          value: "TV/Cable/Satellite",
          validation: {
            required: false
          }
        }
      ]
    }
  ]
};
