import * as constants from "../constants";
import { FormFieldType } from "../enums";
import { IForm } from "../interfaces";

export const CareerSummaryForm: IForm = {
  metadata: {
    name: constants.CAREER_SUMMARY_FORM,
    title: "Add Career Summary",
    submitButtonText: "Send",
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "530px"
    }
  },
  configs: [
    {
      label: "Summary",
      name: "careerSummary",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: true,
        min: 10,
        max: 500
      }
    }
  ]
};
