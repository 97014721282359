<div *ngIf="subscriptions" class="rowN">
  <div *ngFor="let subscription of subscriptions" class="row2">
    <div class="row2__section-one">
      <span largeTitle g5FontColor light>{{ subscription.name }}</span>
    </div>

    <div class="row2__section-two">
      <fusion-subscription
        [applications]="subscription.applications"
        [isOnlySubscriptions]="isOnlySubscriptions"
        (notifyViewApplication)="notifyViewApplication.emit($event)"
        (notifySubscribe)="notifySubscribe.emit($event)"
      ></fusion-subscription>
    </div>
  </div>
</div>
