import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IFormConfig, IFormMetadata } from "@fusion/form";
import { FormGroup } from "@angular/forms";
import { ICompany } from "../../models/interfaces";

@Component({
  selector: "fusion-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"]
})
export class CompanyComponent implements OnInit {
  @Input() company: ICompany;

  constructor() {}

  ngOnInit() {}
}
