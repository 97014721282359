import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { HighlightModule } from "ngx-highlightjs";
import xml from "highlight.js/lib/languages/xml";
import scss from "highlight.js/lib/languages/scss";
import typescript from "highlight.js/lib/languages/typescript";
import javascript from "highlight.js/lib/languages/javascript";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { reducers, metaReducers } from "./store/reducers/index";
import { effects } from "./store/effects/index";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FusionoAuthModule } from "@fusion/oauth";
import { FusionCommonModule } from "@fusion/common";
import { FusionServiceModule } from "@fusion/service";
import { ArticlesComponent } from "./containers/articles/articles.component";
import { FusionSubscriptionModule } from "@fusion/subscription";
import { FusionRouterModule } from "@fusion/router";
import { PublicationsComponent } from "./containers/publications/publications.component";
import { SettingsComponent } from "./containers/settings/settings.component";
import { AddArticleComponent } from "./containers/add-article/add-article.component";
import { FusionArticleModule } from "@fusion/article";
import { FormsModule } from "@angular/forms";
import { ArticleComponent } from "./containers/article/article.component";
import { MyArticlesComponent } from "./containers/my-articles/my-articles.component";
import { EditArticleComponent } from "./containers/edit-article/edit-article.component";
import { FusionMaterialModule } from "@fusion/material";
import { FusionThemeModule } from "@fusion/theme";
import {
  FusionErrorModule,
  ServerErrorInterceptor,
  ErrorHandlerService
} from "@fusion/error";

export function hljsLanguages() {
  return [
    { name: "typescript", func: typescript },
    { name: "javascript", func: javascript },
    { name: "scss", func: scss },
    { name: "xml", func: xml }
  ];
}

@NgModule({
  declarations: [
    AppComponent,
    ArticlesComponent,
    PublicationsComponent,
    SettingsComponent,
    AddArticleComponent,
    ArticleComponent,
    MyArticlesComponent,
    EditArticleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    FusionThemeModule.forRoot(),
    HighlightModule,
    HttpClientModule,
    FusionRouterModule,
    FusionoAuthModule,
    FusionCommonModule,
    FusionServiceModule,
    FusionSubscriptionModule,
    FusionArticleModule,
    FusionMaterialModule,
    FusionErrorModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production // Restrict extension to log-only mode
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
