import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const PropertyDescriptionForm: IForm = {
  metadata: {
    name: constants.PROPERTY_DESCRIPTION_FORM,
    title: "Property Description",
    submitButtonText: "Next",
    stepper: {
      isStepper: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px"
      // max_height: '530px'
    }
  },
  configs: [
    {
      label: "Description",
      name: "description",
      type: FormFieldType.Ckeditor,
      placeholder: "property description",
      validation: {
        required: false,
        max: 2000
      }
    }
  ]
};
