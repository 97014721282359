<mat-sidenav-container class="drawer" scrollable>
  <mat-sidenav
    #appDrawer
    fixedInViewport="true"
    class="drawer-container"
    [mode]="mode"
    [opened]="isHomeApp ? false : isOpen"
    sticky
  >
    <!-- top nav -->
    <ng-container *ngIf="!(token$ | async); else oauthUser">
      <div class="drawer__top-nav" fxShow="true" fxHide.gt-sm>
        <div class="nav__item" routerLink="/signin" (click)="toggleNav(true)">
          <mat-icon>lock_open</mat-icon>
          <span>Sign In</span>
        </div>
        <div class="nav__item" routerLink="/signup" (click)="toggleNav(true)">
          <mat-icon>person_add</mat-icon>
          <span>Sign Up</span>
        </div>
      </div>
    </ng-container>

    <ng-template #oauthUser>
      <div class="drawer__top-nav" fxShow="true" fxHide.gt-sm>
        <div
          class="top-nav__item"
          *ngIf="authUserProfilePath$ | async as userAppBaseUrl"
          (click)="navigateToUserProfile(userAppBaseUrl)"
        >
          <div *ngIf="oAuthUser$ | async as user" centering>
            <span smallLogo>
              <img
                imageFit
                src="{{
                  user.profilePicture ||
                    'assets/images/users/profile-picture/profile.png'
                }}"
              />
            </span>
          </div>
          <div centering centerLeft>
            <span>User Profile</span>
          </div>
        </div>
        <ng-container *ngFor="let company of oAuthCompanies$ | async">
          <div
            class="top-nav__item"
            *ngIf="companyAppBaseUrl$ | async as baseUrl"
            (click)="navigateToCompany(baseUrl, company.id)"
          >
            <div centering>
              <span smallLogo>
                <img
                  imageFit
                  src="{{
                    company?.logo ||
                      '/assets/images/users/company-logo/logoPlaceholder.png'
                  }}"
                />
              </span>
            </div>
            <div centering centerLeft>
              <span>{{ company.name | truncate: 25 }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div class="top-nav__item-sign-out" (click)="signOut()">
            <div centering center>
              <mat-icon color="warn">link_off</mat-icon>
            </div>
            <div centering centerLeft>
              <span errorFontColor>Sign Out</span>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="v-margin-5" fxShow="true" fxHide.gt-sm>
        <mat-divider></mat-divider>
      </div>

      <!-- side nav -->
      <div class="drawer__side-nav">
        <ng-container *ngIf="breakpoint$ | async as breakpoint">
          <ng-container *ngFor="let nav of sideNav">
            <div
              pointer
              class="side-nav__item"
              [class.drawer__item-active]="nav.isCurrentPath"
              [attr.semiBold]="nav.isCurrentPath ? 'semiBold' : null"
              routerLink="{{ currentRootPath }}/{{ nav.path }}"
              (click)="toggleNav(breakpoint.matches)"
            >
              <ng-container *ngIf="nav.isCurrentPath; else regularIcon">
                <mat-icon color="primary">{{ nav.icon }}</mat-icon>
                <div centering centerLeft>
                  <span primaryFontColor>
                    {{ nav.title }}
                  </span>
                </div>
              </ng-container>

              <ng-template #regularIcon>
                <mat-icon>{{ nav.icon }}</mat-icon>
                <div centering centerLeft>
                  <span>
                    {{ nav.title }}
                  </span>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
