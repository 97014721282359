import { Component, OnInit, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fusion-common-social-icons',
  templateUrl: './social-icons.component.html',
  styleUrls: ['./social-icons.component.scss']
})
export class SocialIconsComponent implements OnInit {
  @Input() currentUrl: string;
  color: string;

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.addSvgIcon(
      'email-open',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/email-open.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'gmail',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/gmail.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'facebook-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/facebook-box.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'google-plus-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/google-plus-box.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'linkedin-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/linkedin-box.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'pinterest-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/pinterest-box.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'twitter-box',
      domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/twitter-box.svg'
      )
    );
  }

  ngOnInit() {
    this.color = 'default';
  }
}
