import { ICarousel } from "../interfaces/carousel.interface";

export const carouselItems: ICarousel[] = [
  {
    title: "Rent Properties",
    description: "List your property, let us find best tenant for you."
  },
  {
    title: "Company Profile",
    description:
      "Add, claim and manage companies, subscribe to services and manage permissions."
  },
  {
    title: "Post Jobs",
    description: "Post job with a click of a button."
  },
  {
    title: "Career Profile",
    description: "Create your career profile, search and apply for job."
  },
  {
    title: "Write Articles",
    description:
      "Write articles, stories and series to create professional brand."
  }
];
