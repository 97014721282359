import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICompany } from '@fusion/common';

@Component({
  selector: 'fusion-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent implements OnInit {
  @Output() notifyViewCompany: EventEmitter<any> = new EventEmitter<any>();
  @Input() company: ICompany;

  constructor() {}

  ngOnInit() {}
}
