import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.payment.host;
    this.api = this.configService.getConfig().services.payment.api;
  }

  postPayment(paymentData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/invoices`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, paymentData, options);
  }

  getInvoices(customerId: string, applicationId: string): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/customers/${customerId}/applications/${applicationId}/invoices`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getCustomer(customerId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/customers/${customerId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postCustomer(customerData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/customers`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, customerData, options);
  }

  postCard(cardData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/cards`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, cardData, options);
  }

  confirmCard(cardData: any, cardId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/cards/${cardId}/confirm`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, cardData, options);
  }

  getWallet(walletId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/wallets/${walletId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postWallet(walletData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/wallets`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, walletData, options);
  }

  postPaymentWithCard(paymentData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/payments`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, paymentData, options);
  }

  confirmPaymentWithCard(paymentId: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/payments/${paymentId}/confirm`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, {}, options);
  }

  addBalance(balanceData: any, walletId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/wallets/${walletId}/balance`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, balanceData, options);
  }

  redeemCoupon(couponMetadata: any, walletId: string, code): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/wallets/${walletId}/coupons/${code}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, couponMetadata, options);
  }

  getFundingOptions(): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/fundingoptions`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getFundingOption(fundingOptionId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/fundingoptions/${fundingOptionId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getFundingTotal(): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/funding/total`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getFunding(financierId: string, paid: boolean): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/funding?financier_id=${financierId}&paid=${paid}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postFunding(fundingData: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/funding`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, fundingData, options);
  }

  confirmFunding(fundingId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/funding/${fundingId}/confirm`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, {}, options);
  }

  getFundingContributors(fundingOptionId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/fundingoptions/${fundingOptionId}/contributors`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }
}
