import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  ExperiencesActionTypes,
  AddExperiences,
  AddExperiencesSuccess,
  AddExperiencesFail,
  UpdateExperiences,
  DeleteExperiences,
  DeleteExperiencesSuccess,
  DeleteExperiencesFail,
  UpdateExperiencesFail
} from "../actions/experiences.actions";
import { Store } from "@ngrx/store";
import { FusionCareerProfileState } from "../reducers/index";
import { EmployerService } from "@fusion/service";
import { getoAuthUserId } from "@fusion/oauth";
import { LoadJobseekerProfile } from "../actions/jobseeker-profile.actions";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

@Injectable()
export class ExperiencesEffects {
  constructor(
    public actions$: Actions,
    private store: Store<FusionCareerProfileState>,
    private employerService: EmployerService
  ) {}

  @Effect()
  addExperiences$ = this.actions$.pipe(
    ofType<AddExperiences>(ExperiencesActionTypes.AddExperiences),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([payload, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.AddExperiencesFail,
        source: ErrorSource.Validation,
        data: null
      };
      const mappedExperience = this.getMappedExperience(userId, payload);
      return this.employerService.postExperience(mappedExperience).pipe(
        switchMap(dataresult => {
          return [
            new AddExperiencesSuccess(dataresult),
            new LoadJobseekerProfile()
          ];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue adding your experience. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new AddExperiences(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new AddExperiencesFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  updateExperiences$ = this.actions$.pipe(
    ofType<UpdateExperiences>(ExperiencesActionTypes.UpdateExperiences),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([payload, userId]: [any, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.UpdateExperiencesFail,
        source: ErrorSource.Validation,
        data: null
      };
      const experienceId = payload.id;
      const mappedExperience = this.getMappedExperience(userId, payload);
      delete mappedExperience.id;
      return this.employerService
        .updateExperience(mappedExperience, experienceId)
        .pipe(
          switchMap(dataresult => {
            return [
              new AddExperiencesSuccess(dataresult),
              new LoadJobseekerProfile()
            ];
          }),
          catchError(error => {
            errorPayload = {
              ...errorPayload,
              source: ErrorSource.API,
              data: error,
              config: {
                type: ErrorHandlingType.Dialog,
                message:
                  "Sorry, we are having some issue updating your experience. Please try again later.",
                action: {
                  primary: {
                    type: ErrorActionType.Dispatch,
                    reference: [new UpdateExperiences(payload)],
                    title: "Retry"
                  }
                }
              }
            };
            return of(new UpdateExperiencesFail(errorPayload));
          })
        );
    })
  );

  @Effect()
  deleteExperiences$ = this.actions$.pipe(
    ofType<DeleteExperiences>(ExperiencesActionTypes.DeleteExperiences),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    mergeMap(([experienceId, userId]: [string, string]) => {
      let errorPayload: IError<FusionCareerProfileError> = {
        code: FusionCareerProfileError.DeleteExperiencesFail,
        source: ErrorSource.Validation,
        data: null
      };
      return this.employerService.deleteExperience(experienceId, userId).pipe(
        switchMap(dataResult => {
          return [
            new DeleteExperiencesSuccess(dataResult),
            new LoadJobseekerProfile()
          ];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue deleting your experience. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new DeleteExperiences(experienceId)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new DeleteExperiencesFail(errorPayload));
        })
      );
    })
  );

  getMappedExperience(userId: string, experienceRaw: any) {
    if (experienceRaw.company.id) {
      return {
        id: experienceRaw.id,
        user_id: userId,
        company_id: experienceRaw.company.id,
        position: experienceRaw.position,
        job_type: experienceRaw.jobType,
        start_date: experienceRaw.startDate,
        end_date: experienceRaw.endDate || null,
        responsibility: experienceRaw.responsibility || null
      };
    }
    return {
      user_id: userId,
      company: {
        name: experienceRaw.company || null,
        category: experienceRaw.category || null,
        type: experienceRaw.type || null,
        address: experienceRaw.address || null,
        unit: experienceRaw.unit || null,
        city: experienceRaw.city || null,
        state: experienceRaw.state || null,
        country: experienceRaw.country || null,
        zip_code: experienceRaw.zipCode || null
      },
      position: experienceRaw.position,
      job_type: experienceRaw.jobType,
      start_date: experienceRaw.startDate,
      end_date: experienceRaw.endDate || null,
      responsibility: experienceRaw.responsibility || null
    };
  }
}
