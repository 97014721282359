<div *ngIf="company" class="row4">
  <div bottomDivider class="row4__section-one">
    <div class="profile-image">
      <img
        class="object-fit_cover"
        routerLink="/company/{{ company.id }}"
        src="{{
          company?.logo ||
            '/assets/images/users/company-logo/logoPlaceholder.png'
        }}"
      />
    </div>
  </div>
  <div class="row4__section-two">
    <h5>{{ company.name }}</h5>
  </div>
  <div
    smallFont
    class="row4__section-three"
    [innerHTML]="company?.companyDetail | truncate: 300"
  ></div>
  <div class="row4__section-four">
    <a routerLink="/company/{{ company.id }}">View More</a>
  </div>
</div>
