import * as constants from "../constants";
import * as dataModel from "../data";
import { currencyCode, payRates } from "../data";
import { IForm } from "../interfaces";
import { FormFieldType, FormFieldGroup } from "../enums";

export const PropertyDetailForm: IForm = {
  metadata: {
    name: constants.PROPERTY_DETAIL_FORM,
    title: "Property Detail",
    submitButtonText: "Next",
    stepper: {
      isStepper: true,
      isFirstStep: true
    },
    style: {
      topMargin: "0px",
      maxWidth: "800px",
      maxHeight: "400px"
    }
  },
  configs: [
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.HomeProperty,
      configs: [
        {
          label: "Property Name",
          name: "propertyName",
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 25
          }
        },
        {
          label: "Property Type",
          name: "propertyType",
          type: FormFieldType.Dropdown,
          options: dataModel.propertyType,
          validation: {
            required: true
          }
        },
        {
          label: "SQFT",
          name: "sqft",
          type: FormFieldType.Number,
          validation: {
            required: false,
            max: 10
          }
        },
        {
          label: "Beds",
          name: "beds",
          type: FormFieldType.Dropdown,
          options: dataModel.bedsCount,
          validation: {
            required: false
          }
        },
        {
          label: "Rooms",
          name: "rooms",
          type: FormFieldType.Dropdown,
          options: dataModel.roomCount,
          validation: {
            required: true
          }
        },
        {
          label: "Baths",
          name: "baths",
          type: FormFieldType.Dropdown,
          options: dataModel.bathsCount,
          validation: {
            required: true
          }
        },
        {
          label: "Price",
          name: "price",
          type: FormFieldType.Number,
          placeholder: "$100000/year",
          examplePattern: "12345",
          validation: {
            required: true,
            pattern: "[0-9]+"
          }
        },
        {
          label: "Deposite",
          name: "deposite",
          type: FormFieldType.Number,
          placeholder: "$100000/year",
          examplePattern: "12345",
          validation: {
            required: false,
            pattern: "[0-9]+"
          }
        },
        {
          label: "Currency Code",
          name: "currencyCode",
          type: FormFieldType.Dropdown,
          placeholder: "$100000/year",
          options: currencyCode,
          validation: {
            required: true
          }
        },
        {
          label: "Price Rate",
          name: "paymentCycle",
          type: FormFieldType.Dropdown,
          placeholder: "$100000/year",
          options: payRates,
          validation: {
            required: true
          }
        }
      ]
    }
  ]
};
