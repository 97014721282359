import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";

import { CropperComponent } from "angular-cropperjs";
import { IFileUpload } from "../../models/interfaces";

@Component({
  selector: "fusion-common-image-crop",
  templateUrl: "./image-crop.component.html",
  styleUrls: ["./image-crop.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropComponent implements OnInit {
  @Output() notifyImageUpload: EventEmitter<any> = new EventEmitter<any>();
  @Input() isUploading: boolean;
  @Input() aspectRatio: number;
  @Input() uploadTitle: string;
  @Input() isRoundedCanvas: boolean = false;

  @ViewChild("angularCropper")
  public angularCropper: CropperComponent;
  currentImage: IFileUpload;
  config = null;
  imageUrl = null;

  constructor() {}

  ngOnInit() {
    this.config = {
      aspectRatio: this.aspectRatio,
      dragMode: "move",
      background: true,
      movable: true,
      rotatable: true,
      scalable: true,
      zoomable: true,
      viewMode: 1,
      checkImageOrigin: true,
      checkCrossOrigin: false
    };
  }

  crop() {
    // Crop
    let dimension = {};

    if (this.isRoundedCanvas) {
      dimension = {
        width: 200,
        height: 200
      };
    }
    let croppedCanvas = this.angularCropper.cropper.getCroppedCanvas(dimension);

    // Round
    let roundedCanvas = this.getRoundedCanvas(croppedCanvas);

    let roundedBase64Data = roundedCanvas.toDataURL();
    let rectangularBase64Data = croppedCanvas.toDataURL(
      this.currentImage.type,
      0.9
    );

    let tempImage = document.createElement("img");
    const croppedImageBase64 = this.isRoundedCanvas
      ? roundedBase64Data
      : rectangularBase64Data;
    tempImage.src = croppedImageBase64;

    this.imageUrl = null;
    let block = croppedImageBase64.split(";");
    let realData = block[1].split(",")[1];
    const croppedImage: IFileUpload = {
      ...this.currentImage,
      blob: this.b64toBlob(realData, this.currentImage.type)
    };

    this.notifyImageUpload.emit(croppedImage);
  }

  rotate() {
    this.angularCropper.cropper
      .move(1, -1)
      .rotate(45)
      .scale(1, -1);
  }

  getRoundedCanvas(sourceCanvas) {
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    let width = sourceCanvas.width;
    let height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = "destination-in";
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  }

  onNotifyImageUpload(image) {
    this.currentImage = image;
    const reader = new FileReader();
    reader.readAsDataURL(image.blob);
    reader.onload = () => {
      this.imageUrl = reader.result;
    };
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
