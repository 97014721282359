import { Component, OnInit } from '@angular/core';
import {
  IFormMetadata,
  IFormConfig,
  setFormValidationRules,
} from '@fusion/form';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SignInForm } from '../../models/forms';
import { FusionoAuthState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { oAuthStart } from '../../store/actions';
import { Observable } from 'rxjs';
import { IErrorHandlingConfig, getErrorHandlingConfig } from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { getOauthProgressState } from '../../store/selectors';
import { IProgressState } from '@fusion/common';

@Component({
  selector: 'fusion-oauth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  signInError$: Observable<IErrorHandlingConfig>;
  signInState$: Observable<IProgressState>;

  // form templates
  signInFormMetadata: IFormMetadata = SignInForm.metadata;
  signInFormConfigs: IFormConfig[] = SignInForm.configs;
  signInFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(SignInForm.configs)
  );

  constructor(
    private store: Store<FusionoAuthState>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {}

  notifySignInFormSubmit(formData) {
    this.store.dispatch(new oAuthStart(formData));
    this.signInState$ = this.store.select(getOauthProgressState);
    this.signInError$ = this.store.select(
      getErrorHandlingConfig(FusionoAuthError.oAuthFail)
    );
  }
}
