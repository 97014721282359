import {
  CurrentSubscriptionActionTypes,
  CurrentSubscriptionActions
} from "../actions/current-subscription.actions";
import { ISubscription } from "../../models/interfaces";

export interface CurrentSubscriptionState {
  data: ISubscription;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CurrentSubscriptionState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CurrentSubscriptionActions
): CurrentSubscriptionState {
  switch (action.type) {
    case CurrentSubscriptionActionTypes.LoadCurrentSubscription: {
      return {
        ...state,
        loading: true
      };
    }
    case CurrentSubscriptionActionTypes.LoadCurrentSubscriptionSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case CurrentSubscriptionActionTypes.LoadCurrentSubscriptionFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
