import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ArticleState } from '../../store/reducers';
import { getRouterUrlBase } from '@fusion/router';
import {
  IArticle,
  getCurrentArticle,
  LoadArticle,
  FusionArticleError
} from '@fusion/article';
import { IErrorHandlingConfig, getErrorHandlingConfig } from '@fusion/error';

@Component({
  selector: 'article-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  currentRootPath$: Observable<string>;
  article$: Observable<IArticle>;
  errorHandlingConfig$: Observable<IErrorHandlingConfig>;

  constructor(private store: Store<ArticleState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadArticle());

    this.currentRootPath$ = this.store.select(getRouterUrlBase);
    this.article$ = this.store.select(getCurrentArticle);
    this.errorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.LoadArticleFail)
    );
  }
}
