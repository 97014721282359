import { UsersActionTypes, UsersActions } from '../actions/users.actions';
import { IUser } from '@fusion/common';

export interface UsersState {
  data: IUser[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: UsersState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: UsersActions
): UsersState {
  switch (action.type) {
    case UsersActionTypes.SearchUsers: {
      return {
        ...state,
        loading: true
      };
    }
    case UsersActionTypes.SearchUsersSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case UsersActionTypes.SearchUsersFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
