import { createSelector } from "@ngrx/store";

import { getFusionoAuthState } from "../reducers";
import { IPermission } from "../../models/interfaces";
import { ICompany } from "@fusion/common";

export const getUserProfileState = createSelector(
  getFusionoAuthState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.userProfile;
  }
);

export const getCompaniesData = createSelector(getUserProfileState, (state) => {
  if (state == null) {
    return null;
  }
  return state.data;
});

export const getEmployerOrganizations = createSelector(
  getCompaniesData,
  (state): ICompany[] => {
    if (state == null) {
      return null;
    }
    return state.employer.map((com) => {
      return {
        ...com.company,
      };
    });
  }
);

export const getEmployerCompanies = createSelector(
  getEmployerOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category !== "Mosque");
  }
);

export const getEmployerMosques = createSelector(
  getEmployerOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category === "Mosque");
  }
);

export const getRepresentativeOrganizations = createSelector(
  getCompaniesData,
  (state): ICompany[] => {
    if (state == null) {
      return null;
    }
    return state.representative.map((com) => {
      return {
        ...com.company,
      };
    });
  }
);

export const getRepresentativeCompanies = createSelector(
  getRepresentativeOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category !== "Mosque");
  }
);

export const getRepresentativeMosques = createSelector(
  getRepresentativeOrganizations,
  (companies): ICompany[] => {
    if (companies == null) {
      return null;
    }
    return companies.filter((company) => company.category === "Mosque");
  }
);

export const getoAuthCompanies = createSelector(
  getEmployerOrganizations,
  getRepresentativeOrganizations,
  (empCompanies, repCompanies): ICompany[] => {
    if (empCompanies == null || repCompanies == null) {
      return null;
    }
    return [...empCompanies, ...repCompanies];
  }
);

export const getSubscriptionPermissions = createSelector(
  getCompaniesData,
  (state): IPermission[] => {
    if (state == null) {
      return null;
    }
    return state.subscriptions;
  }
);

export const getUserPermissions = createSelector(
  getCompaniesData,
  (state): IPermission[] => {
    if (state == null) {
      return null;
    }
    return [...state.employer, ...state.representative, ...state.subscriptions];
  }
);
