<div class="colN">
  <div card class="" *ngFor="let company of companies">
    <fusion-company-card
      [company]="company"
      (notifyViewCompany)="notifyViewCompany.emit($event)"
    >
    </fusion-company-card>
  </div>

  <ng-container *ngIf="isEmployerMode">
    <ng-container *ngIf="!isNavigateMode; else navigateView">
      <div border pointer radius elevation centering class="add-company">
        <fusion-company-add-company
          [isNavigateMode]="isNavigateMode"
          [currentRootPath]="currentRootPath"
          [autoCompleteEntities]="unclaimedCompanies"
          [formValidationRules]="formValidationRules"
          [formConfigs]="formConfigs"
          [formMetadata]="formMetadata"
          (notifyAutocompletion)="notifyAutocompletion.emit($event)"
          (notifyFormSubmit)="notifyFormSubmit.emit($event)"
        ></fusion-company-add-company>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #navigateView>
  <div class="add-company">
    <fusion-company-add-company
      [isNavigateMode]="isNavigateMode"
      [currentRootPath]="currentRootPath"
      [autoCompleteEntities]="unclaimedCompanies"
      [formValidationRules]="formValidationRules"
      [formConfigs]="formConfigs"
      [formMetadata]="formMetadata"
      (notifyAutocompletion)="notifyAutocompletion.emit($event)"
      (notifyFormSubmit)="notifyFormSubmit.emit($event)"
    ></fusion-company-add-company>
  </div>
</ng-template>
