<ng-container *ngIf="appBaseUrls$ | async as appBaseUrls">
  <div *ngIf="topNavigation">
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button
          #responsiveView
          mat-icon-button
          fxShow="true"
          fxHide.gt-sm
          (click)="toggleNav()"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button (click)="onClickOfBrand()">
          <img
            brandLogo
            alt="elitecareer"
            src="/assets/images/users/company-logo/dot2circle-logo-white.svg"
          />
        </button>
        <span pointer class="l-padding-4" (click)="onClickOfBrand()"
          >Dot to Circle</span
        >
        <span class="menu-spacer"></span>
        <div class="menu" fxShow="true" fxHide.lt-md>
          <!-- <a
          *ngFor="let option of topNavigation"
          href="{{ option.path }}"
          mat-button
          >{{ option.title }}</a
        > -->
          <ng-container *ngIf="!(token$ | async); else oauth_user">
            <a mat-button routerLink="/signin">Sign In</a>
            <a mat-button routerLink="/signup">Sign Up</a>
            <!-- <fusion-form-dialog
              [isDialog]="true"
              [formValidationRules]="signInFormValidationRules"
              [formConfigs]="signInFormConfigs"
              [formMetadata]="signInFormMetadata"
              (notifyFormSubmit)="notifySignInFormSubmit($event)"
            ></fusion-form-dialog>
            <fusion-form-dialog
              [isDialog]="true"
              [formValidationRules]="signUpFormValidationRules"
              [formConfigs]="signUpFormConfigs"
              [formMetadata]="signUpFormMetadata"
              (notifyFormSubmit)="notifySignUpFormSubmit($event)"
            ></fusion-form-dialog> -->
          </ng-container>

          <ng-template #oauth_user>
            <button mat-button [matMenuTriggerFor]="menu">
              <div *ngIf="oAuthUser$ | async as user" centering>
                <span extraSmallProfileImage class="r-margin-3">
                  <img
                    imageFit
                    src="{{
                      user.profilePicture ||
                        'assets/images/users/profile-picture/profile.png'
                    }}"
                  />
                </span>
                <span>{{ oAuthUserName$ | async }}</span>
              </div>
            </button>
            <mat-menu #menu="matMenu">
              <a
                *ngIf="authUserProfilePath$ | async as userAppBaseUrl"
                (click)="navigateToUserProfile(userAppBaseUrl)"
                mat-menu-item
              >
                <div *ngIf="oAuthUser$ | async as user" centering>
                  <span extraSmallProfileImage class="r-margin-3">
                    <img
                      imageFit
                      src="{{
                        user.profilePicture ||
                          'assets/images/users/profile-picture/profile.png'
                      }}"
                    />
                  </span>
                  <span>User Profile</span>
                </div>
              </a>
              <ng-container *ngFor="let company of oAuthCompanies$ | async">
                <a
                  (click)="navigateToCompany(appBaseUrls, company)"
                  mat-menu-item
                >
                  <div centering>
                    <span smallLogo class="r-margin-3">
                      <img
                        imageFit
                        src="{{
                          company?.logo ||
                            '/assets/images/users/company-logo/logoPlaceholder.png'
                        }}"
                      />
                    </span>
                    <span>{{ company.name | truncate: 25 }}</span>
                  </div>
                </a>
              </ng-container>
            </mat-menu>
            <a
              class="custom-round-button"
              mat-flat-button
              color="warn"
              (click)="signOut()"
              >Sign Out</a
            >
          </ng-template>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-menu #menu="matMenu">
      <ng-container *ngIf="!(token$ | async); else oauth_user_2">
        <div class="menu" mat-menu-item>
          <a mat-button routerLink="/signin">Sign In</a>
          <!-- <fusion-form-dialog
            [isDialog]="true"
            [iconType]="ModalIconType.None"
            [buttonTitle]="'Sign In'"
            [formValidationRules]="signInFormValidationRules"
            [formConfigs]="signInFormConfigs"
            [formMetadata]="signInFormMetadata"
            (notifyFormSubmit)="notifySignInFormSubmit($event)"
          ></fusion-form-dialog> -->
        </div>
        <div class="menu" mat-menu-item>
          <a mat-button routerLink="/signup">Sign Up</a>
          <!-- <fusion-form-dialog
            [isDialog]="true"
            [iconType]="ModalIconType.None"
            [buttonTitle]="'Sign Up'"
            [formValidationRules]="signUpFormValidationRules"
            [formConfigs]="signUpFormConfigs"
            [formMetadata]="signUpFormMetadata"
            (notifyFormSubmit)="notifySignUpFormSubmit($event)"
          ></fusion-form-dialog> -->
        </div>
      </ng-container>

      <ng-template #oauth_user_2>
        <a
          *ngIf="authUserProfilePath$ | async as userAppBaseUrl"
          (click)="navigateToUserProfile(userAppBaseUrl)"
          mat-menu-item
        >
          <div *ngIf="oAuthUser$ | async as user" centering>
            <span extraSmallProfileImage class="r-margin-3">
              <img
                imageFit
                src="{{
                  user.profilePicture ||
                    'assets/images/users/profile-picture/profile.png'
                }}"
              />
            </span>
            <span>User Profile</span>
          </div>
        </a>
        <ng-container *ngFor="let company of oAuthCompanies$ | async">
          <a (click)="navigateToCompany(appBaseUrls, company)" mat-menu-item>
            <div centering>
              <span smallLogo class="r-margin-3">
                <img
                  imageFit
                  src="{{
                    company?.logo ||
                      '/assets/images/users/company-logo/logoPlaceholder.png'
                  }}"
                />
              </span>
              <span>{{ company.name | truncate: 25 }}</span>
            </div>
          </a>
        </ng-container>
        <span mat-menu-item>
          <mat-icon color="accent">link_off</mat-icon>
          <span secondaryFontColor (click)="signOut()">Sign Out</span>
        </span>
      </ng-template>
    </mat-menu>
  </div>
</ng-container>
