import { Component, OnInit } from '@angular/core';
import { IFormMetadata, IFormConfig, setFormValidationRules } from '@fusion/form';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SignUpForm } from '../../models/forms';
import { Store } from '@ngrx/store';
import { FusionoAuthState } from '../../store/reducers';
import { SignUpStart } from '../../store/actions';
import { IErrorHandlingConfig, getErrorHandlingConfig } from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { Observable } from 'rxjs';
import { IProgressState } from '@fusion/common';
import { getSignUpProgressState } from '../../store/selectors';

@Component({
  selector: 'fusion-oauth-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signUpError$: Observable<IErrorHandlingConfig>;
  signUpState$: Observable<IProgressState>;

  signUpFormMetadata: IFormMetadata = SignUpForm.metadata;
  signUpFormConfigs: IFormConfig[] = SignUpForm.configs;
  signUpFormValidationRules: FormGroup = this.fb.group(
    setFormValidationRules(SignUpForm.configs)
  );

  constructor(
    private store: Store<FusionoAuthState>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.signUpState$ = this.store.select(getSignUpProgressState);
    this.signUpError$ = this.store.select(
      getErrorHandlingConfig(FusionoAuthError.SignUpFail)
    );
  }

  notifySignUpFormSubmit(formData) {
    this.store.dispatch(new SignUpStart(formData));
  }
}
