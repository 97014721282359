import { Injectable } from '@angular/core';
import tinycolor from "tinycolor2";

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export interface ITheme {
  primaryColor: string;
  secondaryColor: string;
  warnColor: string;
  fontFamily?: string;
  fontFamilyHeader?: string;
  logoUrl?: string;
}

export class ThemingServiceConfig {
  primaryHexColorCode: string;
  secondaryHexColorCode: string;
  warnHexColorCode?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  fusionTheme: ITheme = {
    primaryColor: '#660066',
    secondaryColor: '#ff9800',
    warnColor: '#e91e63',
    fontFamily: 'Nunito Sans, sans-serif',
    fontFamilyHeader: 'Montserrat, sans-serif',
    logoUrl: 'https://higgs-qa.kaptest.com/atom_logo_blue.svg'
  };

  colorPalette: Color[] = [];
  primaryColorPalette: Color[] = [];
  secondaryColorPalette: Color[] = [];
  serviceConfig: ThemingServiceConfig;

  constructor(
    config: ThemingServiceConfig
  ) {
    this.serviceConfig = config;
  }

  applyTheme() {
    if (this.serviceConfig) {
      this.fusionTheme = {
        ...this.fusionTheme,
        primaryColor: this.serviceConfig.primaryHexColorCode,
        secondaryColor: this.serviceConfig.secondaryHexColorCode,
        warnColor: this.serviceConfig.warnHexColorCode || this.fusionTheme.warnColor
      };
    }
    this.setNonMaterialThemeColor(this.fusionTheme);
    this.setMaterialPaletteColor('primary', this.fusionTheme.primaryColor);
    this.setMaterialPaletteColor('secondary', this.fusionTheme.secondaryColor);
    this.setMaterialPaletteColor('warn', this.fusionTheme.warnColor);
  }

  setNonMaterialThemeColor(theme: ITheme) {
    document.body.style.setProperty('--primary', theme.primaryColor);
    document.body.style.setProperty('--primary-rgb', this.getRGBValues(theme.primaryColor));
    document.body.style.setProperty('--secondary', theme.secondaryColor);
    document.body.style.setProperty('--secondary-rgb', this.getRGBValues(theme.secondaryColor));
    document.body.style.setProperty('--warn', theme.warnColor);
    document.body.style.setProperty('--warn-rgb', this.getRGBValues(theme.warnColor));
  }

  setMaterialPaletteColor(paletteName: string, colorHex: string) {
    this.colorPalette = this.computeColors(colorHex);
    for (const color of this.colorPalette) {
      const key1 = `--material-palette-${paletteName}-${color.name}`;
      const value1 = color.hex;
      const key2 = `--material-palette-${paletteName}-contrast-${color.name}`;
      const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  computeColors(colorHex: string): Color[] {
    return [
      this.getColorObject(tinycolor(colorHex).lighten(52), '50'),
      this.getColorObject(tinycolor(colorHex).lighten(37), '100'),
      this.getColorObject(tinycolor(colorHex).lighten(26), '200'),
      this.getColorObject(tinycolor(colorHex).lighten(12), '300'),
      this.getColorObject(tinycolor(colorHex).lighten(6), '400'),
      this.getColorObject(tinycolor(colorHex), '500'),
      this.getColorObject(tinycolor(colorHex).darken(6), '600'),
      this.getColorObject(tinycolor(colorHex).darken(12), '700'),
      this.getColorObject(tinycolor(colorHex).darken(18), '800'),
      this.getColorObject(tinycolor(colorHex).darken(24), '900'),
      this.getColorObject(tinycolor(colorHex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(colorHex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(colorHex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(colorHex).lighten(5).saturate(5), 'A700')
    ];
  }

  getColorObject(value: string, name: string): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }

  /**
   * convert hexadecimal or rgb into just num,num,num format so it can be plugged
   * into rgba
   * @param color hex/rgb() string
   */
  getRGBValues(color: string) {
    if (color == null) {
      return null;
    }
    let result = color;
    // hexadecimal regex
    const hexRegex = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i; // full ver
    const hexShortRegex = /^#([a-f\d])([a-f\d])([a-f\d])$/i; // shorthand
    // these do not validate numbers being between 0-255, if they're not then the original input
    // is invalid as well and not the concern here
    const resultRegexString = '\\d{1,3},?\\s*\\d{1,3},?\\s*\\d{1,3}';
    const rgbRegex = new RegExp('^rgb\\((' + resultRegexString + ')\\)$');
    const resultRegex = new RegExp('^' + resultRegexString + '$');
    // Case: hex
    if (color.match(hexShortRegex)) {
      // 3 dit into 6 digit hex
      color = color.replace(
        hexShortRegex,
        (origString, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
      );
    }
    if (color.match(hexRegex)) {
      // 6 digit hex code
      result = color.replace(hexRegex, (p, r, g, b) => {
        // convert to number and join with ,
        return [r, g, b].map(hex => parseInt(hex, 16)).join(', ');
      });
    } else if (color.match(rgbRegex)) {
      // Case: rgb()
      result = color.replace(rgbRegex, (origString, rgb) => {
        return rgb;
      });
    }
    // return null if the result does not match r,g,b format
    return result.match(resultRegex) ? result : null;
  }
}
