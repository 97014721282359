import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICompany } from '@fusion/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'fusion-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  @Output() notifyViewCompany: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyAutocompletion: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() companies: ICompany[];
  @Input() isEmployerMode: boolean;
  @Input() isNavigateMode: boolean;
  @Input() currentRootPath: string;
  @Input() formValidationRules: FormGroup;
  @Input() formConfigs: Object;
  @Input() formMetadata: any;
  @Input() unclaimedCompanies: ICompany[];

  constructor() {}

  ngOnInit() {}
}
