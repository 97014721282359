import { 
  SignUpActionTypes,
  SignUpActions
} from '../actions/sign-up.actions';

export interface SignUpState {
  data: any;
  isInProgress: boolean;
  isSuccess: boolean;
}

export const initialState: SignUpState = {
  data: null,
  isInProgress: false,
  isSuccess: false
};

export function reducer(
  state = initialState,
  action: SignUpActions
): SignUpState {
  switch (action.type) {
    case SignUpActionTypes.SignUpStart: {
      return {
        ...state,
        isInProgress: true
      };
    }
    case SignUpActionTypes.SignUpSuccess: {
      return {
        ...state,
        isInProgress: false,
        isSuccess: true,
        data: action.payload
      };
    }
    case SignUpActionTypes.SignUpFail: {
      return {
        ...state,
        isInProgress: false,
        isSuccess: false
      };
    }
  }
  return state;
}
