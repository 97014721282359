import { createSelector } from "@ngrx/store";

import { getFusionCompanyState } from "../reducers";
import { UnclaimedCompaniesState } from "../reducers/unclaimed-companies.reducer";
import { ICompany } from "../../models/interfaces";

export const getUnclaimedCompaniesState = createSelector(
  getFusionCompanyState,
  (state): UnclaimedCompaniesState => {
    if (state == null) {
      return null;
    }
    return state.unclaimedCompanies;
  }
);

export const getUnclaimedCompanies = createSelector(
  getUnclaimedCompaniesState,
  (state): ICompany[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
