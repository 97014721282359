import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { IErrorHandlingConfig } from '@fusion/error';
import { IFormConfig, IFormMetadata } from '../../models/interfaces';
import { IProgressState } from '@fusion/common';

@Component({
  selector: 'fusion-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTemplateComponent implements OnInit {
  @Output() notifyFormValidationUpdate = new EventEmitter<boolean>();
  @Output() notifyFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  notifyAutocompletion: EventEmitter<string> = new EventEmitter<string>();
  @Input() dialogRef: MatDialogRef<any> | null;
  @Input() isDialog: boolean;
  @Input() autoCompleteEntities: any;
  @Input() entityType: string;
  @Input() formValidationRules: FormGroup;
  @Input() formConfigs: IFormConfig[];
  @Input() formMetadata: IFormMetadata;
  @Input() error: IErrorHandlingConfig;
  @Input() progressState: IProgressState;
  role: string[];

  constructor() {}

  ngOnInit() {}

  formClose() {
    // TODO emit event to notify form close
  }
}
