import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { IPublication } from '../../models/interfaces';

export const getPublicationsState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.publications;
  }
);

export const getPublications = createSelector(
  getPublicationsState,
  (publication): IPublication[] => {
    if (publication == null) {
      return null;
    }
    return publication.data;
  }
);
