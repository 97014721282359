import { ISort } from '../interfaces/sort.interface';

export const sortOption: ISort = {
  title: 'Sort By',
  optionOne: {
    title: 'Recent Date',
    value: 'desc'
  },
  optionTwo: {
    title: 'Older Date',
    value: 'asc'
  }
};
