export const CAREER_SUMMARY_FORM = "[Form] addCareerSummary";
export const CERTIFICATION_FORM = "[Form] addCertification";
export const COMPANY_FORM = "[Form] addCompany";
export const CONTACT_US_FORM = "[Form] addContactUs";
export const COVER_LETTER_FORM = "[Form] addCoverLetter";
export const EDUCATION_FORM = "[Form] addEducation";
export const EDUCATION_UPDATE_FORM = "[Form] updateEducation";
export const EXPERIENCE_FORM = "[Form] addExperience";
export const EXPERIENCE_UPDATE_FORM = "[Form] updateExperience";
export const JOB_SEARCH_FORM = "[Form] jobSearch";
export const JOB_SEARCH_OPTIONS_FORM = "[Form] jobSearchOptions";
export const JOB_SUBMISSION_FORM = "[Form] jobSubmission";
export const PROJECT_FORM = "[Form] addProject";
export const PROJECT_UPDATE_FORM = "[Form] updateProject";
export const PUBLICATION_FORM = "[Form] addPublication";
export const PUBLICATION_UPDATE_FORM = "[Form] updatePublication";
export const REPRESENTATIVE_FORM = "[Form] addRepresentative";
export const SEARCH_COMPANY_FORM = "[Form] searchCompany";
export const SIGN_IN_FORM = "[Form] signIn";
export const SIGN_UP_FORM = "[Form] signUp";
export const UPDATE_USER_PROFILE_FORM = "[Form] updateUserProfile";
export const SKILL_FORM = "[Form] addSkill";
export const ADDRESS_FORM = "[Form] address";
export const PROPERTY_DETAIL_FORM = "[Form] propertyDetail";
export const FAMILY_PREFERENCES_FORM = "[Form] familyPreferences";
export const LEASE_TERMS_FORM = "[Form] leaseTerms";
export const AMENITIES_FORM = "[Form] amenities";
export const COMMUNITY_SERVICES_FORM = "[Form] communityServices";
export const SHARED_SERVICES_FORM = "[Form] sharedServices";
export const INCLUDED_UTILITIES_FORM = "[Form] includedUtilities";
export const PET_POLICY_FORM = "[Form] petPolicy";
export const SMOKING_POLICY_FORM = "[Form] smokingPolicy";
export const PROPERTY_DATE_FORM = "[Form] propertyDate";
export const PROPERTY_DESCRIPTION_FORM = "[Form] propertyDescription";
export const HOMERENT_SEARCH_FORM = "[Form] homerentSearch";
export const PERMISSION_FORM = "[Form] Permission";
