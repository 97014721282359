import { 
  PrivateArticlesActionTypes,
  PrivateArticlesActions
} from '../actions/private-articles.actions';
import { IArticle } from '../../models/interfaces';

export interface PrivateArticlesState {
  data: IArticle[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: PrivateArticlesState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: PrivateArticlesActions
): PrivateArticlesState {
  switch (action.type) {
    case PrivateArticlesActionTypes.LoadPrivateArticles: {
      return {
        ...state,
        loading: true
      };
    }
    case PrivateArticlesActionTypes.LoadPrivateArticlesSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case PrivateArticlesActionTypes.LoadPrivateArticlesFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
