import { IProfileNav } from '../interfaces/profile-nav.interface';

export const companyProfileNav: IProfileNav[] = [
  {
    title: 'Company Profile',
    path: '',
    icon: 'person',
    active: true
  },
  {
    title: 'My Subscriptions',
    path: 'subscriptions',
    icon: 'library_books',
    active: false
  },
  {
    title: 'Subscribe',
    path: 'subscribe',
    icon: 'library_add',
    active: false
  },
  // {
  //   title: 'Notifications',
  //   path: 'notification',
  //   icon: 'notifications_none',
  //   isBadged: true,
  //   active: false
  // },
  // {
  //   title: 'Post Job',
  //   path: 'postjob',
  //   icon: 'work_outline',
  //   active: false
  // },
  // {
  //   title: 'Job Applications',
  //   path: 'jobapplication',
  //   icon: 'assignment_ind',
  //   active: false
  // },
  // {
  //   title: 'Add Services',
  //   path: 'addservice',
  //   icon: 'group_work',
  //   active: false
  // },
  {
    title: 'Representatives',
    path: 'representative',
    icon: 'people_outline',
    active: false
  },
  {
    title: 'Settings',
    path: 'settings',
    icon: 'settings',
    active: false
  }
];
