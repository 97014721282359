import * as constants from "../constants";
import {
  companyTypes,
  companyCategories,
  noOfEmployees,
  countries
} from "../data";
import { IForm } from "../interfaces";
import { FormFieldType, FormFieldGroup } from "../enums";

export const CompanyForm: IForm = {
  metadata: {
    name: constants.COMPANY_FORM,
    title: "Add Company",
    submitButtonText: "add/claim company",
    isToggleButton: true,
    style: {
      topMargin: "0px",
      maxWidth: "740px",
      maxHeight: "550px"
    }
  },
  configs: [
    {
      label: "Company Name",
      name: "name",
      type: FormFieldType.Autocomplete,
      placeholder: "Company Name",
      autoFillItems: [
        "category",
        "website",
        "type",
        "founded",
        "noOfEmployees",
        "phoneNo",
        "companyDetail",
        "address",
        "city",
        "zipCode",
        "state",
        "country"
      ],
      validation: {
        required: true,
        min: 2,
        max: 30
      }
    },
    {
      label: "Company Type",
      name: "type",
      type: FormFieldType.Dropdown,
      options: companyTypes,
      validation: {
        required: true
      }
    },
    {
      label: "Company Category",
      name: "category",
      type: FormFieldType.Dropdown,
      options: companyCategories,
      validation: {
        required: true
      }
    },
    {
      label: "No of Employees",
      name: "noOfEmployees",
      type: FormFieldType.Dropdown,
      options: noOfEmployees,
      validation: {
        required: true
      }
    },
    {
      label: "Date Founded",
      name: "founded",
      type: FormFieldType.Date,
      placeholder: "YYYY-MM-DD",
      examplePattern: "1985-12-30",
      validation: {
        required: true
      }
    },
    // company details - start
    {
      label: "Company Overview",
      name: "companyDetail",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: true,
        min: 10,
        max: 5000
      }
    },
    // company details - ends
    {
      label: "Phone #",
      name: "phoneNo",
      type: FormFieldType.Phone,
      placeholder: "(347) 123-1234",
      validation: {
        required: true,
        min: 2,
        max: 30
      }
    },
    {
      label: "Website",
      name: "website",
      type: FormFieldType.Url,
      placeholder: "http://elitecareer.net",
      examplePattern: "http://elitecareer.net",
      validation: {
        required: true,
        min: 2,
        max: 30,
        pattern:
          "^((https?|http|ftp|smtp)://)(www.)?[a-z0-9]+.[a-z]+(/[a-zA-Z0-9#]+/?)*$"
      }
    },
    {
      type: FormFieldType.Group,
      group: FormFieldGroup.Address,
      configs: [
        {
          label: "Address",
          name: "address",
          type: FormFieldType.Text,
          validation: {
            required: true,
            max: 50
          }
        },
        {
          label: "Unit#",
          name: "unit",
          type: FormFieldType.Text,
          validation: {
            required: false,
            max: 10
          }
        },
        {
          label: "City",
          name: "city",
          type: FormFieldType.Text,
          placeholder: "city",
          validation: {
            required: true,
            max: 30
          }
        },
        {
          label: "State",
          name: "state",
          type: FormFieldType.Text,
          validation: {
            required: false
          }
        },
        {
          label: "Zip Code",
          name: "zipCode",
          type: FormFieldType.Text,
          placeholder: "zip code",
          examplePattern: "11432",
          validation: {
            required: true,
            max: 10,
            pattern: "[0-9]+"
          }
        },
        {
          label: "Country",
          name: "country",
          type: FormFieldType.Dropdown,
          options: countries,
          validation: {
            required: true
          }
        }
      ]
    }
  ]
};
