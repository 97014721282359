import { Component, OnInit, Input } from '@angular/core';
import { IFormConfig } from '../../models/interfaces';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'fusion-form-job-metadata',
  templateUrl: './job-metadata.component.html',
  styleUrls: ['./job-metadata.component.scss']
})
export class JobMetadataComponent implements OnInit {
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {}

  getRequiredConfig(configs: any, options: string[]) {
    return configs.filter((config: any) => {
      if (options.length && options.indexOf(config.name) > -1) {
        return config;
      }
    });
  }
}
