import { Action } from "@ngrx/store";
import { IEducation } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum EducationsActionTypes {
  SetEducations = "[Educations] Set",
  AddEducations = "[Educations] Add",
  AddEducationsSuccess = "[Educations] Add Success",
  AddEducationsFail = "[Educations] Add Fail",
  UpdateEducations = "[Educations] Update",
  UpdateEducationsSuccess = "[Educations] Update Success",
  UpdateEducationsFail = "[Educations] Update Fail",
  DeleteEducations = "[Educations] Delete",
  DeleteEducationsSuccess = "[Educations] Delete Success",
  DeleteEducationsFail = "[Educations] Delete Fail"
}

export class SetEducations implements Action {
  readonly type = EducationsActionTypes.SetEducations;

  constructor(public payload: IEducation[]) {}
}

export class AddEducations implements Action {
  readonly type = EducationsActionTypes.AddEducations;

  constructor(public payload: IEducation[]) {}
}

export class AddEducationsSuccess implements Action {
  readonly type = EducationsActionTypes.AddEducationsSuccess;

  constructor(public payload: any) {}
}

export class AddEducationsFail implements Action {
  readonly type = EducationsActionTypes.AddEducationsFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class UpdateEducations implements Action {
  readonly type = EducationsActionTypes.UpdateEducations;

  constructor(public payload: IEducation[]) {}
}

export class UpdateEducationsSuccess implements Action {
  readonly type = EducationsActionTypes.UpdateEducationsSuccess;

  constructor(public payload: any) {}
}

export class UpdateEducationsFail implements Action {
  readonly type = EducationsActionTypes.UpdateEducationsFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class DeleteEducations implements Action {
  readonly type = EducationsActionTypes.DeleteEducations;

  constructor(public payload: string) {}
}

export class DeleteEducationsSuccess implements Action {
  readonly type = EducationsActionTypes.DeleteEducationsSuccess;

  constructor(public payload: any) {}
}

export class DeleteEducationsFail implements Action {
  readonly type = EducationsActionTypes.DeleteEducationsFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type EducationsActions =
  | SetEducations
  | AddEducations
  | AddEducationsSuccess
  | AddEducationsFail
  | UpdateEducations
  | UpdateEducationsSuccess
  | UpdateEducationsFail
  | DeleteEducations
  | DeleteEducationsSuccess
  | DeleteEducationsFail;
