import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromJobseekerProfile from './jobseeker-profile.reducer';
import * as fromEducations from './educations.reducer';
import * as fromExperiences from './experiences.reducer';
import * as fromProjects from './projects.reducer';
import * as fromPublications from './publications.reducer';
import * as fromSkills from './skills.reducer';
import * as fromRating from './rating.reducer';

export interface FusionCareerProfileState {
  jobseekerProfile: fromJobseekerProfile.JobseekerProfileState;
  educations: fromEducations.EducationsState;
  experiences: fromExperiences.ExperiencesState;
  projects: fromProjects.ProjectsState;
  publications: fromPublications.PublicationsState;
  skills: fromSkills.SkillsState;
  rating: fromRating.RatingState;
}

export const reducers: ActionReducerMap<FusionCareerProfileState> = {
  jobseekerProfile: fromJobseekerProfile.reducer,
  educations: fromEducations.reducer,
  experiences: fromExperiences.reducer,
  projects: fromProjects.reducer,
  publications: fromPublications.reducer,
  skills: fromSkills.reducer,
  rating: fromRating.reducer
};

export const getFusionCareerProfileState = createFeatureSelector<
  FusionCareerProfileState
>('fusionCareerProfile');

// export reducer state interfaces
