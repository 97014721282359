import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment';

const moment = moment_;

@Pipe({
  name: 'fancyTime'
})
export class FancyTimePipe implements PipeTransform {
  transform(startDate: any, option?: any, endDate?: any): any {
    let message = '';
    if (option === 'duration') {
      var a = moment(endDate ? endDate : new Date(), 'YYYY-MM-DD');
      var b = moment(startDate, 'YYYY-MM-DD');

      // years
      var years = a.diff(b, 'year');
      b.add(years, 'years');
      message = years > 1 ? `${years} yrs` : years == 1 ? `${years} yr` : '';

      // months
      var months = a.diff(b, 'months');
      b.add(months, 'months');
      message +=
        months > 1 ? ` ${months} mos` : months < 2 ? ` ${months} mo` : '';

      // days
      var days = a.diff(b, 'days');

      return message;
    } else {
      return moment(startDate, 'YYYY-MM-DD HH:mm:ss ZZ').fromNow();
    }
  }
}
