import { IProfileNav } from '../interfaces/profile-nav.interface';

export const homeRentNav: IProfileNav[] = [
  {
    title: 'Search Property',
    path: 'search',
    icon: 'search',
    active: true
  },
  {
    title: 'Add Property',
    path: 'addproperty',
    icon: 'add_to_queue',
    active: false
  },
  // {
  //   title: 'My Tour Requests',
  //   path: 'mytour',
  //   icon: 'directions_walk',
  //   active: false
  // },
  {
    title: 'My Properties',
    path: 'properties',
    icon: 'view_list',
    active: false
  },
  {
    title: 'Pending Orders',
    path: 'pendingorders',
    icon: 'send',
    active: false
  },
  {
    title: 'Transactions',
    path: 'transactions',
    icon: 'credit_card',
    active: false
  }
  // {
  //   title: 'Notifications',
  //   path: 'notifications',
  //   icon: 'notifications_none',
  //   isBadged: true,
  //   active: false
  // },
  // {
  //   title: 'Settings',
  //   path: 'settings',
  //   icon: 'settings',
  //   active: false
  // }
];
