import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  switchMap,
  map,
  withLatestFrom
} from 'rxjs/operators';

import {
  PrivateArticlesActionTypes,
  LoadPrivateArticlesSuccess,
  LoadPrivateArticlesFail,
  LoadPrivateArticles
} from '../actions/private-articles.actions';
import { FusionArticleState } from '../reducers';
import { Store } from '@ngrx/store';
import { ArticleService, MappingService, mappingType } from '@fusion/service';
import { getRouterParams } from '@fusion/router';
import { Params } from '@angular/router';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from '@fusion/error';
import { FusionArticleError } from '../../models/enums';

@Injectable()
export class PrivateArticlesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionArticleState>,
    private articleService: ArticleService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<LoadPrivateArticles>(PrivateArticlesActionTypes.LoadPrivateArticles),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getRouterParams)),
    mergeMap(([payload, params]: [boolean, Params]) => {
      let errorPayload: IError<FusionArticleError> = {
        code: FusionArticleError.LoadPrivateArticlesFail,
        source: ErrorSource.Validation,
        data: null
      };
      const authorId = params.subscriberId;
      return this.articleService.getArticlesByAuthor(authorId, payload).pipe(
        switchMap((dataResult) => {
          const mappedData = this.mappingService.getMappedData(
            dataResult,
            mappingType.camelize
          );
          return [new LoadPrivateArticlesSuccess(mappedData)];
        }),
        catchError((error) => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Template,
              message:
                'Sorry, we are having some issue loading your articles. Please try again later.',
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new LoadPrivateArticles(payload)],
                  title: 'Retry'
                }
              }
            }
          };
          return of(new LoadPrivateArticlesFail(errorPayload));
        })
      );
    })
  );
}
