import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from "rxjs/operators";

import {
  UserActionTypes,
  UpdateUserSuccess,
  UpdateUserFail,
  UpdateUser,
  UploadProfilePicture,
  UploadProfilePictureSuccess,
  UploadProfilePictureFail,
  UploadProfileBanner,
  UploadProfileBannerSuccess,
  UploadProfileBannerFail
} from "../actions/user.actions";
import { IUser, IFilePayload } from "@fusion/common";
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType
} from "@fusion/error";
import { FusionoAuthError } from "../../models/enums";
import { MappingService, mappingType, UserService } from "@fusion/service";
import { TokenRefresh } from "../actions";
import { getRouterParams } from "@fusion/router";
import { Params } from "@angular/router";
import { Store } from "@ngrx/store";
import { FusionoAuthState } from "../reducers";
import { getoAuthUserId } from '../selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store<FusionoAuthState>,
    private userService: UserService,
    private mappingService: MappingService
  ) {}

  @Effect()
  effect$ = this.actions$.pipe(
    ofType<UpdateUser>(UserActionTypes.UpdateUser),
    map(action => action.payload),
    mergeMap((payload: IUser) => {
      let errorPayload: IError<FusionoAuthError> = {
        code: FusionoAuthError.UpdateUserFail,
        source: ErrorSource.Validation,
        data: null
      };

      const userId = payload.id;
      const mappedUser = this.mappingService.getMappedData<any>(
        payload,
        mappingType.underscore
      );
      return this.userService.updateUser(mappedUser, userId).pipe(
        switchMap(dataResult => {
          return [new UpdateUserSuccess(dataResult), new TokenRefresh()];
        }),
        catchError(error => {
          errorPayload = {
            ...errorPayload,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Dialog,
              message:
                "Sorry, we are having some issue updating your profile. Please try again later.",
              action: {
                primary: {
                  type: ErrorActionType.Dispatch,
                  reference: [new UpdateUser(payload)],
                  title: "Retry"
                }
              }
            }
          };
          return of(new UpdateUserFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  uploadImage$ = this.actions$.pipe(
    ofType<UploadProfilePicture>(UserActionTypes.UploadProfilePicture),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    switchMap(([imagePayload, userId]: [IFilePayload, string]) => {
      return this.userService.uploadPicture(userId, imagePayload).pipe(
        switchMap(dataResult => {
          return [new UploadProfilePictureSuccess(), new TokenRefresh()];
        })
      );
    }),
    catchError(error => of(new UploadProfilePictureFail()))
  );

  @Effect()
  uploadProfileBannerImage$ = this.actions$.pipe(
    ofType<UploadProfileBanner>(UserActionTypes.UploadProfileBanner),
    map(action => action.payload),
    withLatestFrom(this.store.select(getoAuthUserId)),
    switchMap(([imagePayload, userId]: [IFilePayload, string]) => {
      return this.userService.uploadPicture(userId, imagePayload).pipe(
        switchMap(dataResult => {
          return [new UploadProfileBannerSuccess(), new TokenRefresh()];
        })
      );
    }),
    catchError(error => of(new UploadProfileBannerFail()))
  );
}
