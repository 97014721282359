import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.article.host;
    this.api = this.configService.getConfig().services.article.api;
  }

  getArticleById(articleId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/articles/${articleId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getArticlesByAuthor(authorId: string, active: boolean = true): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/articles/author/${authorId}?active=${active}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postArticle(article: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/articles`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, article, options);
  }

  updateArticle(articleId: string, article: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/articles/${articleId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, article, options);
  }

  getArticles(): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/articles`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }
}
