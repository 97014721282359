import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import { switchMap, map, tap } from "rxjs/operators";
import { FusionoAuthState } from "../reducers";
import { getoAuthDecodedToken, getHomeAppBaseUrl } from "../selectors";
import { RedirectToHomeApp } from "../actions";

@Injectable({
  providedIn: "root"
})
export class OauthRouteGuard implements CanActivate {
  constructor(private store: Store<FusionoAuthState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(getoAuthDecodedToken).pipe(
      switchMap(user => {
        if (!user) {
          this.store.dispatch(new RedirectToHomeApp());
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}
