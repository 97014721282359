import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IApplication } from "../../models/interfaces";

@Component({
  selector: "fusion-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"]
})
export class SubscriptionComponent implements OnInit {
  @Input() applications: IApplication[];
  @Input() isOnlySubscriptions: boolean;
  @Output() notifyViewApplication: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifySubscribe: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
