import { NgModule } from '@angular/core';
import { FusionRouterComponent } from './fusion-router.component';
import { StoreModule } from '@ngrx/store';
import { reducers, CustomSerializer } from './store/reducers';
import {
  RouterStateSerializer,
  StoreRouterConnectingModule, DefaultRouterStateSerializer
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './store';

@NgModule({
  declarations: [FusionRouterComponent],
  imports: [
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    StoreModule.forFeature('fusionRouter', reducers),
    EffectsModule.forFeature(effects),
  ],
  exports: [FusionRouterComponent],
  providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }]
})
export class FusionRouterModule {}
