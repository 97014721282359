import { Action } from "@ngrx/store";
import { IUser, IFilePayload } from "@fusion/common";
import { IError } from "@fusion/error";
import { FusionoAuthError } from "../../models/enums";

export enum UserActionTypes {
  UpdateUser = "[User] Update",
  UpdateUserSuccess = "[User] Update Success",
  UpdateUserFail = "[User] Update Fail",
  UploadProfilePicture = "[User] Upload Profile Picture",
  UploadProfilePictureSuccess = "[User] Upload Profile Picture Success",
  UploadProfilePictureFail = "[User] Upload Profile Picture Fail",
  UploadProfileBanner = "[User] Upload Profile Banner",
  UploadProfileBannerSuccess = "[User] Upload Profile Banner Success",
  UploadProfileBannerFail = "[User] Upload Profile Banner Fail"
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor(public payload: IUser) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserSuccess;

  constructor(public payload: any) {}
}

export class UpdateUserFail implements Action {
  readonly type = UserActionTypes.UpdateUserFail;

  constructor(public payload: IError<FusionoAuthError>) {}
}

export class UploadProfilePicture implements Action {
  readonly type = UserActionTypes.UploadProfilePicture;

  constructor(public payload: IFilePayload) {}
}

export class UploadProfilePictureSuccess implements Action {
  readonly type = UserActionTypes.UploadProfilePictureSuccess;
}

export class UploadProfilePictureFail implements Action {
  readonly type = UserActionTypes.UploadProfilePictureFail;
}

export class UploadProfileBanner implements Action {
  readonly type = UserActionTypes.UploadProfileBanner;

  constructor(public payload: IFilePayload) {}
}

export class UploadProfileBannerSuccess implements Action {
  readonly type = UserActionTypes.UploadProfileBannerSuccess;
}

export class UploadProfileBannerFail implements Action {
  readonly type = UserActionTypes.UploadProfileBannerFail;
}

export type UserActions =
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail
  | UploadProfilePicture
  | UploadProfilePictureSuccess
  | UploadProfilePictureFail
  | UploadProfileBanner
  | UploadProfileBannerSuccess
  | UploadProfileBannerFail;
