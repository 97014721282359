import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { take, tap } from "rxjs/operators";
import { getJobseekerProfile } from "../selectors";
import { LoadJobseekerProfile } from "../actions";
import { FusionCareerProfileState } from "../reducers";

@Injectable({
  providedIn: "root"
})
export class LoadJobseekerProfileResolver implements Resolve<any> {
  constructor(private store: Store<FusionCareerProfileState>) {}

  resolve(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.store
      .select(getJobseekerProfile)
      .pipe(
        tap(jobseeker => {
          if (!jobseeker) {
            this.store.dispatch(new LoadJobseekerProfile());
          }
        }),
        take(1)
      )
      .subscribe(() => {
        return true;
      });
  }
}
