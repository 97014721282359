import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IUser, IFilePayload } from "@fusion/common";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";

@Injectable({
  providedIn: "root"
})
export class UserService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.user.host;
    this.api = this.configService.getConfig().services.user.api;
  }

  postImage(userId: string, imageRaw: IFilePayload): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}/image`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, imageRaw, options);
  }

  getUser(userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  updateUser(userBody, userId: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, userBody, options);
  }

  searchUsers(keywords: string): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users?keywords=${keywords}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  uploadPicture(userId: string, imagePayload: any): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/users/${userId}/images`;
    const options = this.requestOptionsService.generateOptions(false, true);

    const formData = new FormData();
    formData.append("file", imagePayload.blob);
    formData.append("name", imagePayload.name);
    formData.append("type", imagePayload.type);
    formData.append("reference", imagePayload.reference);
    return this._http.post(fullUrl, formData, options);
  }
}
