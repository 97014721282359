import { Component, OnInit, Input } from '@angular/core';
import { IArticle } from '../../models/interfaces';

@Component({
  selector: 'fusion-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {
  @Input() articles: IArticle[];
  @Input() currentRootPath: string;
  @Input() parentDir: string;

  constructor() {}

  ngOnInit() {}

  getPreviewDir(articleId) {
    if (this.parentDir && this.parentDir.length > 0) {
      return `${this.currentRootPath}/${this.parentDir}/${articleId}`;
    }
    return `${this.currentRootPath}/${articleId}`;
  }
}
