import { NgModule } from "@angular/core";
import { SubscriptionListComponent } from "./containers/subscription-list/subscription-list.component";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { FusionCommonModule } from "@fusion/common";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FusionPipeModule } from "@fusion/pipe";
import { FusionMaterialModule } from "@fusion/material";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers } from "./store/reducers/index";
import { effects } from "./store/effects/index";
// import { FusionCareerProfileModule } from '@fusion/career-profile';
import { guards } from "./store/guards/index";

@NgModule({
  declarations: [SubscriptionListComponent, SubscriptionComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    FusionCommonModule,
    FusionPipeModule,
    FusionMaterialModule,
    // FusionCareerProfileModule,
    StoreModule.forFeature("fusionSubscription", reducers),
    EffectsModule.forFeature(effects)
  ],
  exports: [SubscriptionListComponent],
  providers: [guards]
})
export class FusionSubscriptionModule {}
