import { Action } from '@ngrx/store';

export enum AppRouterActionTypes {
  Navigate = '[Router] Navigate',
}

export class Navigate implements Action {
  readonly type = AppRouterActionTypes.Navigate;

  constructor(public payload: string) {}
}

export type AppRouterActions = Navigate;
