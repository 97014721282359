<div pageSpacing largePage pageBody>
  <div pageTitle><h1>My Articles!</h1></div>
  <div pageContent>
    <div content>
      <ng-container
        *ngIf="
          errorHandlingConfig$ | async as errorHandlingConfig;
          else articleList
        "
      >
        <ng-template [fusionErrorHandler]="errorHandlingConfig"></ng-template>
      </ng-container>
      <ng-template #articleList>
        <fusion-article-list
          [articles]="articles$ | async"
          [currentRootPath]="currentRootPath$ | async"
          [parentDir]="'myarticles'"
        ></fusion-article-list>
      </ng-template>
    </div>
    <div adSpace></div>
  </div>
</div>
