import { Action } from '@ngrx/store';
import { IError } from '@fusion/error';
import { FusionArticleError } from '../../models/enums';

export enum PrivateArticlesActionTypes {
  LoadPrivateArticles = '[Private Articles] Load',
  LoadPrivateArticlesSuccess = '[Private Articles] Load Success',
  LoadPrivateArticlesFail = '[Private Articles] Load Fail'
}

export enum PrivateArticlesErrorActionTypes {
  LoadPrivateArticlesFail = '[Private Articles] Load Fail'
}

export class LoadPrivateArticles implements Action {
  readonly type = PrivateArticlesActionTypes.LoadPrivateArticles;

  constructor(public payload: boolean) {}
}

export class LoadPrivateArticlesSuccess implements Action {
  readonly type = PrivateArticlesActionTypes.LoadPrivateArticlesSuccess;

  constructor(public payload: any) {}
}

export class LoadPrivateArticlesFail implements Action {
  readonly type = PrivateArticlesActionTypes.LoadPrivateArticlesFail;

  constructor(public payload: IError<FusionArticleError>) {}
}

export type PrivateArticlesActions =
  | LoadPrivateArticles
  | LoadPrivateArticlesSuccess
  | LoadPrivateArticlesFail;
