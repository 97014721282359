import { createSelector } from "@ngrx/store";

import { getFusionCompanyState } from "../reducers";
import { ICompany } from "../../models/interfaces";

export const getCompaniesState = createSelector(
  getFusionCompanyState,
  state => {
    if (state == null) {
      return null;
    }
    return state.companies;
  }
);

export const getCompanies = createSelector(
  getCompaniesState,
  (state): ICompany[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

// export const getCompanies = createSelector(
//   getCompanyEntities,
//   (entities): ICompany[] => {
//     if (entities == null) {
//       return null;
//     }
//     return Object.keys(entities).map((id) => entities[id]);
//   }
// );

// export const getCurrentCompany = createSelector(
//   getCompanyEntities,
//   getRouterParams,
//   (entities, params) => {
//     if (entities == null || params == null) {
//       return null;
//     }
//     return entities[params.companyId];
//   }
// );

// export const getCurrentCompanySummary = createSelector(
//   getCurrentCompany,
//   (company): ICompanySummary => {
//     if (company == null) {
//       return null;
//     }
//     return {
//       type: company.type,
//       category: company.category,
//       website: company.website,
//       address: company.address,
//       city: company.city,
//       state: company.state,
//       country: company.country,
//       zipCode: company.zip_code
//     };
//   }
// );
