import { oAuthEffects } from "./oauth.effects";
import { UserProfileEffects } from "./user-profile.effects";
import { SignUpEffects } from "./sign-up.effects";
import { ApplicationsEffects } from "./applications.effects";
import { ErrorEffects } from "./error.effects";
import { UserEffects } from "./user.effects";

// include all effects
export const effects: any[] = [
  oAuthEffects,
  UserProfileEffects,
  SignUpEffects,
  ApplicationsEffects,
  ErrorEffects,
  UserEffects
];

// export from effects
