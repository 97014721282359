import { Injectable } from "@angular/core";
import {
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";
import { FusionoAuthState } from "../reducers";
import { getoAuthDecodedToken } from "../selectors";
import { switchMap } from "rxjs/operators";
import { RedirectToHomeApp } from "../actions";

@Injectable({
  providedIn: "root",
})
export class OauthUserGuard implements CanActivateChild {
  constructor(private store: Store<FusionoAuthState>) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(getoAuthDecodedToken).pipe(
      switchMap((userTokenData) => {
        const userId = next.params.userId;
        if (
          !userTokenData ||
          !userTokenData.user ||
          userTokenData.user.id !== userId
        ) {
          this.store.dispatch(new RedirectToHomeApp());
          return of(false);
        } else {
          return of(true);
        }
      })
    );
  }
}
