import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { FusionCompanyState } from "../reducers/index";
import { getCompany } from "../selectors";
import { take, tap } from "rxjs/operators";
import { LoadCompany } from "../actions";

@Injectable({
  providedIn: "root"
})
export class LoadCompanyResolver implements Resolve<any> {
  constructor(private store: Store<FusionCompanyState>) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.store
      .select(getCompany)
      .pipe(
        tap(company => {
          if (!company) {
            this.store.dispatch(new LoadCompany());
          }
        }),
        take(1)
      )
      .subscribe(com => {
        return true;
      });
  }
}
