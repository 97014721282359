import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

// rxjs
import { of } from 'rxjs';
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as moment_ from 'moment';
import * as jwt from 'jsonwebtoken';

import * as fromActions from '../actions/index';
import { FusionoAuthState } from '../reducers/index';
import { Store } from '@ngrx/store';
import {
  TokenService,
  CookieService,
  ConfigService,
  MappingService,
  mappingType,
} from '@fusion/service';
import { IoAuthCredentials, IUser } from '@fusion/common';
import {
  getApplications,
  getHomeAppBaseUrl,
  getoAuthSessionToken,
  getoAuthUserProfilePath,
} from '../selectors';
import {
  RedirectToHomeApp,
  TokenDecodeFail,
  SessionRestoreFail,
  RedirectToUserProfileApp,
  oAuthFail,
} from '../actions/index';
import {
  IError,
  ErrorSource,
  ErrorHandlingType,
  ErrorActionType,
} from '@fusion/error';
import { FusionoAuthError } from '../../models/enums';
import { IoAuthSession } from '../../models/interfaces';
import { Router } from '@angular/router';

const moment = moment_;

@Injectable()
export class oAuthEffects {
  constructor(
    private store: Store<FusionoAuthState>,
    private actions$: Actions,
    private tokenService: TokenService,
    private cookieService: CookieService,
    private configService: ConfigService,
    private mappingService: MappingService,
    private router: Router
  ) {}

  @Effect()
  getUserToken$ = this.actions$.pipe(
    ofType<fromActions.oAuthStart>(fromActions.oAuthActionTypes.oAuthStart),
    map((action) => action.payload),
    mergeMap((userCredential: IoAuthCredentials) => {
      return this.tokenService.getUserToken(userCredential).pipe(
        switchMap((dataResult) => {
          const decodedToken = jwt.decode(dataResult.token);
          const expiration = this.getDays(
            moment.unix(decodedToken.exp),
            moment.unix(decodedToken.iat)
          );
          const session: IoAuthSession = {
            token: dataResult.token,
            expiration: expiration,
          };

          return [
            new fromActions.TokenDecode(dataResult.token),
            new fromActions.SessionStart(session),
            new fromActions.oAuthSuccess(session),
            new fromActions.RedirectToUserProfileApp(),
          ];
        }),
        catchError((error) => {
          const invalidCredentials = `Invalid email or password!`;
          const code = error.status;
          const errorPayload: IError<FusionoAuthError> = {
            code: FusionoAuthError.oAuthFail,
            source: ErrorSource.API,
            data: error,
            config: {
              type: ErrorHandlingType.Message,
              message: code === 404 ? invalidCredentials : 'Sorry, we are having some issue authenticating your account. Please try again later.',
            },
          };
          return of(new oAuthFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  refreshUserToken$ = this.actions$.pipe(
    ofType<fromActions.TokenRefresh>(fromActions.oAuthActionTypes.TokenRefresh),
    withLatestFrom(this.store.select(getoAuthSessionToken)),
    mergeMap(([action, userToken]: [any, string]) => {
      return this.tokenService.getRefreshToken(userToken).pipe(
        switchMap((dataResult) => {
          const decodedToken = jwt.decode(dataResult.token);
          const expiration = this.getDays(
            moment.unix(decodedToken.exp),
            moment.unix(decodedToken.iat)
          );
          const session: IoAuthSession = {
            token: dataResult.token,
            expiration: expiration,
          };

          return [
            new fromActions.TokenDecode(dataResult.token),
            new fromActions.SessionStart(session),
            new fromActions.TokenRefreshSuccess(session),
          ];
        })
      );
    })
  );

  @Effect()
  tokenDecode$ = this.actions$.pipe(
    ofType<fromActions.TokenDecode>(fromActions.oAuthActionTypes.TokenDecode),
    map((action) => action.payload),
    mergeMap((token: string) => {
      const decodedToken = jwt.decode(token);
      const mappedToken = this.mappingService.getMappedData<IUser>(
        decodedToken,
        mappingType.camelize
      );
      return [new fromActions.TokenDecodeSuccess(mappedToken)];
    }),
    catchError((error) => {
      const errorPayload: IError<FusionoAuthError> = {
        code: FusionoAuthError.TokenDecodeFail,
        source: ErrorSource.Validation,
        data: error,
      };
      return of(new TokenDecodeFail(errorPayload));
    })
  );

  @Effect({ dispatch: false })
  sessionStart$ = this.actions$.pipe(
    ofType<fromActions.SessionStart>(fromActions.oAuthActionTypes.SessionStart),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getApplications)),
    tap(([session, apps]: [any, any]) => {
      const protocol = apps.protocol;
      const domain = protocol === 'https' ? apps.domain : null;
      const isSecure = protocol === 'https' ? true : false;
      const gatewayCookieName = this.configService.getConfig().auth.gateway
        .cookie;
      this.cookieService.set(
        gatewayCookieName,
        session.token,
        session.expiration,
        '/',
        domain,
        isSecure
      );
    })
  );

  @Effect()
  getCookieToken$ = this.actions$.pipe(
    ofType(fromActions.oAuthActionTypes.SessionRestore),
    mergeMap((data) => {
      const gatewayTokenCookie = this.configService.getConfig().auth.gateway
        .cookie;
      return this.cookieService.get(gatewayTokenCookie).pipe(
        switchMap((userToken) => {
          const decodedToken = jwt.decode(userToken.token);
          const expiration = this.getDays(
            moment.unix(decodedToken.exp),
            moment.unix(decodedToken.iat)
          );
          const session: any = {
            token: userToken.token,
            expiration: expiration,
          };
          return [
            new fromActions.TokenDecode(userToken.token),
            new fromActions.SessionRestoreSuccess(session),
          ];
        }),
        catchError((error) => {
          const errorPayload: IError<FusionoAuthError> = {
            code: FusionoAuthError.SessionRestoreFail,
            source: ErrorSource.Validation,
            data: error,
          };
          return of(new SessionRestoreFail(errorPayload));
        })
      );
    })
  );

  @Effect()
  destroyoAuthSession$ = this.actions$.pipe(
    ofType(fromActions.oAuthActionTypes.SessionEnd),
    withLatestFrom(this.store.select(getApplications)),
    switchMap(([action, apps]: [any, any]) => {
      const domain = apps.protocol === 'https' ? apps.domain : null;
      this.cookieService.deleteAll('/', domain);
      return of(new RedirectToHomeApp());
    })
  );

  @Effect({ dispatch: false })
  redirectToHomeApp$ = this.actions$.pipe(
    ofType(fromActions.oAuthActionTypes.RedirectToHomeApp),
    withLatestFrom(this.store.select(getHomeAppBaseUrl)),
    map(([action, homeAppUrl]: [any, any]) => {
      window.location.href = homeAppUrl;
    })
  );

  @Effect({ dispatch: false })
  redirectToUserProfileApp$ = this.actions$.pipe(
    ofType<RedirectToUserProfileApp>(
      fromActions.oAuthActionTypes.RedirectToUserProfileApp
    ),
    map((action) => action.payload),
    withLatestFrom(this.store.select(getoAuthUserProfilePath)),
    map(([payload, userProfileAppUrl]: [any, any]) => {
      window.location.href = userProfileAppUrl;
    })
  );

  getDays(exp, iat) {
    return moment(exp).diff(iat, 'days');
  }
}
