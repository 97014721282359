import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ISearchPagination } from '@fusion/common';

@Component({
  selector: 'fusion-form-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Output() notifyPagination: EventEmitter<object> = new EventEmitter<object>();
  @Input() jobPagination: ISearchPagination;
  @Input() count: number = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25];

  constructor() {}

  ngOnInit() {}

  onPagination(event) {
    this.notifyPagination.emit(event);
  }
}
