import { NgModule } from '@angular/core';

import { FancyTimePipe } from './fancy-time/fancy-time.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';

export const PIPES = [FancyTimePipe, TruncatePipe];

@NgModule({
  declarations: PIPES,
  exports: PIPES
})
export class FusionPipeModule {}
