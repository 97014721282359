
<div>
  <input placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search>
</div>

<div class="map" #map></div>

detail: {{entries}}

<div *ngFor="let entry of entries">
  <table *ngIf="entry.place" [style.borderColor]="entry.color">
    <thead>
      <tr>
        <th>
          <button (click)="remove(entry)">remove</button>
        </th>
        <th>short_name</th>
        <th>types</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="2">{{entry.place.name}}</td>
        <td>name</td>
      </tr>
      <tr *ngFor="let component of entry.place.address_components">
        <td>{{component.long_name}}</td>
        <td>{{component.short_name}}</td>
        <td>{{component.types}}</td>
      </tr>
      <tr>
        <th colspan="2">
          â Parsed as
        </th>
        <th>field</th>
      </tr>
      <tr *ngFor="let locationField of locationFields">
        <td colspan="2">{{entry.location[locationField]}}</td>
        <td>{{locationField}}</td>
      </tr>
    </tbody>
  </table>
</div>
