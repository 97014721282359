import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionSubscriptionError } from "../../models/enums";
import { ISubscription } from "../../models/interfaces";

export enum SubscriptionsActionTypes {
  LoadSubscriptions = "[Subscriptions] Load",
  LoadSubscriptionsSuccess = "[Subscriptions] Load Success",
  LoadSubscriptionsFail = "[Subscriptions] Load Fail"
}

export class LoadSubscriptions implements Action {
  readonly type = SubscriptionsActionTypes.LoadSubscriptions;
}

export class LoadSubscriptionsSuccess implements Action {
  readonly type = SubscriptionsActionTypes.LoadSubscriptionsSuccess;

  constructor(public payload: ISubscription[]) {}
}

export class LoadSubscriptionsFail implements Action {
  readonly type = SubscriptionsActionTypes.LoadSubscriptionsFail;

  constructor(public payload: IError<FusionSubscriptionError>) {}
}

export type SubscriptionsActions =
  | LoadSubscriptions
  | LoadSubscriptionsSuccess
  | LoadSubscriptionsFail;
