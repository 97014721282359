export enum ErrorHandlingType {
  Component = 'component',
  Dialog = 'dialog',
  Template = 'template',
  Message = 'message'
}

export enum ErrorSource {
  API = 'API',
  Validation = 'Validation',
  Unknown = 'Unknown',
  Internal = 'Internal'
}

export enum ErrorActionType {
  Dispatch = 'Dispatch',
  Navigate = 'Navigate'
}
