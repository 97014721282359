import { createSelector } from "@ngrx/store";

import { getFusionSubscriptionState } from "../reducers";
import { getRouterParams } from "@fusion/router";
import { ISubscription, IApplication, IFeature } from "../../models/interfaces";
import { Subdomain } from "@fusion/oauth";

export const getSubscriptionsState = createSelector(
  getFusionSubscriptionState,
  state => {
    if (state == null) {
      return null;
    }
    return state.subscriptions;
  }
);

export const getSubscriptionsData = createSelector(
  getSubscriptionsState,
  state => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);

export const getSubscriptions = createSelector(
  getSubscriptionsData,
  getRouterParams,
  (subscriptions, params): ISubscription[] => {
    if (subscriptions == null || params == null) {
      return null;
    }

    return subscriptions
      .map(sub => {
        return {
          ...sub,
          applications: sub.applications.filter(app =>
            params.companyId ? app.allowCompany : app.allowUser
          )
        };
      })
      .filter(sub => sub.applications.length);
  }
);

export const getActiveSubscriptions = createSelector(
  getSubscriptions,
  (subs): ISubscription[] => {
    if (subs == null) {
      return null;
    }
    return subs
      .map(sub => {
        return {
          ...sub,
          applications: sub.applications.filter(app => app.isSubscribed)
        };
      })
      .filter(sub => sub.applications.length);
  }
);

export const getApplications = createSelector(
  getSubscriptionsData,
  (subs): IApplication[] => {
    if (subs == null) {
      return null;
    }

    let applications: IApplication[] = [];
    subs.map(sub => {
      applications = [...applications, ...sub.applications];
    });

    return applications;
  }
);

export const getProfileNav = (appId: string, currentPath: string = "") =>
  createSelector(getApplications, (applications): IFeature[] => {
    if (applications == null) {
      return null;
    }

    return applications
      .find(app => app.id == appId)
      .features.map(feature => {
        if (feature.path === currentPath) {
          feature.active = true;
        } else {
          feature.active = false;
        }
        return feature;
      });
  });

export const hasCompanySubscription = createSelector(
  getActiveSubscriptions,
  subs => {
    if (subs == null || !subs.length) {
      return null;
    }
    const companyGroup = subs.find(
      group => group.id === "610b1593-8957-4580-ae72-4943ff040540"
    );
    const company =
      companyGroup &&
      companyGroup.applications &&
      companyGroup.applications.length
        ? companyGroup.applications.find(
            app => app.domain === Subdomain.company
          )
        : null;
    return company ? true : false;
  }
);

export const hasMosqueSubscription = createSelector(
  getActiveSubscriptions,
  subs => {
    if (subs == null || !subs.length) {
      return null;
    }
    const companyGroup = subs.find(
      group => group.id === "610b1593-8957-4580-ae72-4943ff040540"
    );
    const company =
      companyGroup &&
      companyGroup.applications &&
      companyGroup.applications.length
        ? companyGroup.applications.find(
            app => app.domain === Subdomain.Mosque
          )
        : null;
    return company ? true : false;
  }
);
