import { createSelector } from "@ngrx/store";

import { getFusionArticleState } from "../reducers";
import { IArticle } from "../../models/interfaces";

export const getPrivateArticlesState = createSelector(
  getFusionArticleState,
  state => {
    if (state == null) {
      return null;
    }
    return state.privateArticles;
  }
);

export const getPrivateArticles = createSelector(
  getPrivateArticlesState,
  (state): IArticle[] => {
    if (state == null) {
      return null;
    }
    return state.data;
  }
);
