import { Action } from "@ngrx/store";
import { IPublication } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum PublicationsActionTypes {
  SetPublications = "[Publications] Set",
  LoadPublications = "[Publications] Load",
  LoadPublicationsSuccess = "[Publications] Load Success",
  LoadPublicationsFail = "[Publications] Load Fail",
  AddPublication = "[Publications] Add",
  AddPublicationSuccess = "[Publications] Add Success",
  AddPublicationFail = "[Publications] Add Fail",
  UpdatePublication = "[Publications] Update",
  UpdatePublicationSuccess = "[Publications] Update Success",
  UpdatePublicationFail = "[Publications] Update Fail",
  DeletePublication = "[Publications] Delete",
  DeletePublicationSuccess = "[Publications] Delete Success",
  DeletePublicationFail = "[Publications] Delete Fail"
}

export class SetPublications implements Action {
  readonly type = PublicationsActionTypes.SetPublications;

  constructor(public payload: IPublication[]) {}
}

export class LoadPublications implements Action {
  readonly type = PublicationsActionTypes.LoadPublications;

  constructor(public payload: IPublication[]) {}
}

export class LoadPublicationsSuccess implements Action {
  readonly type = PublicationsActionTypes.LoadPublicationsSuccess;

  constructor(public payload: any) {}
}

export class LoadPublicationsFail implements Action {
  readonly type = PublicationsActionTypes.LoadPublicationsFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class AddPublication implements Action {
  readonly type = PublicationsActionTypes.AddPublication;

  constructor(public payload: IPublication[]) {}
}

export class AddPublicationSuccess implements Action {
  readonly type = PublicationsActionTypes.AddPublicationSuccess;

  constructor(public payload: any) {}
}

export class AddPublicationFail implements Action {
  readonly type = PublicationsActionTypes.AddPublicationFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class UpdatePublication implements Action {
  readonly type = PublicationsActionTypes.UpdatePublication;

  constructor(public payload: IPublication[]) {}
}

export class UpdatePublicationSuccess implements Action {
  readonly type = PublicationsActionTypes.UpdatePublicationSuccess;

  constructor(public payload: any) {}
}

export class UpdatePublicationFail implements Action {
  readonly type = PublicationsActionTypes.UpdatePublicationFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export class DeletePublication implements Action {
  readonly type = PublicationsActionTypes.DeletePublication;

  constructor(public payload: string) {}
}

export class DeletePublicationSuccess implements Action {
  readonly type = PublicationsActionTypes.DeletePublicationSuccess;

  constructor(public payload: any) {}
}

export class DeletePublicationFail implements Action {
  readonly type = PublicationsActionTypes.DeletePublicationFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type PublicationsActions =
  | SetPublications
  | LoadPublications
  | LoadPublicationsSuccess
  | LoadPublicationsFail
  | AddPublication
  | AddPublicationSuccess
  | AddPublicationFail
  | UpdatePublication
  | UpdatePublicationSuccess
  | UpdatePublicationFail
  | DeletePublication
  | DeletePublicationSuccess
  | DeletePublicationFail;
