import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {}

  getAccessToken() {
    const gatewayTokenCookie = this.configService.getConfig().auth.gateway
      .cookie;
    return this.cookieService.get(gatewayTokenCookie);
  }

  setAccessToken() {
    const gatewayTokenCookie = this.configService.getConfig().apiGatewayCookie;
    return this.http.get(`/user/api/token/refresh`).subscribe((data: any) => {
      // 'gatewayTokenCookie', 'cookieValue', 'days from now'
      this.cookieService.set(
        gatewayTokenCookie,
        data.Token,
        data.Expiration,
        '/'
      );
    });
  }
}
