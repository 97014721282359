import { CompanyEffects } from "./company.effects";
import { CompaniesEffects } from "./companies.effects";
import { UnclaimedCompaniesEffects } from "./unclaimed-companies.effects";
import { UsersEffects } from "./users.effects";
import { ErrorEffects } from "./error.effects";

// include all effects
export const effects: any[] = [
  CompanyEffects,
  CompaniesEffects,
  UnclaimedCompaniesEffects,
  UsersEffects,
  ErrorEffects
];

// export from effects
