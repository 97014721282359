import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { GatewayService } from "../gateway/gateway.service";
import { ConfigService } from "../config/config.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RequestOptionsService {
  sessionCookie: any;

  constructor(
    private gatewayService: GatewayService,
    private configService: ConfigService
  ) {}

  generateOptions(isApiKey: boolean = false, isMultipartForm: boolean = false) {
    this.gatewayService
      .getAccessToken()
      .pipe(
        map((token) => {
          this.sessionCookie = token;
        })
      )
      .subscribe();
    const secret = this.configService.getConfig().services.token.key;
    const appId = this.configService.getConfig().appId;
    let headerOptions: any = {};
    if (!isMultipartForm) {
      headerOptions = {
        ...headerOptions,
        "Content-Type": "application/json",
      };
    }

    if (isApiKey) {
      headerOptions = {
        ...headerOptions,
        "Fusion-Api-Key": secret,
        "App-Id": appId,
      };
    } else {
      if (this.sessionCookie.token) {
        headerOptions = {
          ...headerOptions,
          Authorization: `Bearer ${this.sessionCookie.token}`,
        };
      }
    }
    const headers = new HttpHeaders(headerOptions);
    const options: any = { headers: headers };
    return options;
  }

  generateOptionsExternal() {
    const headerOptions = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    };
    const headers = new HttpHeaders(headerOptions);
    return { headers: headers };
  }
}
