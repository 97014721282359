import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IFormConfig } from "../../models/interfaces";

@Component({
  selector: "fusion-form-name",
  templateUrl: "./name.component.html",
  styleUrls: ["./name.component.scss"]
})
export class NameComponent implements OnInit {
  @Input() formConfigs: IFormConfig[];
  @Input() formValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {}

  getRequiredConfig(configs: any, options: string[]) {
    return configs.filter((config: any) => {
      if (options.length && options.indexOf(config.name) > -1) {
        return config;
      }
    });
  }
}
