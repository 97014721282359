import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";

// rxjs
import { of } from "rxjs";
import { mergeMap, catchError, map } from "rxjs/operators";
import {
  LoadSkills,
  SkillsActionTypes,
  LoadSkillsSuccess,
  LoadSkillsFail
} from "../actions/index";

@Injectable()
export class SkillsEffects {
  constructor(public actions$: Actions) {}

  @Effect()
  getSkills$ = this.actions$.pipe(
    ofType<LoadSkills>(SkillsActionTypes.LoadSkills),
    map(action => action.payload),
    mergeMap((payload: any) => {
      return [new LoadSkillsSuccess(payload)];
    })
  );
}
