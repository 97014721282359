export const roles = [
  {
    id: 'b56a48d8-8d71-4511-a51a-4db2217e99c9',
    title: 'Admin',
    description:
      'Admin has access on all resources of company and on asscciated subscription',
    active: true
  },
  {
    id: 'dc467450-3e55-48de-95c8-c76b94feee06',
    title: 'Publisher',
    description:
      'Publisher has access on publishing content for company and on asscciated subscription',
    active: true
  },
  {
    id: '06d95e6e-ea79-41c6-8758-60c364fa6b8e',
    title: 'Editor',
    description:
      'Editor has access on editing content for company and on asscciated subscription',
    active: true
  },
  {
    id: 'dd35ef7f-9eb4-44ea-8f1b-43e0260586f2',
    title: 'Advertiser',
    description:
      'Advertiser has access on posting add for company on asscciated subscription',
    active: true
  },
  {
    id: '32915ce8-bbe8-443c-9118-05350d16e5ef',
    title: 'Analyst',
    description:
      'Analyst has access on managing report for company on asscciated subscription',
    active: true
  }
];
