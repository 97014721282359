<div pointer border radius image>
  <img radius *ngIf="image" imageFit [src]="image" />
  <div
    centering
    center
    imageOverlayTransparent
    *ngIf="isUploading"
    class="loading-shade"
  >
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div centering center imageOverlayTransparent>
    <label class="cover-image__label" for="logo__file-input">
      <div mediumFontSize>
        <mat-icon largeFont>cloud_upload</mat-icon>
      </div>
      <div *ngIf="uploadTitle" microTitle>{{ uploadTitle }}</div>
    </label>

    <input
      accept="image/*"
      id="logo__file-input"
      class="logo__file-input"
      type="file"
      (change)="onFileChange($event)"
    />
  </div>
</div>
