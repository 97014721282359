import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionCompanyError } from "../../models/enums";
import { ICompany } from "../../models/interfaces";

export enum UnclaimedCompaniesActionTypes {
  LoadUnclaimedCompanies = "[Unclaimed Companies] Load",
  LoadUnclaimedCompaniesSuccess = "[Unclaimed Companies] Load Success",
  LoadUnclaimedCompaniesFail = "[Unclaimed Companies] Load Fail"
}

export class LoadUnclaimedCompanies implements Action {
  readonly type = UnclaimedCompaniesActionTypes.LoadUnclaimedCompanies;

  constructor(public payload: string) {}
}

export class LoadUnclaimedCompaniesSuccess implements Action {
  readonly type = UnclaimedCompaniesActionTypes.LoadUnclaimedCompaniesSuccess;

  constructor(public payload: ICompany[]) {}
}

export class LoadUnclaimedCompaniesFail implements Action {
  readonly type = UnclaimedCompaniesActionTypes.LoadUnclaimedCompaniesFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export type UnclaimedCompaniesActions =
  | LoadUnclaimedCompanies
  | LoadUnclaimedCompaniesSuccess
  | LoadUnclaimedCompaniesFail;
