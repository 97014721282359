import { Action } from "@ngrx/store";
import { IRating } from "../../models/interfaces";
import { IError } from "@fusion/error";
import { FusionCareerProfileError } from "../../models";

export enum RatingActionTypes {
  SetRating = "[Rating] Set",
  LoadRating = "[Rating] Load",
  LoadRatingSuccess = "[Rating] Load Success",
  LoadRatingFail = "[Rating] Load Fail"
}

export class SetRating implements Action {
  readonly type = RatingActionTypes.SetRating;

  constructor(public payload: IRating[]) {}
}

export class LoadRating implements Action {
  readonly type = RatingActionTypes.LoadRating;

  constructor(public payload: IRating[]) {}
}

export class LoadRatingSuccess implements Action {
  readonly type = RatingActionTypes.LoadRatingSuccess;

  constructor(public payload: any) {}
}

export class LoadRatingFail implements Action {
  readonly type = RatingActionTypes.LoadRatingFail;

  constructor(public payload: IError<FusionCareerProfileError>) {}
}

export type RatingActions =
  | SetRating
  | LoadRating
  | LoadRatingSuccess
  | LoadRatingFail;
