import { INavigation } from '../interfaces/navigation.interface';

export const topNavigation: INavigation[] = [
  {
    title: 'Home',
    path: 'http://localhost:4200'
  },
  {
    title: 'Home Rent',
    path: 'http://localhost:4205'
  },
  {
    title: 'Job Search',
    path: 'http://localhost:4201/jobsearch'
  }
  // {
  //   title: 'Contact Us',
  //   path: 'http://localhost:4200/contactus'
  // }
  // {
  //   title: 'Sign In',
  //   path: ''
  // },
  // {
  //   title: 'Sign Up',
  //   path: ''
  // }
];
