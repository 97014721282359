import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { RequestOptionsService } from '../request-options/request-options.service';
import { Observable } from 'rxjs';

const API_KEY = 'AIzaSyD4Ugi5XQd2QemxtiJC0iwDjR2nPqWwqNU';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {
  baseUrl: string;
  placeAutocomplete: string;
  placeDetails: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.google.host;
    this.placeAutocomplete = this.configService.getConfig().services.google.placeAutocomplete;
    this.placeDetails = this.configService.getConfig().services.google.placeDetails;
  }

  getPlaceAutocomplete(keywords: string, sessiontoken: string): Observable<any> {
    const fullUrl = `${this.baseUrl}/${this.placeAutocomplete}?input=${keywords}&types=geocode&language=en&key=${API_KEY}`;
    const options = this.requestOptionsService.generateOptionsExternal();
    return this._http.get(fullUrl, options);
  }
  
  getPlaceDetails(placeId: string, sessiontoken: string): Observable<any> {
    const fullUrl = `${this.baseUrl}/${this.placeDetails}?place_id=${placeId}language=en&key=${API_KEY}&sessiontoken=${sessiontoken}`;
    const options = this.requestOptionsService.generateOptionsExternal();
    return this._http.get(fullUrl);
  }
}
