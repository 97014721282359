import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionCompanyError } from "../../models/enums";
import { ICompany } from "../../models/interfaces";

export enum CompaniesActionTypes {
  LoadCompanies = "[Companies] Load",
  LoadCompaniesSuccess = "[Companies] Load Success",
  LoadCompaniesFail = "[Companies] Load Fail",
  SearchCompanies = "[Companies] Search",
  SearchCompaniesSuccess = "[Companies] Search Success",
  SearchCompaniesFail = "[Companies] Search Fail"
}

export class LoadCompanies implements Action {
  readonly type = CompaniesActionTypes.LoadCompanies;
}

export class LoadCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadCompaniesSuccess;

  constructor(public payload: ICompany[]) {}
}

export class LoadCompaniesFail implements Action {
  readonly type = CompaniesActionTypes.LoadCompaniesFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export class SearchCompanies implements Action {
  readonly type = CompaniesActionTypes.SearchCompanies;

  constructor(public payload: string) {}
}

export class SearchCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.SearchCompaniesSuccess;

  constructor(public payload: ICompany[]) {}
}

export class SearchCompaniesFail implements Action {
  readonly type = CompaniesActionTypes.SearchCompaniesFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export type CompaniesActions =
  | LoadCompanies
  | LoadCompaniesSuccess
  | LoadCompaniesFail
  | SearchCompanies
  | SearchCompaniesSuccess
  | SearchCompaniesFail;
