<ng-container *ngIf="isNavOption; else nonNavOption">
  <div class="nav__item" (click)="openDialog()">
    <ng-container [ngSwitch]="iconType">
      <ng-container *ngSwitchCase="ModalIconType.SignIn">
        <mat-icon>lock_open</mat-icon>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.SignUp">
        <mat-icon>person_add</mat-icon>
      </ng-container>
    </ng-container>
    <span>{{ buttonTitle }}</span>
  </div>
</ng-container>

<ng-template #nonNavOption>
  <ng-container *ngIf="formMetadata?.isToggleButton; else button_link">
    <mat-button-toggle-group #group="matButtonToggleGroup" vertical="true">
      <mat-button-toggle value="left" checked (click)="openDialog()">
        <mat-icon>add</mat-icon>
        {{formMetadata.submitButtonText}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </ng-container>
</ng-template>

<ng-template #button_link>
  <span
    (click)="openDialog()"
    matTooltip="{{ buttonTitle }}"
    [matTooltipPosition]="'above'"
    class="menu"
  >
    <ng-container [ngSwitch]="iconType">
      <ng-container *ngSwitchCase="ModalIconType.AddIcon">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.EditIcon">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.SignIn">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>lock_open</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.SignUp">
        <button class="custom-button" mat-fab color="primary">
          <mat-icon>person_add</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="ModalIconType.None">
        <span>{{ buttonTitle }}</span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <a mat-button>{{ formMetadata?.submitButtonText }}</a>
      </ng-container>
    </ng-container>
  </span>
</ng-template>
