import * as constants from "../constants";
import { IForm } from "../interfaces";
import { FormFieldType } from "../enums";

export const ContactUsForm: IForm = {
  metadata: {
    name: constants.CONTACT_US_FORM,
    title: "Contact Us",
    submitButtonText: "Send",
    style: {
      topMargin: "0px"
    }
  },
  configs: [
    {
      label: "Your Name",
      name: "fullName",
      type: FormFieldType.Text,
      placeholder: "John",
      validation: {
        required: true,
        min: 3,
        max: 30
      }
    },
    {
      label: "Email",
      name: "email",
      type: FormFieldType.Email,
      placeholder: "john.deep@example.com",
      examplePattern: "john.deep@example.com",
      validation: {
        required: true,
        min: 5,
        max: 30,
        pattern: "[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\\.[a-zA-Z]+"
      }
    },
    {
      label: "Subject",
      name: "subject",
      type: FormFieldType.Text,
      placeholder: "subject",
      validation: {
        required: true,
        min: 5,
        max: 30
      }
    },
    {
      label: "Message",
      name: "message",
      type: FormFieldType.Ckeditor,
      placeholder: "project description",
      validation: {
        required: false,
        max: 3000
      }
    }
  ]
};
