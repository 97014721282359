// import guards
import { OauthSessionGuard } from './oauth-session.guard';
import { OauthRouteGuard } from './oauth-route.guard';
import { OauthSignInGuard } from './oauth-sign-in.guard';
import { OauthUserGuard } from './oauth-user.guard';

// include all guards
export const guards: any[] = [
  OauthSessionGuard,
  OauthRouteGuard,
  OauthUserGuard,
  OauthSignInGuard,
];

// export from guards
export * from './oauth-session.guard';
export * from './oauth-route.guard';
export * from './oauth-user.guard';
export * from './oauth-sign-in.guard';
