import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { ErrorService } from '../error/error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) {}

  get errorService(): ErrorService {
    return this.injector.get(ErrorService);
  }

  handleError(error: Error | HttpErrorResponse) {
    this.errorService.error(error);
  }
}
