import {
  CompaniesActionTypes,
  CompaniesActions
} from "../actions/companies.actions";
import { ICompany } from "../../models/interfaces";

export interface CompaniesState {
  data: ICompany[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: CompaniesState = {
  data: [],
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: CompaniesActions
): CompaniesState {
  switch (action.type) {
    case CompaniesActionTypes.LoadCompanies: {
      return {
        ...state,
        loading: true
      };
    }
    case CompaniesActionTypes.LoadCompaniesSuccess:
    case CompaniesActionTypes.SearchCompaniesSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case CompaniesActionTypes.LoadCompaniesFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
