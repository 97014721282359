export const propertyType = [
  'House',
  'Apartment',
  'Condo',
  'Room',
  'Basement',
  'Roommate',
  'Retail Store',
  'Shoping Center',
  'Health Care',
  'Office'
];
