<div *ngIf="emptyState" class="colN">
  <div
    border
    radius
    cardSpacing
    elevation
    pointer
    class="add-property row2"
    [ngClass]="{ disabled: isLoading }"
    (click)="navigateTo(currentRootPath, emptyState?.path)"
  >
    <div centeringBottom class="row2__section-one">
      <div *ngIf="isLoading" class="loading-shade">
        <mat-spinner></mat-spinner>
      </div>
      <button mat-fab color="accent"><mat-icon>add</mat-icon></button>
    </div>
    <div class="row2__section-two row2">
      <div centeringText class="row2__section-one">
        <h6>{{ emptyState?.card.title }}</h6>
      </div>

      <div centering class="row2__section-two">
        <div g3FontColor exSmallFont>{{ emptyState?.card.description }}</div>
      </div>
    </div>
  </div>
  <div class="detail row2">
    <div class="title row2__section-one">
      <h2>{{ emptyState?.message.title }}</h2>
    </div>
    <div g3FontColor class="row2__section-two">
      {{ emptyState?.message.description }}
    </div>
  </div>
</div>
