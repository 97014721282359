<div *ngIf="company" class="col1">
  <div cardSpacing border radius class="col1__section-one rowN">
    <div class="">
      <h6>Website</h6>
      <div extraSmallTitle>
        <a target="_blank" href="{{ company.website }}">{{
          company.website
        }}</a>
      </div>
    </div>

    <div class="">
      <h6>
        {{ company.type === "parent/holding" ? "Headquarter" : "Location" }}
      </h6>
      <div extraSmallTitle>
        {{ company.address }}, {{ company.city }},
        {{ company.state ? company.state + "," : "" }} {{ company.country }} -
        {{ company.zipCode }}
      </div>
    </div>

    <div class="">
      <h6>Company Type</h6>
      <div extraSmallTitle>{{ company.type }}</div>
    </div>

    <div class="">
      <h6>Company Category</h6>
      <div extraSmallTitle>{{ company.category }}</div>
    </div>

    <div class="">
      <h6>Year Founded</h6>
      <div extraSmallTitle>
        {{ company.founded | date: "y" }} ({{ company.founded | fancyTime }})
      </div>
    </div>

    <div class="">
      <h6>Company Size</h6>
      <div extraSmallTitle>{{ company.noOfEmployees }}</div>
    </div>
  </div>
  <div class="col1__section-two"></div>
</div>
