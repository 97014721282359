<div pageSpacing largePage pageBody>
  <div pageTitle><h1>Add Article</h1></div>
  <div pageContent>
    <div content>
      <mat-tab-group dynamicHeight [selectedIndex]="1">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="r-margin-3">library_add</mat-icon>
            Add Article
          </ng-template>
          <div class="v-padding-8 h-padding-6">
            <ng-container
              *ngIf="
                addArticleErrorHandlingConfig$ | async as errorHandlingConfig;
                else addArticle
              "
            >
              <ng-template
                [fusionErrorHandler]="errorHandlingConfig"
              ></ng-template>
            </ng-container>
            <ng-template #addArticle>
              <fusion-common-empty-state
                [isNotifyOnly]="true"
                [isLoading]="isAddingArticle$ | async"
                [emptyState]="emptyState"
                [currentRootPath]="currentRootPath$ | async"
                (notifyEmptyStateClick)="onNotifyEmptyStateClick()"
              ></fusion-common-empty-state>
            </ng-template>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="r-margin-3">view_list</mat-icon>
            Draft Articles ({{ (articles$ | async).length }})
          </ng-template>
          <div class="v-padding-8 h-padding-6">
            <ng-container
              *ngIf="
                loadArticlesErrorHandlingConfig$ | async as errorHandlingConfig;
                else articleList
              "
            >
              <ng-template
                [fusionErrorHandler]="errorHandlingConfig"
              ></ng-template>
            </ng-container>
            <ng-template #articleList>
              <fusion-article-list
                [articles]="articles$ | async"
                [currentRootPath]="currentRootPath$ | async"
                [parentDir]="'addarticle'"
              ></fusion-article-list>
            </ng-template>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div adSpace></div>
  </div>
</div>
