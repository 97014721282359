import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFormConfig } from '../../models/interfaces';

@Component({
  selector: 'fusion-form-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Output() notifyFormValidationUpdate = new EventEmitter<boolean>();
  @Input() formConfigs: IFormConfig;
  @Input() formValidationRules: FormGroup;

  constructor() {}

  ngOnInit() {
    this.formValidationRules.valueChanges.subscribe((formData: any) => {
      if (this.formConfigs.disable) {
        this.setDisabledProperties(formData);
      }
    });
  }

  update(value) {
    if (this.formConfigs.display) {
      this.notifyFormValidationUpdate.emit(value);
    }
  }

  setDisabledProperties(formData) {
    const value = formData[this.formConfigs.disable.by] === 'true';
    if (value) {
      this.formConfigs.disable.fields.map((field) => {
        this.formValidationRules.controls[field].disable({
          onlySelf: true,
        });
      });
    } else {
      this.formConfigs.disable.fields.map((field) => {
        if (this.formValidationRules.controls[field]) {
          this.formValidationRules.controls[field].enable({
            onlySelf: true,
          });
        }
      });
    }
  }
}
