import {
  SubscribeActionTypes,
  SubscribeActions
} from '../actions/subscribe.actions';

export interface SubscribeState {
  data: any;
  loading: boolean;
  loaded: boolean;
}

export const initialState: SubscribeState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: SubscribeActions
): SubscribeState {
  switch (action.type) {
    case SubscribeActionTypes.SubscribeStart: {
      return {
        ...state,
        loading: true
      };
    }
    case SubscribeActionTypes.SubscribeSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case SubscribeActionTypes.SubscribeFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
