import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FusionFormComponent } from './fusion-form.component';
import { SalaryComponent } from './components/salary/salary.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CkeditorComponent } from './components/ckeditor/ckeditor.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { RadioComponent } from './components/radio/radio.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { FormHandlerComponent } from './containers/form-handler/form-handler.component';
import { FormTemplateComponent } from './containers/form-template/form-template.component';
import { UploadComponent } from './components/upload/upload.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { OptionListComponent } from './containers/option-list/option-list.component';
import { SearchComponent } from './components/search/search.component';
import { FormDialogComponent } from './dialogs/form-dialog/form-dialog.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { FusionCommonModule } from '@fusion/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FusionMaterialModule } from '@fusion/material';
import { CKEditorModule } from 'ng2-ckeditor';
import { DialogTemplateComponent } from './containers/dialog-template/dialog-template.component';
import { AddressComponent } from './components/address/address.component';
import { HomePropertyComponent } from './components/home-property/home-property.component';
import { NameComponent } from './components/name/name.component';
import { JobMetadataComponent } from './components/job-metadata/job-metadata.component';
import { JobApplyTypeComponent } from './components/job-apply-type/job-apply-type.component';

@NgModule({
  declarations: [
    FusionFormComponent,
    SalaryComponent,
    CheckboxComponent,
    CkeditorComponent,
    DatepickerComponent,
    DropdownComponent,
    RadioComponent,
    TextFieldComponent,
    TextareaComponent,
    AutoCompleteComponent,
    FormHandlerComponent,
    FormTemplateComponent,
    UploadComponent,
    PaginatorComponent,
    OptionListComponent,
    SearchComponent,
    FormDialogComponent,
    NotificationDialogComponent,
    DialogTemplateComponent,
    AddressComponent,
    HomePropertyComponent,
    NameComponent,
    JobMetadataComponent,
    JobApplyTypeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    FusionCommonModule,
    FusionMaterialModule,
    CKEditorModule
  ],
  entryComponents: [
    FormDialogComponent,
    NotificationDialogComponent,
    DialogTemplateComponent
  ],
  exports: [
    FusionFormComponent,
    FormTemplateComponent,
    OptionListComponent,
    SearchComponent,
    FormDialogComponent,
    NotificationDialogComponent,
    DialogTemplateComponent
  ]
})
export class FusionFormModule {}
