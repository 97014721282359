import {
  SubscriptionsActionTypes,
  SubscriptionsActions
} from "../actions/subscriptions.actions";
import { ISubscription } from "../../models/interfaces";

export interface SubscriptionsState {
  data: ISubscription[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: SubscriptionsState = {
  data: null,
  loading: false,
  loaded: false
};

export function reducer(
  state = initialState,
  action: SubscriptionsActions
): SubscriptionsState {
  switch (action.type) {
    case SubscriptionsActionTypes.LoadSubscriptions: {
      return {
        ...state,
        loading: true
      };
    }
    case SubscriptionsActionTypes.LoadSubscriptionsSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.payload
      };
    }
    case SubscriptionsActionTypes.LoadSubscriptionsFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
  }
  return state;
}
