import { NgModule } from '@angular/core';
import { AddArticleComponent } from './containers/add-article/add-article.component';
import { QuillModule } from 'ngx-quill';
import { ArticleEditorComponent } from './containers/article-editor/article-editor.component';
import { FusionCommonModule } from '@fusion/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HighlightModule } from 'ngx-highlightjs';
import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { ArticleViewComponent } from './containers/article-view/article-view.component';
import { ArticleListComponent } from './containers/article-list/article-list.component';
import { FusionPipeModule } from '@fusion/pipe';
import { RouterModule } from '@angular/router';
import { FusionMaterialModule } from '@fusion/material';
import { SectionDividerComponent } from './components/section-divider/section-divider.component';

@NgModule({
  declarations: [
    AddArticleComponent,
    ArticleEditorComponent,
    ArticleViewComponent,
    ArticleListComponent,
    SectionDividerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FusionCommonModule,
    FusionPipeModule,
    FusionMaterialModule,
    // HighlightModule,
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean']
        ]
      },
      theme: 'snow'
    }),
    StoreModule.forFeature('fusionArticle', reducers),
    EffectsModule.forFeature(effects)
  ],
  exports: [
    AddArticleComponent,
    ArticleEditorComponent,
    ArticleViewComponent,
    ArticleListComponent
  ]
})
export class FusionArticleModule {}
