import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

// from reducer
import * as fromCompanies from './companies.reducer';
import * as fromCompany from './company.reducer';
import * as fromUnclaimedCompanies from './unclaimed-companies.reducer';
import * as fromUsers from './users.reducer';

export interface FusionCompanyState {
  companies: fromCompanies.CompaniesState;
  company: fromCompany.CompanyState;
  unclaimedCompanies: fromUnclaimedCompanies.UnclaimedCompaniesState;
  users: fromUsers.UsersState;
}

export const reducers: ActionReducerMap<FusionCompanyState> = {
  companies: fromCompanies.reducer,
  company: fromCompany.reducer,
  unclaimedCompanies: fromUnclaimedCompanies.reducer,
  users: fromUsers.reducer
};

export const getFusionCompanyState = createFeatureSelector<FusionCompanyState>(
  'fusionCompany'
);

// export reducer state interfaces
export { CompanyState } from './company.reducer';
