import { createSelector } from '@ngrx/store';

import { getFusionCareerProfileState } from '../reducers';
import { ISkill } from '../../models/interfaces';

export const getSkillsState = createSelector(
  getFusionCareerProfileState,
  (state) => {
    if (state == null) {
      return null;
    }
    return state.skills;
  }
);

export const getSkills = createSelector(
  getSkillsState,
  (skill): ISkill[] => {
    if (skill == null) {
      return null;
    }
    return skill.data;
  }
);
