import { Action } from "@ngrx/store";
import { IError } from "@fusion/error";
import { FusionCompanyError } from "../../models/enums";
import { ICompany } from "../../models/interfaces";
import { IFilePayload, IFileUpload } from "@fusion/common";

export enum CompanyActionTypes {
  LoadCompany = "[Company] Load",
  LoadCompanySuccess = "[Company] Load Success",
  LoadCompanyFail = "[Company] Load Fail",
  AddCompany = "[Company] Add",
  AddCompanySuccess = "[Company] Add Success",
  AddCompanyFail = "[Company] Add Fail",
  UploadLogo = "[Company] Upload Logo",
  UploadLogoSuccess = "[Company] Upload Logo Success",
  UploadLogoFail = "[Company] Upload Logo Fail",
  UploadBanner = "[Company] Upload Banner",
  UploadBannerSuccess = "[Company] Upload Banner Success",
  UploadBannerFail = "[Company] Upload Banner Fail",
  UpdateCompany = "[Company] Update",
  UpdateCompanySuccess = "[Company] Update Success",
  UpdateCompanyFail = "[Company] Update Fail",
  ClaimCompany = "[Company] Claim",
  ClaimCompanySuccess = "[Company] Claim Success",
  ClaimCompanyFail = "[Company] Claim Fail"
}

export class LoadCompany implements Action {
  readonly type = CompanyActionTypes.LoadCompany;

  constructor(public payload?: string) {}
}

export class LoadCompanySuccess implements Action {
  readonly type = CompanyActionTypes.LoadCompanySuccess;

  constructor(public payload: ICompany) {}
}

export class LoadCompanyFail implements Action {
  readonly type = CompanyActionTypes.LoadCompanyFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export class AddCompany implements Action {
  readonly type = CompanyActionTypes.AddCompany;

  constructor(public payload: any) {}
}

export class AddCompanySuccess implements Action {
  readonly type = CompanyActionTypes.AddCompanySuccess;

  constructor(public payload: any) {}
}

export class AddCompanyFail implements Action {
  readonly type = CompanyActionTypes.AddCompanyFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export class UploadLogo implements Action {
  readonly type = CompanyActionTypes.UploadLogo;

  constructor(public payload: IFileUpload) {}
}

export class UploadLogoSuccess implements Action {
  readonly type = CompanyActionTypes.UploadLogoSuccess;

  constructor(public payload: any) {}
}

export class UploadLogoFail implements Action {
  readonly type = CompanyActionTypes.UploadLogoFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export class UploadBanner implements Action {
  readonly type = CompanyActionTypes.UploadBanner;

  constructor(public payload: IFilePayload) {}
}

export class UploadBannerSuccess implements Action {
  readonly type = CompanyActionTypes.UploadBannerSuccess;

  constructor(public payload: any) {}
}

export class UploadBannerFail implements Action {
  readonly type = CompanyActionTypes.UploadBannerFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export class UpdateCompany implements Action {
  readonly type = CompanyActionTypes.UpdateCompany;

  constructor(public payload: any) {}
}

export class UpdateCompanySuccess implements Action {
  readonly type = CompanyActionTypes.UpdateCompanySuccess;

  constructor(public payload: any) {}
}

export class UpdateCompanyFail implements Action {
  readonly type = CompanyActionTypes.UpdateCompanyFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export class ClaimCompany implements Action {
  readonly type = CompanyActionTypes.ClaimCompany;

  constructor(public payload: any) {}
}

export class ClaimCompanySuccess implements Action {
  readonly type = CompanyActionTypes.ClaimCompanySuccess;

  constructor(public payload: any) {}
}

export class ClaimCompanyFail implements Action {
  readonly type = CompanyActionTypes.ClaimCompanyFail;

  constructor(public payload: IError<FusionCompanyError>) {}
}

export type CompanyActions =
  | LoadCompany
  | LoadCompanySuccess
  | LoadCompanyFail
  | AddCompany
  | AddCompanySuccess
  | AddCompanyFail
  | UploadLogo
  | UploadLogoSuccess
  | UploadLogoFail
  | UploadBanner
  | UploadBannerSuccess
  | UploadBannerFail
  | UpdateCompany
  | UpdateCompanySuccess
  | UpdateCompanyFail
  | ClaimCompany
  | ClaimCompanySuccess
  | ClaimCompanyFail;
