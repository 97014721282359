import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { RequestOptionsService } from "../request-options/request-options.service";

@Injectable({
  providedIn: "root"
})
export class JobseekerService {
  baseUrl: string;
  api: string;

  constructor(
    private _http: HttpClient,
    private configService: ConfigService,
    private requestOptionsService: RequestOptionsService
  ) {
    this.baseUrl = this.configService.getConfig().services.jobseeker.host;
    this.api = this.configService.getConfig().services.jobseeker.api;
  }

  getJobseekerProfile(jobseekerId): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobseekers/${jobseekerId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  getJobseekerByUserId(userId): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobseekers/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.get(fullUrl, options);
  }

  postJobseeker(userData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobseekers`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, userData, options);
  }

  putJobseeker(jobseekerId: string, userData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/jobseekers/${jobseekerId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, userData, options);
  }

  postSkill(skillData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/skills`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, skillData, options);
  }

  postProject(skillData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/projects`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, skillData, options);
  }

  putProject(projectData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/projects`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, projectData, options);
  }

  deleteProject(projectId: string, userId: string): Observable<any> {
    const fullUrl =
      this.baseUrl + `${this.api}/projects/${projectId}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }

  postPublication(publicationData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/publications`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.post(fullUrl, publicationData, options);
  }

  putPublication(publicationData): Observable<any> {
    const fullUrl = this.baseUrl + `${this.api}/publications`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.put(fullUrl, publicationData, options);
  }

  deletePublication(publicationId: string, userId: string): Observable<any> {
    const fullUrl =
      this.baseUrl +
      `${this.api}/publications/${publicationId}/users/${userId}`;
    const options = this.requestOptionsService.generateOptions();
    return this._http.delete(fullUrl, options);
  }
}
