import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  IArticle,
  LoadArticle,
  getCurrentArticle,
  UpdateArticle,
  PublishArticle,
  isArticleUpdating,
  FusionArticleError
} from '@fusion/article';
import { ArticleState } from '../../store';
import { getRouterUrlRoot } from '@fusion/router';
import { IErrorHandlingConfig, getErrorHandlingConfig } from '@fusion/error';

@Component({
  selector: 'article-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss']
})
export class EditArticleComponent implements OnInit {
  currentRootPath$: Observable<string>;
  article$: Observable<IArticle>;
  isUpdating$: Observable<boolean>;
  updateArticleErrorHandlingConfig$: Observable<IErrorHandlingConfig>;
  publishArticleErrorHandlingConfig$: Observable<IErrorHandlingConfig>;
  loadArticleErrorHandlingConfig$: Observable<IErrorHandlingConfig>;

  constructor(private store: Store<ArticleState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadArticle());

    this.currentRootPath$ = this.store.select(getRouterUrlRoot);
    this.article$ = this.store.select(getCurrentArticle);
    this.isUpdating$ = this.store.select(isArticleUpdating);
    this.updateArticleErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.UpdateArticleFail)
    );
    this.publishArticleErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.PublishArticleFail)
    );
    this.loadArticleErrorHandlingConfig$ = this.store.select(
      getErrorHandlingConfig(FusionArticleError.LoadArticleFail)
    );
  }

  onNotifyArticleSubmit(article: IArticle) {
    this.store.dispatch(new UpdateArticle(article));
  }

  onNotifyArticlePublish(article: IArticle) {
    this.store.dispatch(new PublishArticle(article));
  }
}
