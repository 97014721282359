import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ISeo } from '@fusion/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private meta: Meta, private title: Title) {}

  setMetaTags(config: ISeo) {
    config = {
      siteName: 'EliteCareer',
      title: 'EliteCareer | Career Management App',
      description: 'Job Search, Jobs, Career Management, Employer, Jobseeker',
      image: '/assets/images/logo/logo-sm-2.png',
      ...config
    };
    this.title.setTitle(`${config.title} | ${config.siteName}`);
    this.meta.addTags([
      // facebook
      {
        property: 'og:site_name',
        content: config.siteName
      },
      {
        property: 'og:type',
        content: config.type
      },
      {
        property: 'og:url',
        content: config.url
      },
      {
        property: 'og:title',
        content: config.title
      },
      {
        property: 'og:description',
        content: config.description
      },
      {
        property: 'og:image',
        content: config.image
      },
      // twiter
      {
        name: 'twitter:card',
        content: config.type
      },
      {
        name: 'twitter:title',
        content: config.title
      },
      {
        name: 'twitter:description',
        content: config.description
      },
      {
        name: 'twitter:image',
        content: config.image
      }
    ]);
  }
}
